import React, { useEffect, useState } from 'react';
import {Modal } from "react-bootstrap";
import { CommonService } from '../../service/common.service';
import  './QAModal.css';
import moment from 'moment';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';


const SessionQA = (props) => {
  const [answer, setAnswer] = useState("")
  const [incorrectAnswer, setIncorrectAnswer] = useState(false)
  const [attempts, setAttempts] = useState(3)
  const [userId, setUserId] = useState("")
  const alert = useAlert()
  const history = useHistory()
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIncorrectAnswer(false)
    },20000)
  }, [incorrectAnswer])

  useEffect(()=> {
    if(props && props.question){
      setShow(true)
      setAttempts(3)
      setIncorrectAnswer(false)
      console.log(`QUESTION_IN_POPUP : `,props.question)
    }
  },[props])

  useEffect(() => {
    if(attempts == 0) {
      setShow(false)
      CommonService.httpGetService(`admin-api/security_questions/mark_absent?user_id=${props.userId}`)
      .then(res => {
      })
      .catch(error => {
        console.log(error)
      })
    }
  }, [attempts])
 
  const formSubmitHandler = (e) => {
		e.preventDefault()
    const requestData = {answer}
		CommonService.httpPostService(`admin-api/security_questions/check_answer?user_id=${props.userId}&question_id=${props.question.security_question_id}`, requestData)
		.then(data => {
		  if(data.data.answer == requestData.answer) {
      props.closeModal()
      alert.success("RIGHT ANSWER")
      setIncorrectAnswer(false)
		  } 
		})
    .catch(error => {
      setAttempts((prevNoOfAttempts) => prevNoOfAttempts - 1)
      console.log(error)
      setIncorrectAnswer(true)
		})
    setAnswer("")

  }


  let content = `You have only ${attempts} attempts remained to answer questions.`
  if(incorrectAnswer && attempts == 0) {
    content = `You answered the question incorrectly in all attempts, This will be logged in your activity. It might result your ABSENCE in class!`
  }
  
  return (
    <div>
       { incorrectAnswer &&
        <div className="remaining-attempts">
        <p className="mb-0"><span className="text-bold mr-3">ALERT:</span>{content}</p>
        </div>
       }

      {show &&  <Modal
         show={() => props.showModal()}
         aria-labelledby="contained-modal-title-vcenter"
         // onHide={() => setShow(false)}
         dialogClassName=" ques-ans"
        //  aria-labelledby="example-custom-modal-styling-title"
       >
         <Modal.Header >
           <Modal.Title id="example-custom-modal-styling-title">
          <span>Q. {props.question.question} <small className='text-danger font-weight-bold'>*</small></span>
           </Modal.Title>
         </Modal.Header>
   <form onSubmit={formSubmitHandler}>
   
   <Modal.Body>
  
   <div  className="card p-2 mb-3">
             <div class="row">
               <div className='col-sm-12'>    
               </div> 
               <div className='col-sm-12'>
               <input type="text" placeholder='Your Answer...' className='p-w-100 mt-2 form-control' required 
               value={answer} 
               onChange={(e) => setAnswer(e.target.value)}
               />
               </div>
             </div>
            </div> 
   
     <blockquote >
     <span className='text-bold'>Very Important:</span> Due to Florida State Government policies, System must verify your active presence during the session! <span className='text-danger'>Incorrect answer of the question after 3 attempts will consider it <b>ABSENCE</b> in the class.</span> Currently, you have <span className='text-bold text-success'>2 remaining attempts</span>
     </blockquote>
   
   </Modal.Body>
   <Modal.Footer>
   <div className=' my-btns '>
   <button  type="submit" className="btn active"
   // onClick={()=>props.closeModal()}
   >Submit Answer</button>
   </div>
   </Modal.Footer>
   
   </form>
    </Modal> }
    </div>
      
     )
}

export default SessionQA;