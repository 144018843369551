import React, { useState , useEffect} from "react";
import "./update-lecture-asset.css";
import Container from 'react-bootstrap/Container';
import Header from '../../../common/header/header'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import {history} from '../../../_helpers/history'
import { CommonService } from '../../../service/common.service';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal'

const updateCourse = ({match}) => {
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const alert = useAlert();
    const baseUrl = CommonService.getBaseUrl()
    const assetId = match.params?.id;
    const lectureId = match.params?.lectureId;

    const [dltShow, setdltShow] = useState(false);
    const delethandleClose = () => setdltShow(false);
    const deleteHandleShow = async () => {
        setdltShow(true);
    }

    useEffect(() => {
         
        if (assetId) {
             
            getLecture();
            getAsset();
        }
    }, []);
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('PRIMARY')
    const [courseName, setCourseName] = useState('')
    const [chapterName, setChapterName] = useState('')
    const [lectureName, setLectureName] = useState('')
    const [isPublished, setIsPublished] = useState(true)
    const [duration, setDuration] = useState('')
    const [fileType, setFileType] = useState('PDF')
    const [fileData, setFileData] = useState('')
    
    const getLecture = () => {
        setLoading(true);
        CommonService.httpGetService(`admin-api/chapter_lecture/lecture_detail?chapter_id=${lectureId}`)
            .then(data => {
                if (data.length > 0) {
                     
                    setLectureName(data[0]['title'])
                    setCourseName(data[0]["Course Name"])
                    setChapterName(data[0]["Chapter Name"])
                    setLoading(false);
                }
            }, error => {
                alert.error(error)
                setLoading(false);
            })
    }

    const getAsset = () => {
         
        setLoading(true);
        CommonService.httpGetService(`admin-api/lecture_asset?id=${assetId}`)
            .then(data => {
                if (data.length > 0) {
                     
                    
                    setId(data[0]["id"])
                    setTitle(data[0]["title"])
                    setDuration(data[0]["estimated_time"])
                    setCategory(data[0]["category"])
                    setFileType(data[0]["type"])
                    // setFileData(data[0]["url"])
                    setLoading(false);
                }
            }, error => {
                alert.error(error)
                setLoading(false);
            })
    }
    const selectFile = event => {
       setFileData(event.target.files[0])
     }


    const handleReset = () => {
        setTitle('')
        setDuration('')
        setCategory('PDF')
        setValidated(false);
        setdltShow(false);
    };

     const handleSubmit = () => {
        setLoading(true);
        const form = document.getElementById("UpdateLectureAsset");
        // const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
             
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
            submitHandler();
        }
       
    };
 

    function submitHandler () {
        try {
             
            const data = new FormData()
            let isPublishedBool = true
            if (isPublished == "false" || isPublished == false) {
                isPublishedBool == false
            }
            if(category == undefined) {
                setCategory('PRIMARY')
            }
            data.append('id' , id)
            data.append('file', fileData)
            data.append('title', title)
            data.append('lecture_id', lectureId)
            data.append('type', fileType)
            data.append('estimated_time', duration)
            data.append('category', category)
            data.append('is_published', isPublishedBool)
            data.append('course_name', courseName)
            data.append('chapter_name', chapterName)
            data.append('lecture_name', lectureName)


            // CommonService.httpFileUpload(`lecture_asset`,data)
            // .then(data => {
            //     alert.success(data)
            // }, error => {
            //     alert.error(error)
            // })
             
            axios.post(`${baseUrl}/admin-api//lecture_asset/update`, data, {
               headers : {
                    'Content-Type' : 'multipart/form-data'
               }
            }).then(res => { // then print response status
                setLoading(false);
                alert.success('Lecture Asset Updated Successfully')
            }, error => {
                setLoading(false);
                alert.error(error)
            })

        } catch (error) {
            setLoading(false);
            alert.error(error);
            // alert('Something went wrong with login')
        }
    }


    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
        <Header></Header>
        <Container fluid>
            <div className="heading">
                <div className="lheading">
                    <span className="headTitle">Update Asset </span>
                    <span className="searchItem"> Lecture : {lectureName} </span>
                </div>
                <div className="rheading">
                    <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>
                    </div>
            </div>

            <Form className="CreateEntryForm" id="UpdateLectureAsset" noValidate validated={validated} >
                <Form.Row>
                    <Form.Group as={Col} controlId="formTitleId">
                        <Form.Label>Title *</Form.Label>
                        <Form.Control value={title}  pattern="[a-zA-Z0-9\s]+" onChange={e => setTitle(e.target.value)} placeholder="Asset Title" required />
                        <Form.Control.Feedback type="invalid">
                                Please fill the title.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formCatagory">
                        <Form.Label>Category</Form.Label>
                        <Form.Control as="select" value={category} custom onChange={e => setCategory(e.target.value)} >
                            <option value="PRIMARY">Primary</option>
                            <option value="SUPPLEMENTARY">Supplementary</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="col-6" controlId="formIsPublished">
                        <Form.Label>Published</Form.Label>
                        <Form.Control as="select" defaultValue={isPublished} custom onChange={e => setIsPublished(e.target.value)}  >
                            <option value="true" >Yes</option>
                            <option value="false">No</option>
                        </Form.Control> 
                    </Form.Group>

                    <Form.Group as={Col} className="col-6" controlId="forDuration">
                        <Form.Label>Duration (In Min) </Form.Label>
                        <Form.Control value={duration} type="number" min="0" onChange={e => setDuration(e.target.value)} placeholder="" />
                        {/* <Form.Control.Feedback type="invalid">
                                Please fill the duration in min.
                        </Form.Control.Feedback> */}
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} className="col-6" controlId="formFileType">
                        <Form.Label>File type *</Form.Label>
                        <Form.Control as="select" value={fileType} defaultValue={fileType} custom onChange={e => setFileType(e.target.value)} required >
                            <option value="VIDEO" >Video</option>
                            <option value="PPT">PPTx</option>
                            <option value="PDF">PDF</option>
                        </Form.Control> 
                    </Form.Group>

                    <Form.Group as={Col} className="col-6" controlId="formFileUpload">
                        <Form.Label>Upload File *</Form.Label>
                        <Form.File  onChange={selectFile} id="custom-file" label="Custom file input" custom required/>
                        <Form.Control.Feedback type="invalid">
                                Please upload the content.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <div className="submitBtnRow">
                    <button type="button" onClick={(e) => handleSubmit()} className="saveBtn"> Submit </button>
                    <button type="button" onClick={() =>  deleteHandleShow()} className="resetBtn"> Reset </button>
                </div>
            </Form>
            <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to reset this form?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
                            </Button>
                            <Button variant="primary"  onClick={() => handleReset()}> Yes</Button>
                        </Modal.Footer>
                </Modal>
        </Container>
    </Container>
    </LoadingOverlay>
    );
};

export default updateCourse;


