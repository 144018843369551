import React, { useRef, useState, useContext, useEffect } from "react";
import "./create-course.css";
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Header from '../../../common/header/header'
// import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import { CommonService } from '../../../service/common.service';
import { history } from '../../../_helpers/history'
import { useForm } from "react-hook-form";
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const createCourse = ({ match }) => {
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const formRef = useRef(null);
    const { register, errors } = useForm();
    const alert = useAlert();
    const [name, setName] = useState('');
    const [code, setCode] = useState('')
    const [description, setDescription] = useState('')
    const [certificateDetail, setCertificateDetail] = useState('<p></p>');
    const [duration_type, setDurationType] = useState('Weeks')
    const [duration, setDuration] = useState('')
    const [max_no_of_students, setMaxNoOfStudents] = useState('')
    const [fee, setFee] = useState('')
    const [passingPercentage, setPassingPercentage] = useState(0)
    const [minTestAttempt, setMinTestAttempt] = useState(0)
    const paramId = match.params?.id;
    const [lectureId, setLectureId] = useState(null);
    const [dltShow, setdltShow] = useState(false);

    const delethandleClose = () => setdltShow(false);

    const deleteHandleShow = async () => {
        setdltShow(true);
    }

    const handleSubmit = () => {
         
        setLoading(true);
        const form = document.getElementById("CreateEntryForm");
        // const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
             
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);

        }
        else {
            handleLoginButtonClick();
        }

    };

    const handleReset = () => {
        //  
        // const form = document.getElementById("CreateEntryForm");
        // form.reset() ;
        setCode('');
        setName('');
        setFee('');
        setDescription('');
        setCertificateDetail('<p></p>');
        setMaxNoOfStudents('');
        setDurationType('Weeks');
        setDuration('');
        setValidated(false);
        setdltShow(false);
        setPassingPercentage(0)
        setMinTestAttempt(0)

    };

    function handleLoginButtonClick() {
        setLoading(true);
        try {
            let obj = {
                // "id": id,
                "code": code,
                "name": name,
                "fee": fee,
                "description": description,
                "course_certificate_details": certificateDetail,
                "max_no_of_students": max_no_of_students,
                "duration_type": duration_type,
                "duration": duration,
                "tenant_id": "TENANT",
                "has_certificate": true,
                "passing_percentage" : passingPercentage,
                "min_test_attempt" : minTestAttempt
            }
            CommonService.httpPostService(`admin-api/courses`, obj)
                .then(users => {
                    //  
                    let lectId = users.data[0].id;
                    setLectureId(lectId)
                    alert.success("Courses has been created successfully");
                    console.log(users)
                    setLoading(false);

                }, error => {
                     
                    alert.error("Please fill all the required fields!");
                    console.log(error);
                    setLoading(false);

                })

        } catch (error) {
            setLoading(false);
             
            alert.error("Please fill all the required fields!");
            // alert('Something went wrong with login')
        }
    }

    useEffect(() => {
        setLoading(true);
         
        CommonService.httpGetService(`admin-api/courses`)
            .then(data => {
               setLoading(false);
            }, error => {
                alert.error(error)
                setLoading(false);
            })
    }, []);



    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading...'
            className="loaderStyle"
        >
            <Container className="containerBox" fluid>
                <Header></Header>
                <Container fluid>
                    <div className="heading">
                        <div className="lheading">
                            <span className="headTitle">Create Course </span>
                            <span className="searchItem"> </span>
                        </div>
                        <div className="rheading">
                            <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>

                        </div>
                    </div>

                    <Form ref={formRef} className="CreateEntryForm" id="CreateEntryForm" noValidate validated={validated}  >
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridCode">
                                <Form.Label>Code *</Form.Label>
                                <Form.Control
                                    name="name"
                                    value={code}
                                    onChange={e => setCode(e.target.value)}
                                    placeholder=""
                                    pattern="[a-zA-Z0-9\s]+"
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please fill the Code.
                        </Form.Control.Feedback>
                            </Form.Group>

                            {errors.name && errors.name.type === "required" && <span className="errorMsgUsr">Please enter your name</span>}
                            <Form.Group as={Col} controlId="formGridName">
                                <Form.Label>Name *</Form.Label>
                                <Form.Control type="text" value={name} onChange={e => setName(e.target.value)} pattern="[a-zA-Z0-9\s]+" placeholder="" required />
                                <Form.Control.Feedback type="invalid">
                                    Please fill name of the course
                        </Form.Control.Feedback>
                            </Form.Group>

                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridDuration">
                                
                                <Form.Label>Duration *</Form.Label>
                                <Form.Group style={{display: "flex"}} controlId="formGridDuration">
                                <Form.Control className="durtype" as="select" type="text" defaultValue={duration_type} custom onChange={e => setDurationType(e.target.value)}  required >
                                <option value="Weeks">Weeks</option>
                                <option value="Days">Days</option>
                                <option value="Hours">Hours</option>
                                <option value="Minutes">Minutes</option>
                                </Form.Control>
                                <Form.Control className="durVal" type="number" min="0" value={duration} onChange={e => setDuration(e.target.value)} placeholder="" required/>
                                <Form.Control.Feedback type="invalid">
                                    Please fill the duration.
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Group>


                            <Form.Group as={Col} controlId="formGridAddress2">
                                <Form.Label>Maximum No. Of Students</Form.Label>
                                <Form.Control type="number" min="0" value={max_no_of_students} onChange={e => setMaxNoOfStudents(e.target.value)} placeholder="" />
                                <Form.Control.Feedback type="invalid">
                                    Please fill the maximum number of students allowed.
                        </Form.Control.Feedback>
                            </Form.Group>

                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>Course Fee *</Form.Label>
                                <Form.Control type="number" min="0.01" step="0.01" value={fee} onChange={e => setFee(e.target.value)} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please fill the course fee.
                        </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridZip" className="descriptionRow">
                                <Form.Label >Description *</Form.Label>
                                {/* <textarea className="descriptionTxtBox"></textarea> */}
                                <Form.Control value={description} onChange={e => setDescription(e.target.value)} required />
                                <Form.Control.Feedback type="invalid">
                                    Please fill the description of course.
                        </Form.Control.Feedback>
                            </Form.Group>


                        </Form.Row>


                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>Passing Percentage *</Form.Label>
                                <Form.Control type="number" min="0" max="100"  value={passingPercentage} onChange={e => setPassingPercentage(e.target.value)} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please fill the Passing percentage of course.
                        </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridZip" className="descriptionRow">
                                <Form.Label >Minimum Test To be Attempted *</Form.Label>
                                {/* <textarea className="descriptionTxtBox"></textarea> */}
                                <Form.Control type="number" min="0" value={minTestAttempt} onChange={e => setMinTestAttempt(e.target.value)} required />
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                        </Form.Control.Feedback>
                            </Form.Group>


                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label >Ceritficate Description *</Form.Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={certificateDetail}
                                    onChange={(event, editor) => {
                                        setCertificateDetail(editor.getData())
                                    }}
                                />
                            </Form.Group>
                        </Form.Row> 
                        <div className="submitBtnRow">
                            <button type="button" onClick={() => handleSubmit()} className="saveBtn"> Submit </button>
                            <button type="button" onClick={() => { deleteHandleShow() }} className="resetBtn"> Reset </button>

                             <Link to={`/view-chapter/${lectureId}`} className="" disabled={lectureId == null ? true : false}> <button type="button" className="resetBtn">Chapters</button> </Link> 


                        </div>
                    </Form>

                    <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to reset this form?
                    </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
                            </Button>
                            <Button variant="primary"  onClick={() => handleReset()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>


                </Container>
            </Container>
        </LoadingOverlay>
    );
};

export default createCourse;


