import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// const OngoinClassStudentsComponent = ({classId, studentData,channelName}) => {
const StudentContractComponent = React.memo(function StudentContractComponent(props) {

    useEffect(() => {
        console.log('Student Contact Data props : ', props)
    }, [props]);

    return (
        <div className="container-fluid mb-5">
            <h4 className="text-center mb-3">CONTRACT FOR UNARMED SECURITY GUARD SCHOOL</h4>
            <div className="student-info">
                <h6 style={{ textDecoration: "underline", fontWeight : "bold" }}>STUDENT INFORMATION</h6>
                <div className="row">
                    <div className="col-4">
                        Student Name :
                    </div>
                    <div className="col-8 border-bottom">
                        {props.name}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        Address :
                    </div>
                    <div className="col-8 border-bottom">
                        {props.address}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        City, State, Zip:
                    </div>
                    <div className="col-8 border-bottom">
                        {props.cityName} ,{props.stateName}, {props.zip}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        Phone :
                    </div>
                    <div className="col-8 border-bottom">
                        {props.mobileNo}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        Email :
                    </div>
                    <div className="col-8 border-bottom">
                        {props.email}
                    </div>
                </div>

                <div className="row mt-4">
                    <p style={{ textIndent: "50px" }}>
                        This Agreement is entered into on the Effective Date by and between <span style={{ textDecoration: "underline", fontWeight : "bold" }}>{props.name}</span>, an individual (“Student”), and FLORIDA SECURITY SCHOOL LLC, a Florida Limited liability company, whose address is 11443 W Palmetto Park Rd, Boca Raton, FL 33428 (“School”), and <span style={{ textDecoration: "underline", fontWeight : "bold" }}>{props.teacherName}</span>, an individual (“Instructor”) (Student, School and Instructor shall be referred to as the “Parties”).
                    </p>
                    <p style={{ textIndent: "50px" }}>
                        In consideration of the covenants and obligations contained herein and other good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the Parties hereto hereby agree as follows:
                    </p>

                    <ul style={{ listStyleType: "decimal", listStylePosition: "outside" }}>
                        <li> Student has paid School <span style={{ textDecoration: "underline", fontWeight : "bold" }}> $ {props.fees ? props.fees : 0} </span> (“Tuition”) for enrollment in Unarmed Security Guard Training (the “Class”), on <span style={{ textDecoration: "underline", fontWeight : "bold" }}>{props.classJoinDate}</span> (“Class Date”) at ONLINE www.flsecurityschool.com (“Premises”)
                        </li>
                        <li> Student acknowledges that School and Instructor have the right to cancel or reschedule the Class, for any reason. In the event of a cancellation, Student will receive a full refund of the paid Tuition.</li>
                        <li> Student may notify School or Instructor in writing at <strong>11443 W Palmetto Park Rd, Boca Raton, FL 33428</strong>  no less than 5 days prior to the Class date for a full refund of the Tuition. If notice of cancellation is not received within the time specified, Student will not be entitled to a refund of the Tuition.
                        </li>
                        <li> Student’s failure to abide by all rules and codes of conduct as determined by the School or Instructor will result in the Student’s ejectment from the Class and forfeiture of the Tuition.
                        </li>
                        <li> Student knowingly, voluntarily and willingly assumes all risk inherit to and relating to the Class.
                        </li>
                        <li> Student acknowledges that School and Instructor do not guarantee success in passing any test(s), qualifying for a State of Florida Division of Licensing Class D Security Officer Licenses, or the transferability of credits to a college, university or institution.
                        </li>
                        <li> Student hereby forever release, acquit, discharge, waive, hold harmless and covenant not to sue the School or Instructor, and their respective officers, directors, members, managers, employees, agents, attorneys in fact, assigns and successors in interest successors, heirs, executors, personal representatives, administrators, and assigns, jointly and severally, from or for any and all liabilities, claims, demands damages or causes of action, including all costs, medical expenses and attorney's fees associated therewith, that Student may have by reason, including, but not limited to any claims or losses arising by reason of the School and/or Instructor’s passive or active negligence, or any hidden, latent or obvious defects at or on the Premises or in any equipment used, whether or not supplied or inspected by Instructor.
                        </li>
                        <li> Student agree to indemnify, hold harmless and defend School or Instructor, and their respective officers, directors, members, managers, employees, agents, attorneys in fact, assigns and successors in interest successors, heirs, executors, personal representatives, administrators, and assigns from any and all losses, liabilities, claims, damage, or costs, including attorney's fees, that the Instructor might incur as a result of the Student’s participation in the Class, whether caused by the negligence of the Instructor or otherwise.
                        </li>
                        <li> Student promises to not sue any of the School or Instructor, and their respective officers, directors, members, managers, employees, agents, attorneys in fact, assigns and successors in interest successors, heirs, executors, personal representatives, administrators, and assigns for any cause of action whatsoever, even for claims arising from the negligence of the Instructor or from hidden, latent, or obvious defects in the Premises or equipment supplied or approved by the Instructor.
                        </li>
                        <li> This Agreement shall not be subject to any claim of mistake of fact, and this Agreement expresses a full and complete waiver of liability claimed and disputed, and regardless of the type of injury, or circumstances  concerning any injury. This Agreement is intended to forever bar any claim the Student might have and is intended to  be final and complete.
                        </li>
                        <li> This Agreement contains the full and complete Agreement between the School, Instructor and the Student with regard to the subject matter of this Agreement. Any prior oral representations or agreements concerning this subject matter are expressly superseded by this Agreement which may not be altered, amended or revoked except by an instrument in writing signed by the Instructor.
                        </li>
                        <li> This Agreement shall be governed by the laws of the State of Florida.
                        </li>
                        <li> If any provision of this Agreement or its application to any party or circumstances shall be determined to be invalid and unenforceable to any extent, the remainder of this Agreement or the application of such provision to such person or circumstances, other than those as to which it is so determined invalid or unenforceable, shall not be affected thereby, and each provision hereof shall be valid and shall be enforced to the fullest extent permitted by law.
                        </li>
                        <li> This Agreement may be signed in duplicate counterparts, and any one of which shall be deemed an original.
                        </li>
                        <li> This Agreement may be executed and shall be fully enforceable by a facsimile signature or electronically transmitted PDF image of a signature, which shall be deemed an original signature for purposes of executing this Agreement.
                        </li>
                        <li> Student’s Representations:
                            <ul style={{ listStyleType: "lower-alpha", listStylePosition: "outside" }}>
                                <li>
                                    I acknowledge that I have read this Agreement, understand it and voluntarily sign it. I acknowledge that no oral representations, statements or inducements apart from this written Agreement have been made by or on behalf of the Instructor.  <span style={{ color: "red" }}> <span style={{ textDecoration: "underline", fontWeight : "bold" }}> {props.nameInitials ? props.nameInitials : ''} </span> Initials</span>
                                </li>

                                <li>I acknowledge the risks and dangers that exist in defensive tactics and techniques and my participation in the Class are considered "HIGH RISK" and assume the risk(s) that the Class could result in physical and/or emotional injury, paralysis, death, or damage to myself, to property, or to third-parties. I understand that such risks simply cannot be eliminated without jeopardizing the essential qualities of the Class. The risk(s) include, but are not limited to, among other things: the undersigned or third parties being  shot by a firearm; suffering hearing loss, eye injury or loss, inhalation or contact with airborne contaminants and or flying debris, and being struck anywhere on my body.   <span style={{ color: "red" }}> <span style={{ textDecoration: "underline", fontWeight : "bold" }}> {props.nameInitials ? props.nameInitials : ''} </span> Initials</span>     </li>

                                <li>I understand that the Instructor has a difficult job to perform. The School and Instructor seek safety but are not infallible. The School and Instructor might be unaware of a participant’s state of fitness or abilities. The School and Instructor may give inadequate warnings or instructions, and the equipment used might malfunction. <span style={{ color: "red" }}> <span style={{ textDecoration: "underline", fontWeight : "bold" }}> {props.nameInitials ? props.nameInitials : ''} </span> Initials</span>     </li>

                                <li>I understand that the School or Instructor may terminate my enrollment if I fail to comply with attendance, academic and financial requirements or if I fail to abide by established standards of conducts. While enrolled in the school, I understand that I must maintain satisfactory academic progress as described in the school catalogue and my financial obligation to the school must be paid in full before a certificate may be awarded.  <span style={{ color: "red" }}> <span style={{ textDecoration: "underline", fontWeight : "bold" }}> {props.nameInitials ? props.nameInitials : ''} </span> Initials</span>     </li>

                                <li>I understand that the school does not guarantee job placement to upon graduation.  <span style={{ color: "red" }}> <span style={{ textDecoration: "underline", fontWeight : "bold" }}> {props.nameInitials ? props.nameInitials : ''} </span> Initials</span>     </li>

                                <li>I understand that complaints, which cannot be resolved by direct negotiation with the school in accordance to its written grievance policy may be filed with the Florida Division of Licensing. All students’ complaints must be submitted in writing.  <span style={{ color: "red" }}> <span style={{ textDecoration: "underline", fontWeight : "bold" }}> {props.nameInitials ? props.nameInitials : ''} </span> Initials</span>     </li>

                                <li>I understand that Instructor will only teach the curriculum outline that is required by the State of Florida Division of Licensing as it pertains to Class D Security Officer Licenses.  <span style={{ color: "red" }}> <span style={{ textDecoration: "underline", fontWeight : "bold" }}> {props.nameInitials ? props.nameInitials : ''} </span> Initials</span>     </li>

                                <li>I agree to follow all instructions given by School or Instructor, immediately, except if I should have any reservations about any of the Instructor’s instructions. If I have any reservations, I shall immediately notify Instructor of these reservations.  <span style={{ color: "red" }}> <span style={{ textDecoration: "underline", fontWeight : "bold" }}> {props.nameInitials ? props.nameInitials : ''} </span> Initials</span>     </li>

                                <li>I agree to be personally responsible for my own safety. I agree that at any time, if I am not capable, whether physically/mentally challenged in completing the required demands and/or functions of the course, I am required to immediately advise the Instructor. I shall immediately notify an Instructor of any injury that I receive, or I observe injury to any other participant. <span style={{ color: "red" }}> <span style={{ textDecoration: "underline", fontWeight : "bold" }}> {props.nameInitials ? props.nameInitials : ''} </span> Initials</span>     </li>

                                <li>I shall perform only those techniques taught by the School or Instructor and shall not improvise additional techniques on my own during the course of this instruction. I agree that the School and Instructor have no responsibility to accommodate me or change the instruction of the course.  <span style={{ color: "red" }}> <span style={{ textDecoration: "underline", fontWeight : "bold" }}> {props.nameInitials ? props.nameInitials : ''} </span> Initials</span>     </li>

                                <li>I consent to having my photograph taken while participating in the Class and other events. These pictures may be displayed in any and all publications, including but not limited to newsletters, the official website, brochures, advertisements, and any and all media including video presentations by Instructor.   <span style={{ color: "red" }}> <span style={{ textDecoration: "underline", fontWeight : "bold" }}> {props.nameInitials ? props.nameInitials : ''} </span> Initials</span>     </li>

                                <li>I have had sufficient opportunity to read this entire document. I have read, acknowledge and understand this Agreement and fully understand its terms and understand that I am giving up substantial rights, including my right to sue. I acknowledge that I am signing this agreement freely and voluntarily and intend my signature to be a complete and unconditional release of all liability to the greatest extent allowed by law.  <span style={{ color: "red" }}> <span style={{ textDecoration: "underline", fontWeight : "bold" }}> {props.nameInitials ? props.nameInitials : ''} </span> Initials</span>     </li>
                            </ul>
                        </li>
                    </ul>

                </div>
                <div className="row declaration-section mt-4">
                    <p style={{ textIndent: "50px" }}>
                        IN WITNESS WHEREOF, the parties have executed this Agreement on the day and date set forth opposite each signature below. The date of the last signature shall be the Effective Date.
                    </p>
                    <div className="container mt-4">
                        <h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>STUDENT : </h6>
                        <div className="row"> <div className="col">Student Name :  <span className=" border-bottom">{props.name}</span> </div> <div className="col">Date :  <span className=" border-bottom">{props.createdOn}</span> </div>
                        </div>
                    </div>

                    <div className="container mt-4">
                        <h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>SCHOOL : </h6>
                        <h6 >FLORIDA SECURITY SCHOOL LLC, a Florida Limited liability company</h6>
                        <div className="row"> <div className="col">By :  <span className=" border-bottom">Shlomo Garby, Manager</span> </div> <div className="col">Date :  <span className=" border-bottom">{props.createdOn}</span> </div>
                        </div>
                    </div>
                    <div className="container mt-4">
                        <h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>INSTRUCTOR : </h6>
                        <div className="row"> <div className="col">Name :
                        <span className=" border-bottom">
                                <span>{props.teacherName}</span>
                                {props.teacherSignature ? <span style={{display : "flex",flexDirection:"column"}}><img className="img-fluid" style={{maxWidth:"200px",width:"200px"}} src={props.teacherSignature}></img></span> : ''}
                        </span>
                        </div>
                            <div className="col">Date :  <span className=" border-bottom">{props.createdOn}</span> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
})

StudentContractComponent.propTypes = {
    name: PropTypes.any
};

StudentContractComponent.defaultProps = {
    name: ""
};



export default StudentContractComponent;
