import React, { useState, useContext, useEffect } from "react";
import "./Courses.css";
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CardColumns from 'react-bootstrap/CardColumns';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";
import Header from '../../common/header/header'
import LoadingOverlay from 'react-loading-overlay';
import { CommonService } from '../../service/common.service';
import { history } from "../../_helpers/history";
var jwt = require('jsonwebtoken');
import { useAlert } from "react-alert";
import QAModal from "../../common/modal/QAModal";

const Courses = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [showQAModal, setShowQAModal] = useState(true)
    const [role, setRole] = useState('');
    const [status, setStatus] = useState(true)
    const alert = useAlert();
    useEffect(() => {
        var userData = {}
        if (localStorage.getItem('user') != null && localStorage.getItem('user') != undefined) {
            userData = JSON.parse(localStorage.getItem('user'))
            setRole(userData.role)
            // if (userData.role == 'TEACHER') {
            // console.log(`Role of user is before subscription: #`,userData.role)
            // }
        }

        const getCourses = async () => {
            setLoading(true);
            CommonService.httpGetService(`admin-api/courses/get-courses/${userData.role}/${userData.id}`).then(res => {
                setData(res);
                setLoading(false);
            }, error => {
                setLoading(false)
            })

        }

        getCourses();

        const checkUserStatus = () => {
            CommonService.httpGetService(`admin-api/security_questions/user_status?user_id=${userData.id}`)
            .then(data => {
                if(data && data.data && data.data.is_sq_answered == true){
                    setStatus(true)
                } else {
                    setStatus(false)
                }
            }).catch(error => {
                // alert.error(error)
                console.log(error)
            })
        }

        checkUserStatus()
        // setLoading(false);
    }, []);

    function getRandomColor() {
        let myColors = ['kt-circle-1', 'kt-circle-2', 'kt-circle-3', 'kt-circle-4', 'kt-circle-5',
            'kt-circle-6', 'kt-circle-7', 'kt-circle-8', 'kt-circle-9'];
        let color = myColors[Math.floor(Math.random() * myColors.length)];
        //  setbgColor(color);
        return color

    }

    const  buyCourse = async (course_id) => {
        var classData = await getUpcomingClass(course_id);
        debugger
        if(classData){
            let jwtobj = {
                    course_id : course_id,
                    class_id: classData[0] ? classData[0].id : null
                }
            let path = `/payment?payment_key=${jwt.sign(jwtobj, process.env.PAYMENT_SECRET)}&payment_redirect_url=${process.env.baseUrl}/payment_status`;
            history.push(path);
        }else{
            alert.error("No Upcoming Class Available")
        }

    }
    const getUpcomingClass = (id) => {
        return new Promise(async (resolve, reject) => {
        CommonService.httpGetService(`admin-api/class/get-upcoming-classes/${id}`).then(res => {
           resolve(res)
        },err=>{
            resolve(null)
        })
        })
    }


    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading...'
            className="loaderStyle"
        >
        {!status && role == 'STUDENT' && <QAModal showModal={showQAModal} closeModal={()=>setShowQAModal(false)} />}
            <Container className="containerColorCourse" fluid>
                <Header></Header>
                {/* <span>
                <input type="text" className="searchcourse" />
            </span> */}
            <div className="dark-blue-bg"></div>
                <div className="w-90per mt-100minus">
                <h2 className="pageTitle mt-50 mb-25 text-center">COURSES</h2>
                <div className="row ">                    
                    {

                        data.map((datal, index) => (
                                <Col xl={3} lg={4} md={6} xs={12}   key={index} className="animate__animated animate__fadeInUp">
                                    <Card className="course-card shadow-box ">
                                        <Card.Body style={{ borderRadius: "15px" }} >
                                            <Card.Title className="card-title" style={{ fontWeight: 600 }} title={datal.name}>{datal.name}</Card.Title>
                                            <div className="courseCard">
                                                <ul className="list-unstyled">
                                                    <li className="detailStyle"><b>Duration :</b> {datal.duration} {datal.duration_type}</li>
                                                    <li className="detailStyle">{datal.first_name} {datal.last_name}</li>
                                                    {
                                                !datal.is_selected && (role == 'STUDENT') ? (
                                                    <li className="detailStyle"> Fee : $ {datal.fee} </li>
                                                ) : ""
                                            }
                                                   
                                                </ul>
                                                <p >
                                                    {datal.description}
                                                </p>
                                            </div>
                                            
                                            {
                                                !datal.is_selected && (role == 'STUDENT') ? (
                                                    <Link onClick={()=>buyCourse(datal.id)}>
                                                        <div className="startJoinBtn fontThirteen my-btns">
                                                            <b className="btn">Buy This Course</b>
                                                        </div>
                                                    </Link>
                                                ) : (
                                                    <div className="my-btns row mt-10">
                                                        <div className="col-sm-6">
                                                            <Link  to={`/courses/${datal.id}/classes`}>
                                                                <div className="startJoinBtn fontThirteen btn active w-50per purchase-glow">
                                                                    <span className="">Open Course</span> 
                                                                </div>
                                                            </Link>
                                                        </div>
                                                            <div className="col-sm-6">
                                                            <Dropdown >
                                                            <Dropdown.Toggle variant="" id="dropdown-basic" className="btn active w-50per">
                                                                      <span >More  <i className="fas fa-ellipsis-v "></i></span>
                                                            </Dropdown.Toggle>
                                                            
                                                            <Dropdown.Menu className="read-status">
                                                                {
                                                                    role == 'ADMIN' ? <Dropdown.Item href={`/update-course/${datal.id}`}><span>Edit</span></Dropdown.Item>  : ''
                                                                }
                                                                {
                                                                    role != 'STUDENT' ?  <Dropdown.Item href={`/courses-resource/create/${datal.id}`}><span>Add Resource</span></Dropdown.Item> : '' 
                                                                }
                                                            
                                                            
                                                            <Dropdown.Item href={`/courses-resource/view/${datal.id}`}><span>View Resource</span></Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                            </div>
                                                           
                                                      </div>   
                                                    )
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>

                            

                        ))
                    }
                </div>
                </div>
            </Container>
        </LoadingOverlay>
    );
};

export default Courses;
