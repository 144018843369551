import React, { useState, useContext, useEffect, useReducer } from "react";
// import "./testComponent.css";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { CommonService } from '../../../service/common.service';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useAlert } from "react-alert";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl'
const SubjectiveComponent = ({ testResultId,testDetails,questionNo, selectedQuestion,questionType,questionIndex, handleSkip, handleLoading }) => {
    const [answer, setAnswer] = useState(null);
    const alert = useAlert();
    
    const onClickSubmit = () => {
        try {
            setLoading(true);
            if (answer != null) {
                var userData = JSON.parse(localStorage.getItem('user'))
                let reqObj = {
                    "user_test_result_id": testResultId,
                    "course_question_id": selectedQuestion.id,
                    "user_id": userData.id,
                    "answer": answer
                }
                console.log(reqObj)
                CommonService.httpPostService(`admin-api/user-test`,reqObj)
                .then(data => {
                    console.log(data);
                    skipQuestion(selectedQuestion)
                    setAnswer(null)
                    // alert.success('Answer has been submitted')
                    setLoading(false);
                }, error => {
                    console.error('Error in submitting answer SubjectiveComponent', error)
                    alert.error('Error in submitting answer')
                    setLoading(false);
                })
                
            }else{
                alert.error('Please choose your answer.')
                setLoading(false);
            }
            
        }
        catch (error) {
            alert.error('Something went wrong..')
            setLoading(false);
        }
    }

    const skipQuestion = (selectedQuestion) =>{
        handleSkip(selectedQuestion,testDetails,questionType,questionIndex,questionNo)
    }

    const setLoading = (status) =>{
        handleLoading(status)
    }

    return (

        <>
            <div className="qsHeading mb-4">
                <span className="quesNum">Question {selectedQuestion.question_no}</span>
                <span className="quesNum">Score: {selectedQuestion?.marks}  <i className="fas fa-question-circle"></i></span></div>
            <div className="currentQ">
                <span className="areaQues"> <span dangerouslySetInnerHTML={{ __html: selectedQuestion.question }}></span></span>
                <div className="areaAns">

                    <CKEditor
                        className="editorStyle"
                        editor={ClassicEditor}
                        data={answer}
                        onInit={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setAnswer(data)
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                    />
                </div>
                <div className="submitBtnRow">
                    {/* <button type="button" className="btnSkip"  onClick={() => skipQuestion(selectedQuestion.id)}> Skip </button> */}
                    <button type="button" className="btnSubmit" onClick={() => onClickSubmit(selectedQuestion.id)}> Submit </button>
                </div>
            </div>

        </>

    );
};

export default SubjectiveComponent;