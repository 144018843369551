import React, { useState, useEffect } from "react";
import { history } from '../../_helpers/history'
import LoadingOverlay from 'react-loading-overlay';
import queryString from 'query-string';
import Header from '../../common/header/header'
import "./payment.css";
import { Link } from "react-router-dom";
import { CommonService } from '../../service/common.service';
var jwt = require('jsonwebtoken');
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import { useAlert } from "react-alert";
import TermsAndConditions from '../../components/TermsAndConditions'
const PaymentStatus = () => {
    const [loading, setLoading] = useState(true);
    const [loadingText, setLoadingText] = useState('Loading');
    const alert = useAlert();
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage && localStorage.getItem('user') ? true : false);
    const [orderId, setOrderId] = useState('');
    const [courseId, setCourseId] = useState('');
    const [classId, setClassId] = useState('');
    const [courseName, setCourseName] = useState('');
    const [className, setClassName] = useState('');
    const [userInfo, setUserInfo] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [paymentKey, setPaymentKey] = useState('');
    const [acceptedTnC, setAcceptedTnC] = useState(false);
    const [validated, setValidated] = useState(false);
    const [parsedTokenData, setParsedTokenData] = useState(false);
    const queryParams = queryString.parse(location.search.replace(';', '&'))


    const [showTnc, setShowTnc] = useState(false);
    const handleTncClose = () => setShowTnc(false);
    const handleTncShow = () => setShowTnc(true);

    //STUDENT DATA 
    const [isNewUser,setIsNewUser] = useState(false)
    const [studentName, setStudentName] = useState('');
    const [gender, setGender] = useState('');
    const [studentAddress1, setStudentAddress1] = useState('');
    const [studentAddress2, setStudentAddress2] = useState('');
    const [studentAddress3, setStudentAddress3] = useState('');
    const [studentCityName, setStudentCityName] = useState('');
    const [studentStateName, setStudentStateName] = useState('');
    const [studentCountryName, setStudentCountryName] = useState('');
    const [studentZip, setStudentZip] = useState('');
    const [studentMobileNo, setStudentMobileNo] = useState('');
    const [studentPrimaryEmail, setStudentPrimaryEmail] = useState('');
    const [showEdit, setShowEdit] = useState(false)
    useEffect(() => {
        console.log(queryParams);
        // if (localStorage && localStorage.getItem('user') != null && localStorage.getItem('user') != undefined) {
        //     let data = JSON.parse(localStorage.getItem('user'))
        //     queryParams['userInfo'] = data
        //     setUserInfo(data)
        // }
        setLoading(false)
        setPaymentStatus(queryParams.payment_status ? queryParams.payment_status : 'failed')
        setOrderId(queryParams.order_id)
        setCourseId(queryParams.course_id)
        setCourseName(queryParams.course_name)
        if (queryParams.token) {
            setPaymentKey(queryParams.token)
            parseToken(queryParams.token)
        }


    }, [])

    const parseToken = (token) => {
        let response = {}
        try {
            jwt.verify(token, process.env.PAYMENT_SECRET, async (err, result) => {

                if (err) {
                    response = {};
                } else {
                    response = result;
                    setParsedTokenData(result)
                    console.log('Billing Info')
                    if (result.billing_info) {
                        setUserInfo(result.billing_info)
                        setOrderId(result.order_id)
                        setClassId(result.class_id)
                        setCourseId(result.course_id)
                        setCourseName(result.course_name)
                        setClassName(result.class_name)
                        // Student Data
                        setIsNewUser(result.new_user ? result.new_user : false)
                        setStudentName(result.billing_info.full_name)
                        setGender(result.billing_info.gender ? result.billing_info.gender : null)
                        setStudentAddress1(result.billing_info.address_1)
                        setStudentAddress2(result.billing_info.address_2)
                        setStudentAddress3(result.billing_info.address_3)
                        setStudentCityName(result.billing_info.city_name)
                        setStudentStateName(result.billing_info.state_name)
                        setStudentCountryName(result.billing_info.country)
                        setStudentZip(result.billing_info.zip)
                        setStudentMobileNo(result.billing_info.mobile_no)
                        setStudentPrimaryEmail(result.billing_info.primary_email)
                    }
                }
            })
            return response
        }
        catch (err) {

            response = {};
            return response
        }
    }
    const setTNC = (event) => {
        setAcceptedTnC(event.target.checked)
    }

    const handleSubmit = () => {
        setLoading(true);
        const form = document.getElementById("CreateLectureForm");
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
            handleSubmitContract();
        }

    };

    function handleSubmitContract() {
        try {
            console.log(parsedTokenData)
            let billing_info = parsedTokenData.billing_info

            let obj = {
                "user_id": parsedTokenData.user_id,
                "full_name": studentName,
                "gender": gender ? gender : null,
                "address_1": studentAddress1,
                "address_2": studentAddress2,
                "address_3": studentAddress3,
                "city_name": billing_info.city_name,
                "state_name": billing_info.state_name,
                "country": billing_info.country,
                "zip": billing_info.zip,
                "mobile_no": studentMobileNo,
                "primary_email": studentPrimaryEmail,
                "class_id": parsedTokenData.class_id,
                "order_id": parsedTokenData.order_id,
                "is_new_user" : isNewUser
            }
            CommonService.httpPostService(`admin-api/user-contract`, obj)
                .then(res => {
                    alert.success("Agreement signed. Please check student's email for further instructions.",{
                        timeout: 10000, 
                      })
                    setLoading(true)
                    setLoadingText('Redirecting to home')
                    setTimeout(() => {
                        history.push(`/thankyou`)
                    }, 2000);
                }, error => {
                    setLoading(false);
                    alert.error(error)
                })


        } catch (error) {
            setLoading(false);
            alert.error("Please fill the required details!");
        }
    }

    function retryPayment(){
        let obj = {
            course_id : courseId,
            class_id : classId
        }
        debugger
        let payment_key = `${jwt.sign(obj, process.env.PAYMENT_SECRET)}`
        const paymentUrl = `${CommonService.getBaseUrl()}/payment?payment_key=${payment_key}&payment_redirect_url=${CommonService.getBaseUrl()}`
        console.log(paymentUrl)
        location.href = paymentUrl
    }


    return (
        <LoadingOverlay
            active={loading}
            spinner
            text={loadingText}
            className="loaderStyle"
        >
            {isLoggedIn ? <Header></Header> : ''}
            {/* <div className="success-view w-100">
                    <span className="check-icon"><i className="fas fa-check-circle"></i></span>
                    <span className="success-info-col">
                        <span className="success-msg">BOOKING SUCCESSFUL</span>
                        <span className="success-info">Confirmation has been sent to your email address</span>
                    </span>
                </div> */}

            {
                paymentStatus == 'success' ? (
                    <div className="container mt-50">
                        <div className="justify-content-center text-center">
                            <div className="paymentDetails">                                
                                <h2><span className="check-icon"><i className="fas fa-check-circle success"></i></span> Success</h2>
                                <h3>Dear, {studentName}</h3>
                                <p>Florida Security School has processed your payment successfully.</p>
                                <p>Thank you for purchasing Course : <span dangerouslySetInnerHTML={{ __html: courseName }}></span>.</p>
                                <p>Please review the students detailes below for accuracy. If you need to make changes you can do so now.</p>
                                <p>Once you accept the terms and condtions and Continue you will be getting email to verify your email address and and set up your password to log in to the class at the scheduled date and time.</p>
                                <p><b>Order Id : {orderId}.</b></p>
                                 </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="shadow-box mt-20 mn-h-350">
                                    <h4  className="titleBar">Billing Info</h4>
                                    <div className="p-20">
                                        <ul className="list-unstyled ">
                                            <li ><b>Name</b> : {parsedTokenData?.billing_info?.full_name}</li>
                                            <li ><b>Email</b> : {parsedTokenData?.billing_info?.primary_email}</li>
                                            <li ><b>Phone</b> : {parsedTokenData?.billing_info?.mobile_no}</li>
                                            <li ><b>Address</b> : {parsedTokenData?.billing_info?.address_1}, {parsedTokenData?.billing_info?.city_name} {parsedTokenData?.billing_info?.state_name} {parsedTokenData?.billing_info?.country} - {parsedTokenData?.billing_info?.zip}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        <div className="col-md-6 col-sm-12">
                                <div className="shadow-box mt-20 mn-h-350">
                                    <h4 className="titleBar">Student Info</h4>                              
                                   <div className="p-20">
                                   <Form id="CreateLectureForm" noValidate validated={validated}>
                                        {
                                            showEdit == true ? <>
                                            <div className="row">
                                            <div className="col-xs-12 col-sm-12" controlId="formGridname">
                                                <Form.Label>Name *</Form.Label>
                                                <Form.Control type="text" placeholder="" value={studentName} onChange={e => setStudentName(e.target.value)} pattern="[a-zA-Z0-9\s]+" required>
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    Please fill your name.
                                                </Form.Control.Feedback>
                                            </div>
                                                <div className="col-xs-12 col-sm-6" controlId="formGridEmail">
                                                    <Form.Label>Email Address *</Form.Label>
                                                    <Form.Control type="text" value={studentPrimaryEmail} onChange={e => setStudentPrimaryEmail(e.target.value)} pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder="" required>
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please fill your email address.
                                                     </Form.Control.Feedback>
                                            </div>
                                            <div className="col-xs-12 col-sm-6"  controlId="formGridMobile">
                                                <Form.Label >Mobile Number *</Form.Label>
                                                <Form.Control value={studentMobileNo} onChange={e => setStudentMobileNo(e.target.value)} pattern="(?<!\d)\d{10,12}(?!\d)" maxLength="12" type="text" required>
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    Invalid Phone Number
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        
                                            </> :  <>
                                            
                                            <span className="pull-right"><button className="btnSubmit m-0 py-0" onClick={() => {
                                                setShowEdit(true)
                                            }}><i className="fas fa-edit"></i> Edit</button></span>

                                            <ul className="list-unstyled">
                                            <li> <span><b>Name</b> : {parsedTokenData?.billing_info?.full_name}</span>  </li>
                                            <li ><b>Email</b> : {parsedTokenData?.billing_info?.primary_email}</li>
                                            <li ><b>Phone</b> : {parsedTokenData?.billing_info?.mobile_no}</li>
                                            <li ><b>Address</b> : {parsedTokenData?.billing_info?.address_1}, {parsedTokenData?.billing_info?.city_name} {parsedTokenData?.billing_info?.state_name} {parsedTokenData?.billing_info?.country} - {parsedTokenData?.billing_info?.zip}</li>
                                            </ul>
                                            
                                            </>
                                        }
                                        
                                            <div> <strong>Please verify Students info.</strong> </div>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridTnC">
                                                <Form.Check type="checkbox">
                                                    <Form.Check.Input id="tnCcheckBox" onChange={() => setTNC(event)} value={acceptedTnC} type="checkbox" required />
                                                    <span >Accept <span className="forgotpwd" onClick={() => handleTncShow()}> Terms &amp; Conditions</span></span>
                                                    <Form.Control.Feedback type="invalid">Please Accept Terms &amp; Condition!</Form.Control.Feedback>
                                                </Form.Check>
                                            </Form.Group>
        
                                        </Form.Row>
                                        <div className="submitBtnRow">
                                            <button type="button" onClick={() => handleSubmit()} className="saveBtn"> Continue </button>
        
                                        </div>
                                    </Form>
                               
                                   </div>
                                </div>
                        </div>
                             
                            
                        </div>
                               <Modal show={showTnc}
                                    onHide={handleTncClose}
                                    backdrop="static"
                                    keyboard={false}>
                                    <Modal.Header className="d-flex justify-center">
                                        <Modal.Title >Sign Contract Terms and Conditions</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="tnc-body">
                                       <TermsAndConditions></TermsAndConditions>
                                        </Modal.Body>
                                    <Modal.Footer className="tnc-footer">
                                        <button className="loginBtn" onClick={() => {
                                            handleTncClose()
                                            let chkBoxBtn = document.getElementById('tnCcheckBox')
                                            if (chkBoxBtn && !chkBoxBtn.checked) {
                                                chkBoxBtn.click()
                                            }
                                        }}>
                                            Accept
                                    </button>
                                    </Modal.Footer>
                                </Modal>
                           
                    </div>
                ) : ''
            }
            {
                paymentStatus == 'failed' ? (
                    <div className="container">
                        <div className="justify-content-center row text-center">
                            <div className="col-6 mt-5">
                                <span className="check-icon"><i className="fas fa-times-circle failed"></i></span>
                                <h2>Failed</h2>
                                <h3>Dear, {studentName}</h3>
                                <p>Payment Failed for the purchase of course : {courseName}.</p>
                                <p>If any amount deducted from your account please contact us.</p>
                                {/* <p>
                                    <Link id="coursesNav" to={`/courses/${courseId}/classes`} className="header-menu-item">
                                        <button type="button" name="" id="" className="btn btn-primary btn-sm" >Go To Home</button>
                                    </Link>
                                </p> */}
                                <div>
                                {
                                   classId && classId != '' ?
                                    <button type="button" className="btn btn-sm btn-secondary mr-2"  onClick={()=> retryPayment()}> Retry </button> : ''
                                }
                                <button type="button" name="" id="" className="btn btn-primary btn-sm" onClick={()=> {
                            location.href=process.env.mainWebsiteUrl
                        }}>Go To Home</button>
                                </div>
                            </div>

                        </div>
                    </div>
                ) : ''
            }
        </LoadingOverlay>
    );
};

export default PaymentStatus;
