import React from "react";
import { Router, Route,Switch,Redirect } from "react-router-dom";
import MainLayout from "./layout/MainLayout";

import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Login from "./pages/login/Login"
import Payment from "./pages/payment/payment"
import PaymentStatus from "./pages/payment/payment_status"
import Signup from "./pages/signup/Signup"
import UpdatePassword from "./pages/updatepassword/UpdatePassword"
import Courses from "./pages/courses/Courses"
import { history } from "../src/_helpers/history"
import {PrivateRoute} from "../src/components"
import viewCourse from "./pages/courses/view/view-course";
import createCourse from "./pages/courses/create/create-course";
import updateCourse from "./pages/courses/update/update-course";

import viewChapter from "./pages/chapter/view/view-chapter";
import createChapter from "./pages/chapter/create/create-chapter";
import updateChapter from "./pages/chapter/update/update-chapter";

import viewLecture from "./pages/lecture/view/view-lecture";
import createLecture from "./pages/lecture/create/create-lecture";
import updateLecture from "./pages/lecture/update/update-lecture";

import viewLectureAsset from "./pages/lecture-asset/view/view-lecture-asset";
import createLectureAsset from "./pages/lecture-asset/create/create-lecture-asset";
import updateLectureAsset from "./pages/lecture-asset/update/update-lecture-asset";

import createUser from "./pages/user/create/create-user";
import viewUser from "./pages/user/view/view-user";
import updateUser from "./pages/user/update/update-user";

import createClass from "./pages/class/create-class/create-class";
import viewClass from "./pages/class/view-class/view-class";
import updateClass from "./pages/class/update-class/update-class";

import viewStudent from "./pages/student/view/view-student";
import Classes from "./pages/class/class";

import createTest from "./pages/test/create/create-test";
import viewTest from "./pages/test/view/view-test";
import updateTest from "./pages/test/update/update-test";

import viewQuestion from "./pages/question/view/view-question";
import createQuestion from "./pages/question/create/create-question";
import updateQuestion from "./pages/question/update/update-question";
import StartTest from "./pages/test/start/start-test";
import SubjectiveTest from "./pages/test/subjective/subjective-test";
import multipleChoice from "./pages/test/objective/mcq-test";
import PassResult from "./components/result/pass/pass-result";
import FailResult from "./components/result/fail/fail-result";
import Quiz from "./pages/test/quiz/quiz";
import CertificatePrint from "./components/certificate/certificate";
import CertificatePrint2 from "./components/certificate/certificatex";
import CourseCertificate from "./components/course-certificate/course-certificate";
import studentCertificate from "./pages/student-certificate/student-certificate";
// import QuizPassResult from "./components/result/quiz/quiz-pass-result";
import Profile from "./pages/profile/profile";
import userActivity from "./pages/user-activity/user-activity";
import StudentContract from "./pages/student-contract/student-contract";
import PrintStudentContract from "./pages/print-student-contract/print-student-contract";
import StudentTestActivity from "./pages/student-test-activity/student-test-activity";
import createCourseResource from "./pages/courses-resource/create/create-courses-resource";
import viewCourseResource from "./pages/courses-resource/view/view-courses-resource";
import updateCourseResource from "./pages/courses-resource/update/update-courses-resource";
import ThankyouPage from "./pages/payment/thankyou";
// import trueFalseTest from "./pages/test/truefalse/truefalse-test";


// import updateClass from "./pages/class/update-class/update-class";


const AppRouter = () => {
  return (

  
    <Router history={history}>
      <Switch>
    <Route  path="/index.html" render={props =>
                <Redirect to={{ pathname: '/' }} />
              }/>
    <Route exact path="/" component={Login} />
    <Route exact path="/login" component={Login} />
    {/* <Route exact path="/signup" component={Signup} /> */}
    <Route exact path="/updatepassword" component={UpdatePassword} />

    <Route exact path="/payment" component={Payment} />
    <Route exact path="/payment_status" component={PaymentStatus} />
    {/* <PrivateRoute exact path="/home/:id" component={Home} />
    course/:courseId/:classId/start?channel_name=kfjhgjhj21323k4j */}
    <PrivateRoute exact path="/course/:courseId/:classId/:teacherId/start" component={Home} />

    {/* Course */}
    <PrivateRoute exact path="/courses" component={Courses} />
    <PrivateRoute exact path="/courses/:id/classes" component={Classes} />
    <PrivateRoute exact path="/courses-resource/create/:id" component={createCourseResource} />
    <PrivateRoute exact path="/courses-resource/view/:id" component={viewCourseResource} />
    <PrivateRoute exact path="/courses-resource/update/:id" component={updateCourseResource} />
    <PrivateRoute exact path="/view-course" component={viewCourse} />
    <PrivateRoute exact path="/create-course" component={createCourse} />
    <PrivateRoute exact path="/update-course/:id" component={updateCourse} />

    {/* Course Chapter */}

    <PrivateRoute exact path="/view-chapter/:id" component={viewChapter} />
    <PrivateRoute exact path="/create-chapter/:courseId" component={createChapter} />
    <PrivateRoute exact path="/update-chapter/:courseId/:id" component={updateChapter} />

    {/* Lecture */}

    <PrivateRoute exact path="/view-lecture/:courseId/:chapterId" component={viewLecture} />
    <PrivateRoute exact path="/create-lecture/:chapterId" component={createLecture} />
    <PrivateRoute exact path="/update-lecture/:courseId/:chapterId/:id" component={updateLecture} />

    {/* Lecture Asset*/}

    <PrivateRoute exact path="/view-lecture-asset/:id" component={viewLectureAsset} />
    <PrivateRoute exact path="/create-lecture-asset/:id" component={createLectureAsset} />
    <PrivateRoute exact path="/update-lecture-asset/:lectureId/:id" component={updateLectureAsset} />

    {/* User */}

    <PrivateRoute exact path="/create-user" component={createUser} />
    <PrivateRoute exact path="/view-user" component={viewUser} />
    <PrivateRoute exact path="/update-user/:id" component={updateUser} />

    {/* Class */}

    <PrivateRoute exact path="/create-class" component={createClass} />
    <PrivateRoute exact path="/view-class" component={viewClass} />
    <PrivateRoute exact path="/update-class/:id" component={updateClass} />

    {/* Student */}

    <PrivateRoute exact path="/view-student/:id/:name" component={viewStudent} />

    {/* Test */}

    <PrivateRoute exact path="/create-test" component={createTest} />
    <PrivateRoute exact path="/view-test" component={viewTest} />
    <PrivateRoute exact path="/update-test/:id" component={updateTest} />
    <PrivateRoute exact path="/start-test" component={StartTest} />
    <PrivateRoute exact path="/subjective-test/:testId/:testResultId" component={SubjectiveTest} />
    <PrivateRoute exact path="/mcq-test" component={multipleChoice} />
    {/* <PrivateRoute exact path="/truefalse-test" component={trueFalseTest} /> */}


    {/* Question */}

    <PrivateRoute exact path="/create-question" component={createQuestion} />
    <PrivateRoute exact path="/view-question" component={viewQuestion} />
    <PrivateRoute exact path="/view-question/:testId/:courseId" component={viewQuestion} />
    <PrivateRoute exact path="/update-question/:id" component={updateQuestion} />


    <PrivateRoute exact path="/pass-result/:testId" component={PassResult} />
    <PrivateRoute exact path="/fail-result" component={FailResult} />

    {/* fix later */}
    {/* <PrivateRoute exact path="/quiz-pass-result" component={QuizPassResult} /> */}
    <PrivateRoute exact path="/quiz" component={Quiz} />
    <Route exact path="/certificatex" component={CertificatePrint} />
    <Route exact path="/certificate" component={CertificatePrint2} />
    <Route exact path="/user-activity/:type/:userId/:name" component={userActivity} />
    <PrivateRoute exact path="/course-certificate/:id/:name" component={CourseCertificate} />
    <PrivateRoute exact path="/student-certificate" component={studentCertificate} />
    <PrivateRoute exact path="/student-test-activity" component={StudentTestActivity} />

    <PrivateRoute exact path="/profile/:id" component={Profile} />
    <Route exact path="/contract/:id" component={StudentContract} />
    <Route exact path="/print-contract/:id" component={PrintStudentContract} />
    <Route exact path="/thankyou" component={ThankyouPage} />

    </Switch>

</Router>

    // <Router history={history}>
    //   <MainLayout>
    //     <Route path="/" exact render={routeprops => <Home text="Welcome home" {...routeprops} />} />
    //     <Route path="/about" render={routeprops => <About {...routeprops} />} />
    //     <Route path="/contact" render={routeprops => <Contact {...routeprops} />} />
    //     <Route path="/login" render={routeprops => <Login {...routeprops} />} />
    //     <Route path="/signup" render={routeprops => <Signup {...routeprops} />} />
    //     <Route path="/courses" render={routeprops => <Courses {...routeprops} />} />
    //   </MainLayout>
    // </Router>
  );
};

export default AppRouter;
