import { hot } from "react-hot-loader";
import React, { useEffect,useState } from "react";
import AppRouter from "./routes";
import { positions, Provider } from "react-alert";
import AlertTemplate from "./components/react-alert-template-basic/dist/cjs/react-alert-template-basic";
// import { Provider as AuthProvider } from '../src/context/AuthContext';
import * as Sentry from "@sentry/react";
import "./App.css";
import "./font-awesome.css"
const options = {
	timeout: 5000,
	position: positions.TOP_CENTER
};
const style = {
	backgroundColor: '#151515',
	color: 'black',
	padding: '10px',
	textTransform: 'uppercase',
	borderRadius: '3px',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
	fontFamily: 'Arial',
	width: '600px',
	boxSizing: '6border-box'
}
function ErrorFallback({ error, resetErrorBoundary }) {
	const [showDetail, setShowDetail] = useState(false)
	return (
	  <div role="alert" style={{ padding: "20px", textAlign: "center" }}>
		<h2>Something went wrong</h2>
		<p>{error.message}</p>
		{/* Display the full stack trace if available */}
		{
			showDetail && <pre style={{
				backgroundColor: "#f5f5f5",
				padding: "10px",
				textAlign: "left",
				maxHeight: "300px",
				overflowY: "scroll",
				fontSize: "12px",
			}}>
				{error.stack}
			</pre>
		}
		<button onClick={()=> {
			setShowDetail(!showDetail)
		}}>{showDetail ? 'Hide' : 'Show'} Details</button>
		<button onClick={() => window.location.href = "/"} style={{ marginLeft: "10px" }}>
		  Go to Homepage
		</button>
	  </div>
	);
  }

const App = () => {
	const [isCookieEnabled,setCookieEnabledStatus] = useState(false)
	useEffect(()=> {
		if(window.navigator.cookieEnabled){
			setCookieEnabledStatus(window.navigator.cookieEnabled)
		}
	},[])
	return (
		<Sentry.ErrorBoundary fallback={({ error, resetErrorBoundary }) => (
			<ErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />
		  )}>
			<Provider template={AlertTemplate}  {...style} {...options}>
				{/* <AppRouter /> */}
				<AppRouter />
				{!isCookieEnabled ? 
					<div className="cookiecard">
						<div className="cookieContainer">
							<div className="cookieContent">
								<p className="m-0">This website uses cookies in order to offer you the best user experience on our website. Please go to your browser's setting and "<strong>allow cookies</strong>."
               					 </p>
								{/* <a href="#">More Information</a> */}
							</div>
							{/* <button>Accept</button> */}
						</div>
					</div> : null}
			</Provider>
		</Sentry.ErrorBoundary>

		//  <AuthProvider>
		//  </AuthProvider> 
	)
	// return <AppRouter/>
};

export default App;
