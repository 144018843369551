import React, {useRef, useState , useEffect} from "react";
import "./update-class.css";
import Container from 'react-bootstrap/Container';
import Header from '../../../common/header/header'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import {CommonService} from '../../../service/common.service';
import { Link } from "react-router-dom";
import {history} from '../../../_helpers/history'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal'
import * as  moment from 'moment';
var jwt = require('jsonwebtoken');
const updateClass = ({ match }) => { 
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const formRef = useRef(null);
    const alert = useAlert();
    const [id, setId] = useState('');
    const [code, setCode] = useState('')
    const [startDate, setstartDate] = useState('');
    const [endDate, setendDate] = useState('');
    const [courseData, setCourseData] = useState([]);
    const [course, setCourseId] = useState('')
    const [teacherData, setTeacherData] = useState([]);
    const [teacher, setTeacherId] = useState('')
    const [assistantTeacher, setAssistantTeacherId] = useState('')
    const [startTime, setstartTime] = useState('');
    const [endTime, setendTime] = useState('');
    const [name, setName] = useState('');
    const [status, setStatus] = useState('');
    // const  courseParamId = match.params?.courseId;


    // const [classId , setClassId] = useState('')
    const  paramId = match.params?.id;

    const [dltShow, setdltShow] = useState(false);
    const delethandleClose = () => setdltShow(false);
    const deleteHandleShow = async () => {
        setdltShow(true);
    }
   
    useEffect(() => {
        
         
        // if(courseParamId != null) {
           
        // }
        
        if(paramId) {
            setLoading(true);
            setId(paramId)
            CommonService.httpGetService(`admin-api/class?id=${paramId}`)
            .then(classData => {
                if (classData[0] != undefined) {
    
                    setCourseId(classData[0].course_id);
                    setName(classData[0].name)
                    setStatus(classData[0].status)
                    let teacher= classData[0]?.teacher_id
                    setTeacherId(teacher)
                    setAssistantTeacherId(classData[0].assistant_teacher_id ? classData[0].assistant_teacher_id : '')
                    setstartTime(classData[0].start_time)
                    setendTime(classData[0].end_time)
                    setstartDate(moment(classData[0].start_date).format('YYYY-MM-DD'))
                    setendDate(moment(classData[0].end_date).format('YYYY-MM-DD'))
                    getCourses(classData[0].course_id);
                    getTeacher()

                    
                }

                setLoading(false);
				},error =>{
                    alert.error(error)    
                    setLoading(false);

			})
        }

    }, []);

    const getCourses = async (id) => {
        try {
            setLoading(true);
            CommonService.httpGetService(`admin-api/courses?id=${id}`)
            .then(data => {
                setCourseData(data)
                setLoading(false);
            }, error => {
                alert.error(error)
                setLoading(false);
            })
        }
        catch {
            alert.error("Please fill valid details")
        }
    }

    const getTeacher = async () => {
        try {
            setLoading(true);
            CommonService.httpGetService(`admin-api/teacher`)
            .then(res => {
                setTeacherData(res)
                setLoading(false)
    
            }, error => {
                alert.error(error)
                setLoading(false);
            })
        }
        catch {
           
        }
    }

    const handleSubmit = (course,status, name, teacher, startTime, endTime, startDate, endDate) => {
         
        setLoading(true);
        const form = document.getElementById("UpdateClassform");
        // const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
             
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
            handleUpdateButtonClick(course,status, name, teacher, startTime, endTime, startDate, endDate);
            setLoading(false);
        }
       
    };

    const handleReset = () => {
        // document.getElementById("UpdateCourseForm").reset();
        setName('')
        setstartTime('')
        setendTime('')
        setstartDate('')
        setendDate('')
        setValidated(false);
        setdltShow(false);
      };

    function handleUpdateButtonClick (course,status, name, teacher, startTime, endTime, startDate, endDate) {
        try {
            let obj = {
                "id": id,
                "course_id": course,
                "start_date": startDate,
                "teacher_id": teacher,
                "end_date": endDate,
                "name": name,
                "start_time": startTime,
                "end_time": endTime,
                "status": status,
                "assistant_teacher_id" : assistantTeacher
            }

            CommonService.httpPutService(`admin-api/class/${id}`, obj)
            .then(users => {
                setLoading(false);
                // let courseId = users[0].course_id
                // setCourseId(courseId);
                alert.success("Class has been updated successfully");
				},error =>{
                    setLoading(false);
                    alert.error(error)                    
			})

        } catch (error) {
            setLoading(false);
            alert.error("Please fill the required details!");
            // alert('Something went wrong with login')
        }
    }

    const copyPaymentUrl = () => {
        let obj = {
            course_id : course,
            class_id : paramId
        }
        let payment_key = `${jwt.sign(obj, process.env.PAYMENT_SECRET)}`
        const paymentUrl = `${CommonService.getBaseUrl()}/payment?payment_key=${payment_key}&payment_redirect_url=${CommonService.getBaseUrl()}`
        const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = paymentUrl;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
        document.body.removeChild(selBox);
        alert.success('Copied Payment URL')
    }

    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
            <Header></Header>           
            <Container fluid>
                <div className="heading">
                    <div className="lheading">
                        <span className="headTitle">Update Class </span>
                        <span className="searchItem">  </span>
                    </div>
                    <div className="rheading">
                    <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>
                    </div>
                </div>

                <Form ref={formRef} className="UpdateClassform" id="UpdateClassform" noValidate validated={validated}  >
                        
                <Form.Row>
                <Form.Group as={Col} controlId="formGridCode">
                            <Form.Label>Courses *</Form.Label>
                            <Form.Control as="select" defaultValue={course}  custom onChange={e => setCourseId(e.target.value)} required>
                                {courseData.map((course, index) => (
                                    <option key={index} value={course.id}>{course.name} ( {course.code} )</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select the course.
                        </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="descriptionRow">
                                <Form.Label >Name *</Form.Label>
                                <Form.Control type="text" value={name} onChange={e => setName(e.target.value)} required />
                                <Form.Control.Feedback type="invalid">
                                    Please fill the name of class.
                                </Form.Control.Feedback>
                            </Form.Group>
                        
                </Form.Row>
                      <Form.Row>
                     
                            <Form.Group as={Col} controlId="fromCourseStatus">
                            <Form.Label>Status *</Form.Label>
                            <Form.Control as="select" value={status}  custom onChange={e => setStatus(e.target.value)} required>
                                <option  value="Active">Active</option>
                                <option  value="Inactive">Inactive</option>
                                <option  value="Completed">Completed</option>
                               
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select the course.
                        </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridCode">
                            <Form.Label>Teacher *</Form.Label>

                            <Form.Control as="select" value={teacher} custom onChange={e => setTeacherId(e.target.value)} required>
                                {teacherData.map((teacher, index) => (
                                    <option key={index} value={teacher.id}>{teacher.first_name}</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select the teacher.
                        </Form.Control.Feedback>
                        </Form.Group>

                          <Form.Group as={Col} controlId="formGridCode">
                            <Form.Label>Assistant Teacher</Form.Label>
                            {/* <Form.Control type="text" /> */}

                            <Form.Control as="select" value={assistantTeacher} defaultValue={assistantTeacher} custom onChange={e => setAssistantTeacherId(e.target.value)} >
                            <option value="">Select Assistant Teacher</option>
                                {teacherData.map((teacher, index) => (
                                    <option key={index} value={teacher.id}>{teacher.first_name}</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select the teacher.
                        </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                        <Form.Row>
                            
                            <Form.Group as={Col}  className="descriptionRow">
                                <Form.Label >Start Time *</Form.Label>
                                <Form.Control type="time"  value={startTime}  onChange={e => setstartTime(e.target.value)}  required/>
                                <Form.Control.Feedback type="invalid">
                                    Please fill time start time of class.
                                </Form.Control.Feedback>
                            </Form.Group>

                              
                            <Form.Group as={Col}  className="descriptionRow">
                                <Form.Label >End Time *</Form.Label>
                                {/* <DatetimeRangePicker
                                dateFormat={false}
                                 /> */}
                                <Form.Control type="time" value={endTime} onChange={e => setendTime(e.target.value)}  required/>
                                <Form.Control.Feedback type="invalid">
                                    Please fill the end time of class.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridZipS" className="descriptionRow">
                                <Form.Label >Start Date *</Form.Label>
                                <Form.Control type="date" value={startDate} onChange={e => setstartDate(e.target.value)} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please fill the start date of class.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridZip" className="descriptionRow">
                                <Form.Label >End Date *</Form.Label>
                                <Form.Control type="date" value={endDate} onChange={e => setendDate(e.target.value)} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please fill the end date of class.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    

                    <div className="submitBtnRow">
                        <button type="button" onClick={() => handleSubmit(course,status, name, teacher, startTime, endTime, startDate, endDate)} className="saveBtn"> Submit </button>
                        <button type="button" onClick={() => { deleteHandleShow() }} className="resetBtn"> Reset </button>
                        <Link to={`/view-student/${id}/${name}?courseId=${course}`} className=""><button type="button" className="resetBtn">
                           Students   
                         </button></Link> 
                        <Link to={`/course-certificate/${id}/${name}`} className=""><button type="button" className="resetBtn">
                           Generate Certificate   
                         </button></Link>
                         <button type="button" onClick={() => { copyPaymentUrl() }} className="resetBtn"> Payment URL </button>
                    </div>
                </Form>

                <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to reset this form?
                    </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
                            </Button>
                            <Button variant="primary"  onClick={() => handleReset()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>

            </Container>
        </Container>
        </LoadingOverlay>
    );
};

export default updateClass;


