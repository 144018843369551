import React, { useState, useContext, useEffect } from "react";
import "./view-lecture.css";
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Header from '../../../common/header/header'
import Pagination from 'react-bootstrap/Pagination'
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {CommonService} from '../../../service/common.service';
import { useAlert } from "react-alert";
import {history} from '../../../_helpers/history'
import NotFoundIMg from '../../../assets/question-mark.png'
import LoadingOverlay from 'react-loading-overlay';
import tableDragger from '../../../assets/utils/table-dragger/dist/table-dragger';

const viewLecture = ({match}) => {

    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dltShow, setdltShow] = useState(false);
    const delethandleClose = () => setdltShow(false);

    const [data, setData] = useState([]);
    const [dltId, setdltId] = useState();
    const [selectedChapterId, setId] = useState('');

    const [filteredData, setFilteredData] = useState([]);

    const  paramId = match.params?.chapterId;
    const  courseParamId = match.params?.courseId;
    const [isOrderChanged, setIsOrderChanged] = useState(false);

    const getChapterLecture = async (chapterId) => {
        try {
        setLoading(true);
        CommonService.httpGetService('admin-api/chapter_lecture?chapter_id='+chapterId)
        .then(jsonResponse => {
            setData(jsonResponse);
            setFilteredData(jsonResponse);
            setLoading(false);
            setTimeout(() => {
                init_drag()
            }, 100);
            },error =>{
                alert.error(error)
                setLoading(false);
        })
        } catch (error) {
            alert.error("Something went wrong")
        }

    }

    const deleteHandleShow = async(datal) => {
        setdltShow(true);
        setdltId(datal);
    }


    const deleteChapterLecture = (id) => {
     
        CommonService.httpDeleteService(`admin-api/chapter_lecture/${dltId}`)
        .then(users => {
           
            setdltShow(false);
            alert.success("Lecture has been deleted successfully");
            getChapterLecture(selectedChapterId)
            },error =>{
                
                setdltShow(false);
                alert.error(error)
        })

    }

    const search = async(chapterId) => {
        try {
            setLoading(true);
            CommonService.httpGetService('admin-api/chapter_lecture?chapter_id='+chapterId)
            .then(jsonResponse => {
                setData(jsonResponse);
                setFilteredData(jsonResponse);
                setLoading(false);
                },error =>{
                    alert.error(error)
                    setLoading(false);
            })
            } catch (error) {
                alert.error("Something went wrong")
            }

	}

    const filterData = (value) => {
        let tempArr = Object.assign([], data)
        if (value.length >= 3) {
            let upperCaseFilter = value.toUpperCase()
            let filterDataArr = tempArr.filter(
                option => {
                    if (
                        (
                            option.title != undefined &&
                            option.title != null &&
                            (   
                                option.title.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        )
                    ) {
                        return option
                    }
                }
            )
            setFilteredData(filterDataArr)
        } else {
            setFilteredData(data)
        }
        setTimeout(() => {
            init_drag()
        }, 100);
    
}

    useEffect(() => {
       
        setLoading(false);
        if(paramId) {
            setId(paramId);
            getChapterLecture(paramId);
            // search(paramId)
        }
    }, []);

    const init_drag = () => {
        var el = document.getElementById('table');
        if(el){
            var dragger = tableDragger(el, {
                mode: 'row',
                dragHandler: '.handle',
                onlyBody: true,
                animation: 300
              });
              dragger.on('drop',(from, to)=>{
                setIsOrderChanged(true)
              });
        }
      }


    const updateOrder = () => {
        let table = document.getElementById('table');
        let tRows = table['tBodies'][0].rows;
        let reqArr = [];
        for (let x = 0; x < tRows.length; x++) {
            let obj = {
                id: tRows[x].cells[0].innerText,
                display_order_id: x,
            }
            reqArr.push(obj);
        }
        CommonService.httpPutService(`admin-api/chapter_lecture/update-order`,reqArr)
            .then(res => {
                alert.success('Ordering Updated.')
                setData([])
                setFilteredData([])
                getChapterLecture(paramId)
            }, error => {
                alert.error(`Failed to update ordering.`)
                setLoading(false);
            })
    }

    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
            <Header></Header>
        
            <Container fluid>
                <div className="heading">
                    <div className="lheading">
                        <span className="headTitle"> Lecture </span>
                        {/* <span className="searchItem"> 11 entries found </span> */}
                    </div>
                    <div className="rheading">
                        <Link to={`/create-lecture/${selectedChapterId}`}> <button type="button" className="addNew">+ Add New Lecture</button></Link>
                        <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>
                    
                    </div>
                </div>
                <div className="subheading">
                    <div className="lsubhead">
                    {
                        isOrderChanged ? <button type="button" onClick={e => updateOrder()}  className="addNew">Update Order</button> : ''
                    }
                    <input type="text" onChange={e => filterData(e.target.value)} className="tableSearch" placeholder="Search here..." />
                    </div>
                    {/*  */}
                </div>
                {
                            filteredData && filteredData.length > 0 ? (
                <Table responsive id="table">
                    <thead>
                        <tr className="tableHead">
                            <th>#</th>
                            {/* <th>Id</th> */}
                            <th>Title</th>
                            <th>Type</th>
                            <th>Published</th>
                            <th></th>

                        </tr>
                    </thead>
 
                    <tbody>

                    
                             {   filteredData.map((datal, index) => (

                                    <tr key={index} className="handle">
                                        <td hidden>{datal.id}</td>
                                        <td>{index+1}</td>
                                        {/* <td>{datal.id}</td> */}
                                        <td>{datal.title}</td>
                                        <td>{datal.type}</td>
                                        <td>{datal.is_published.toString()}</td>
                                        <td>
                                        <Link to={`/update-lecture/${courseParamId}/${paramId}/${datal.id}`} className=""><i className="fas fa-pencil-alt" ></i></Link> <i className="fas fa-trash-alt"  onClick={() => deleteHandleShow(datal?.id)}></i></td>
                                       
                                    </tr>
    
    
                                ))
                         
                        }
                         {/* , {"id" : id , "text": data} */}



                    </tbody>
                     </Table>
                       ) : (
                        <div className="nofoundRow">
                        <img className="nofoundimg" src={NotFoundIMg} />
                        <span className="nofoundimgCaption">Sorry! Lectures are not available </span>
                        
                        </div>
                    )
                       }
                       

<Modal  show={show}
                                    onHide={handleClose}
                                    backdrop="static"
                                    keyboard={false}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirmation</Modal.Title>
                                    </Modal.Header>
                                        <Modal.Body>
                                            Do you want to edit this course?
                                        </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                             No
                                        </Button>
                                        <Button variant="primary">Yes</Button>
                                    </Modal.Footer>
                            </Modal>

                            <Modal  show={dltShow}
                                    onHide={delethandleClose}
                                    backdrop="static"
                                    keyboard={false}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirmation</Modal.Title>
                                    </Modal.Header>
                                        <Modal.Body>
                                            Do you want to delete this lecture?
                                        </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={delethandleClose}>
                                             No
                                        </Button>
                                        <Button variant="primary"  onClick={() => deleteChapterLecture()}>Yes</Button>
                                    </Modal.Footer>
                            </Modal>
                    
               

            </Container>
        </Container>
        </LoadingOverlay>



    );
};

export default viewLecture;








