import React, { useRef, useState, useContext, useEffect } from "react";
// import "./create-course.css";
import Container from 'react-bootstrap/Container';
import Header from '../../../common/header/header'
// import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import { CommonService } from '../../../service/common.service';
import { history } from '../../../_helpers/history'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const createTest = () => {
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    const [duration, setduration] = useState('');
    const [marks, setmarks] = useState('');
    const [name, setName] = useState('');
    const [testInstruction, setTestInstruction] = useState('<p></p>');
    const [testCertificateDetail, setTestCertificateDetail] = useState('<p></p>');
    const [type, setType] = useState('TEST');
    const [no_of_re_attempt, setReAttempt] = useState('one');
    const [course, setCourseId] = useState('')
    const [passingPerc, setPassingPerc] = useState('')
    const [certificateRequired, setCertificateRequired] = useState(false)
    const [testId, setTestId] = useState(null)
    const alert = useAlert();
    const [courseData, setCourseData] = useState([]);
    const [dltShow, setdltShow] = useState(false);
    const delethandleClose = () => setdltShow(false);
    const deleteHandleShow = async () => {
        setdltShow(true);
    }

    const handleSubmit = () => {
        setLoading(true);
        const form = document.getElementById("CreateEntryForm");
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);

        }
        else {
            handleCreateClassClick();
            setLoading(false);
        }

    };

    const handleReset = () => {
        setName('')
        setmarks('')
        setduration('')
        setReAttempt('one')
        setType('TEST')
        setTestInstruction('')
        setPassingPerc('')
        setCertificateRequired(false)
        setValidated(false);
        setdltShow(false);

    };

    function handleCreateClassClick() {

        try {
            // let reqObj = {
            //     "course_id": course,
            //     "name": name,
            //     "teacher_id": teacher, 
            //     "start_time": duration,
            //     "end_time": marks,
            //     "start_date": startDate,
            //     "end_date": endDate,
            //     "instruction" : testInstruction

            // }
            let reqObj = {
                "name": name,
                "duration_in_min": duration,
                "marks": marks,
                "course_id": course,
                "allowed_attempts": no_of_re_attempt,
                "type": type,
                "tenant_id": 1,
                "instructions": testInstruction,
                "last_modified_by": 1,
                "passing_percentage": passingPerc,
                "certificate_required": certificateRequired,
                "test_certificate_details": testCertificateDetail
            }
            setLoading(true);
            CommonService.httpPostService(`admin-api/course-test`, reqObj)
                .then(response => {
                    let testId = response.data['id']
                    setTestId(testId)
                    // setcourse_id(course_id)
                    alert.success(`${type} has been created successfully`);
                    console.log(response)
                    setLoading(false);

                }, error => {
                    alert.error(error);
                    console.log(error);
                    setLoading(false);

                })

        } catch (error) {
             
            alert.error("Please fill all the required fields!");
            // alert('Something went wrong with login')
        }
    }

    useEffect(() => {
        setLoading(true);
        CommonService.httpGetService(`admin-api/courses`)
            .then(data => {
                setCourseData(data)
                if (data.length > 0) {
                    setCourseId(data[0].id)
                    setLoading(false);
                }
            }, error => {
                alert.error(error)
                setLoading(false);
            })
    }, []);




    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading...'
            className="loaderStyle"
        >
            <Container className="containerBox" fluid>
                <Header></Header>
                <Container fluid>
                    <div className="heading">
                        <div className="lheading">
                            <span className="headTitle">Create Test </span>
                            <span className="searchItem"> </span>
                        </div>
                        <div className="rheading">
                            <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>

                        </div>
                    </div>

                    <Form className="CreateClassForm" id="CreateEntryForm" noValidate validated={validated}  >

                        <Form.Group controlId="formGridCode">
                            <Form.Label>Course</Form.Label>
                            <Form.Control as="select" custom onChange={e => setCourseId(e.target.value)} >
                                {courseData.map((course, index) => (
                                    <option key={index} value={course.id}>{course.name} ( {course.code} )</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select the course.
                        </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Row>
                            <Form.Group as={Col} className="descriptionRow">
                                <Form.Label >Name *</Form.Label>
                                <Form.Control type="text" value={name} onChange={e => setName(e.target.value)} required />
                                <Form.Control.Feedback type="invalid">
                                    Please fill the name of test.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className="descriptionRow">
                                <Form.Label >Duration(In min) *</Form.Label>
                                <Form.Control type="number" value={duration} onChange={e => setduration(e.target.value)} required />
                                <Form.Control.Feedback type="invalid">
                                    Please fill duration of the test.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className="descriptionRow">
                            
                            <Form.Label >No of Reattempt </Form.Label>
                             <Form.Control className="durtype" as="select" type="text" defaultValue={no_of_re_attempt} onChange={e => setReAttempt(e.target.value)}  required >
                                <option value="one">One</option>
                                <option value="two">Two</option>
                                <option value="three">Three</option>
                                <option value="four">Four</option>
                                <option value="five">Five</option>
                                <option value="unlimited">Unlimited</option>
                                </Form.Control> 
                                <Form.Control.Feedback type="invalid">
                                Please fill the number of reattempt allowed.
                            </Form.Control.Feedback>
                        </Form.Group>
                           
                        </Form.Row>
                        
                        <Form.Row>
                        
                       
                        <Form.Group as={Col} className="descriptionRow">
                            <Form.Label >Type </Form.Label>
                            <Form.Control className="durtype" as="select" type="text" defaultValue={type} onChange={e => setType(e.target.value)}   required >
                                <option value="TEST">Test</option>
                                <option value="QUIZ">Quiz</option>
                                
                                </Form.Control> 
                            <Form.Control.Feedback type="invalid">
                                Please choose Type
                            </Form.Control.Feedback>
                        </Form.Group>
                       {
                           type == 'TEST' ? (
                            <Form.Group as={Col} className="descriptionRow">
                            <Form.Label >Passing Marks(%) *</Form.Label>
                            <Form.Control type="number" value={passingPerc} onChange={e => setPassingPerc(e.target.value)} required />
                            <Form.Control.Feedback type="invalid">
                                Please fill passing marks of the test.
                            </Form.Control.Feedback>
                        </Form.Group>
                           ) : (
                            <Form.Group as={Col} className="descriptionRow">
                            <Form.Label >Passing Marks(%) </Form.Label>
                            <Form.Control type="number" value={passingPerc} onChange={e => setPassingPerc(e.target.value)}  />
                           
                        </Form.Group>
                           )
                       }
                        <Form.Group as={Col} className="" controlId="formGridDuration">
                            <Form.Label>Certificate Required</Form.Label>
                            <Form.Check 
                                    type="switch"
                                    id="custom-switch"
                                    label=""
                                    checked={certificateRequired}
                                    onChange={() => setCertificateRequired(!certificateRequired)} 
                                    className="ml-3"
                                />
                         <Form.Control.Feedback type="invalid">
                                Please fill title of the chapter.
                        </Form.Control.Feedback>
                        </Form.Group>
                        </Form.Row>
                        {
                          certificateRequired == true ?  <Form.Row>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label >Ceritficate Description</Form.Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={testCertificateDetail}
                                    onChange={(event, editor) => {
                                        setTestCertificateDetail(editor.getData())
                                    }}
                                />
                            </Form.Group>
                        </Form.Row> : ''
                        }
                        <Form.Row>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label >Test Description</Form.Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={testInstruction}
                                    onChange={(event, editor) => {
                                        setTestInstruction(editor.getData())
                                    }}
                                />
                            </Form.Group>
                        </Form.Row>

                        <div className="submitBtnRow">
                            <button type="button" onClick={() => handleSubmit()} className="saveBtn"> Submit </button>
                            <button type="button" onClick={() => deleteHandleShow()} className="resetBtn"> Reset </button>

                            <Link to={`/view-question/${testId}/${course}`} disabled={testId == null ? true : false}>  <button type="button" className="resetBtn"> Questions </button></Link>
                        </div>
                    </Form>

                    <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to reset this form?
                    </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
                            </Button>
                            <Button variant="primary" onClick={() => handleReset()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>


                </Container>
            </Container>
        </LoadingOverlay>
    );
};

export default createTest;


