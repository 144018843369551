import React from "react";
import PageLayout from "../../layout/PageLayout";
// import ContactSVG from "../../assets/contactus.svg";

const Contact = props => {
  return (
    <PageLayout title={"Contact us"}>
      {/* <ContactSVG /> */}
    </PageLayout>
  );
};

export default Contact;
