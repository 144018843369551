import React, { useState, useContext, useEffect, useReducer } from "react";
// import "./../pages/Home.css";
import {useSelector, useDispatch} from 'react-redux'
import { CommonService } from './../service/common.service';
import { useAlert } from "react-alert";
import queryString from 'query-string';
var Centrifuge = require("centrifuge");
import Message from './Message';

const MessageComponent = ({classId ,groupMessage,channelName,typeOfChat,handleNewMessage,centrifugeToken}) => {
    
    const [typing, setTyping] = useState({})
    const [messageText, setMessageText] = useState('')
    const [token, setToken] = useState('');
    const [flag, setFlag] = useState(true);
    const [isSubscribed, setisSubscribed] = useState(false);
    const [state, dispatch] = useReducer(reducer);
    const [userEmail, setUSerEmail] = useState(null);
	const queryParams = queryString.parse(location.search)
    const alert = useAlert();

    const messageArr = useSelector(state => state.messageArr);
    
    const dispatchMessage = useDispatch();


    useEffect(() => {
        const isLoggedIn = localStorage.getItem('user') ? true : false;
       
        if (isLoggedIn && flag == true) {
            
            console.log('Message Dispatched')
            var data = JSON.parse(localStorage.getItem('user'))
            setUSerEmail(data['email']);
            setToken(data.centri_token ? data.centri_token : '')
            dispatchMessage({type: "MESSAGE_ARRAY", payload: groupMessage && groupMessage.length ? groupMessage : [], channel : channelName})

            let presenceChannel = {
                "method": "presence",
                "params": {
                    "channel": channelName
                }
            }
    
            
            CommonService.publishToCentrifuge(presenceChannel,centrifugeToken).then(res => {
                let flag = false
                for (const key in res.result.presence) {
                    if (res.result.presence.hasOwnProperty(key)) {
                        const client = res.result.presence[key];
                        console.log(`User present ${JSON.parse(client.user).Name } in class ${channelName} : `)
                        if (JSON.parse(client.user).Id == data.id) {
                            
                            let reqObj = { 
                                "method": "unsubscribe",
                                "params": { 
                                    "channel": channelName, 
                                    "user": client.user  
                                } 
                            }
                            CommonService.publishToCentrifuge(reqObj,centrifugeToken).then(res => {}, error => {})
                        }
                    }
                }
                if (!isSubscribed) {
                    subscribeCentrifuge(channelName, data.role, data.centri_token, groupMessage)
                    setisSubscribed(true)
                }
            }, error => {
                 
            })
            setTimeout(() => {
                scrollToBottom()
            }, 100);
            setFlag(false)
        }
	},[]);


    const scrollToBottom = () => {
        const chatWindow = document.getElementById('messageBody');
        // var xH = chatWindow.scrollHeight; 
        // chatWindow.scrollTo(0, xH);
        if (chatWindow) {
            chatWindow.scrollTop = chatWindow.scrollHeight
        }
    }

    const subscribeCentrifuge = (channelName, userRole, token,previousMessage) => {
        const centrifuge = new Centrifuge(CommonService.getCentrifugeUrl('ws'));
        centrifuge.setToken(token);
        centrifuge.on('connect', function (ctx) {
            console.log(`${channelName} centriguge connected` , ctx);
        });

        centrifuge.on('disconnect', function (ctx) {
            console.log("disconnected", ctx);
        });



      
        centrifuge.subscribe(channelName, function (ctx) {
            if (ctx.data.isTyping == undefined) {
                // if(typeOfChat == ctx.data.typeOfChat) {
                let obj = {
                    "message": ctx.data.message,
                    "user": ctx.data.user,
                    "name": ctx.data.name
                }
                dispatchMessage({type: "MESSAGE", payload: obj,channel : channelName})
                // }
            }else{
                let typingObj = {
                    "user": ctx.data.user,
                    "name": ctx.data.name
                }
                dispatch({ type: 'typing', payload: typingObj })
                if(localStorage.getItem('user')) {

                    var userData = JSON.parse(localStorage.getItem('user'))
                    if (userData.name != ctx.data.name) {
                        setTyping(typingObj)
                        setTimeout(() => {
                            setTyping({})
                        }, 1000);
                    }
                }
            }
        })
       
        centrifuge.connect();
    }

    const postToCentrifuge = () => {
        if(messageText != '') {
            var data = JSON.parse(localStorage.getItem('user'))
            let postObj = {
                "method": "publish",
                "params": {
                    "channel": channelName,
                    "data": {
                        message: messageText,
                        user: token,
                        name: data.name,
                        typeOfChat: typeOfChat
                    }
                }
            }
            // dispatchMessage({type: "MESSAGE", payload: postObj.params.data})
            updateNewMessage(postObj.params.data,data.id,typeOfChat)

            CommonService.publishToCentrifuge(postObj,centrifugeToken).then(res => {
                console.log(res)
                setMessageText('')
                scrollToBottom()
            }, error => {
    
            })

            try {
                let sendMessageObj = {
                    "class_id": classId,
                    "channel_name": channelName,
                    "data":{
                        message: messageText,
                        user: token,
                        name: data.name,
                        typeOfChat: typeOfChat
                    }
                }
    
                CommonService.httpPostService(`admin-api/class-chat`,sendMessageObj).then(res => {
                    
                }, error => {
        
                })
            } catch (error) {
                
            }
            
        }
    }

    const postTypingToCentrifuge = () => {
        
        var data = JSON.parse(localStorage.getItem('user'))
        let postObj = {
            "method": "publish",
            "params": {
                "channel": channelName,
                "data": {
                    isTyping: true,
                    user: token,
                    name: data.name
                }
            }
        }
        CommonService.publishToCentrifuge(postObj,centrifugeToken).then(res => {

        }, error => {

        })
    }

    const updateNewMessage = (message,userId,typeOfChat) =>{
        handleNewMessage(message,userId,typeOfChat)
    }


    return (
        <div className="chatSection">
           
            <div id="messageBody"  className="chatView">
                {

                    messageArr && messageArr[channelName] && messageArr[channelName].length > 0 && messageArr[channelName].map((data, i) => {
                        return ( <Message key={i} data={data} token={token} />)
                       
                    })
                }
                
            </div>
            {typing.name != undefined ? <div style={{height:"20px", backgroundColor:"#f5f7f9"}}>{typing.name} is typing... <span style={{float:"right"}} title="Click to scroll down" onClick={()=>{
                let objDiv = document.getElementById('messageBody')
                objDiv.scrollTop = objDiv.scrollHeight;
            }}><i className="fas fa-arrow-circle-down"></i></span></div> : <div style={{height:"20px", backgroundColor:"#f5f7f9"}}><span style={{float:"right"}} title="Click to scroll down" onClick={()=>{
                let objDiv = document.getElementById('messageBody')
                objDiv.scrollTop = objDiv.scrollHeight;
            }}><i className="fas fa-arrow-circle-down"></i></span></div>}
            
            <input type="search" id="textInputBox" value={messageText} autoComplete="off"
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        postToCentrifuge()
                    }
                    // else{
                    //     postTypingToCentrifuge()
                    // }
                }}
                onChange={e => setMessageText(e.target.value)} className="chatInput" placeholder="Enter your message here" />
            <div className="chatAction" >
                {/* <span className="sendBtn"><i className="fas fa-paperclip"></i></span>
                <span className="sendBtn"><i className="far fa-smile"></i></span> */}
                <span className="sendBtn" onClick={() => postToCentrifuge()}><i className="fas fa-paper-plane"></i></span>
            </div>
        </div>
    );
};

function reducer(state, action) {
    switch (action.type) {
        case 'typing':
            return { ...state , typing: action.payload };
        default:
            throw new Error();
    }
}

export default MessageComponent;
