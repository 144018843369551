import React, {useState,useEffect} from "react";
import MessageComponent from "./MessageComponent"
import Student from "./Student"
import { CommonService } from './../service/common.service';
import { useAlert } from "react-alert";
import Dropdown from 'react-bootstrap/Dropdown'
import PropTypes from 'prop-types';
// const OngoinClassStudentsComponent = ({classId, studentData,channelName}) => {
const OngoinClassStudentsComponent = ({
    classId,studentData,channelName, centrifugeToken, handleReadMark
}) => {
	const [classList, setClassList] = useState(false);
	// const [classId, setclassId] = useState('');
	// const [channelName, setchannelName] = useState('');
	const [activeChatStudent, setActiveChatStudent] = useState({});
    const [groupMessageArr, setGroupMessageArr] = useState([]);
    const [showMessageComponent, setShowMessageComponent] = useState(false);
    const alert = useAlert();
    useEffect( () => {
        // console.log('Student Data props : ',props)
        // if(studentData){
        //     setclassId(classId)
        //     setchannelName(channelName)
        // }
    }, []);
    const getGroupMessages = (student) => {
		try {
            setShowMessageComponent(false)
            CommonService.httpGetService(`admin-api/class-chat?channel=${channelName}_${student.id}`)
                .then(data => {
					setGroupMessageArr(data)
					setShowMessageComponent(true)
                }, error => {
                    alert.error(error)
                })
        }
        catch {
            // alert.error("Please fill valid details")
        }

    }
    const hanldeMessage = (message,userId,typeOfChat) => {
         
        let postObj = {
			"method": "publish",
			"params": {
				"channel": `${channelName}_notification_${typeOfChat}`,
				"data": {
					message : message,
					userId : activeChatStudent.id
				}
			}
		}
		CommonService.publishToCentrifuge(postObj,centrifugeToken).then(res => {
			console.log(res)
		}, error => {

		})
    }
    const muteStudent = (student) => {
        console.log('Method Not Implemented')
    }
    const removeStudent = (student) => {
        console.log('Method Not Implemented')
    }

    return (
        <div className="participantList">
        {studentData && studentData.map((student, studentIndex) => (
            <div className="listItem" style={{padding : '0.375rem'}} key={studentIndex} onClick={() => {
                handleReadMark(student.id,studentData)
            }}>
                <Student 
                    key={studentIndex}
                    student={student} 
                    setActiveChatStudent={setActiveChatStudent} 
                    setClassList={setClassList} 
                    getGroupMessages={getGroupMessages}
                    removeStudent={removeStudent}
                    muteStudent={muteStudent}
                    />
            </div>
        ))}

{
            classList == true ? (
                 <div className="float-chat teacher-chat-box">
                     <div className="chatHeader">
							<span>{activeChatStudent.full_name}</span>
                            <span onClick={() => setClassList(false)}>Minimize</span>
                            <span onClick={() => setClassList(false)}>Close</span>
				</div>
                {
                  showMessageComponent ?  <MessageComponent classId={classId}  groupMessage={groupMessageArr} channelName={`${channelName}_${activeChatStudent.id}`} typeOfChat='individual' handleNewMessage={hanldeMessage} centrifugeToken={centrifugeToken}/> : ''
                }
                </div>
            ) : ''
        }


    </div>
    );
}

// OngoinClassStudentsComponent.propTypes = {
// 	studentData: PropTypes.any
// };

// OngoinClassStudentsComponent.defaultProps = {
// 	studentData: []
// };



export default OngoinClassStudentsComponent;
