import React, { useState, useEffect } from "react";
import "./update-question.css";
import Container from 'react-bootstrap/Container';
import Header from '../../../common/header/header'
// import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import { CommonService } from '../../../service/common.service';
import { history } from '../../../_helpers/history'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const updateQuestion = ({ match }) => {
    const [loading, setLoading] = useState(true);
    const [validated, setValidated] = useState(false);

    const alert = useAlert();
    const [courseData, setCourseData] = useState([]);
    const [dltShow, setdltShow] = useState(false);
        // form Variables
        const [course, setCourseId] = useState('')
        const [question, setQuestion] = useState('<p></p>');
        const [questionType, setQuestionType] = useState('objective');
        const [marks, setMarks] = useState('');
        const [choice_1, setchoice_1] = useState('');
        const [choice_2, setchoice_2] = useState('');
        const [choice_3, setchoice_3] = useState('');
        const [choice_4, setchoice_4] = useState('');
        const [answer, setAnswer] = useState('');
    const  questionId = match.params?.id;

    const delethandleClose = () => setdltShow(false);
    const deleteHandleShow = async () => {
        setdltShow(true);
    }

    const handleSubmit = () => {
        setLoading(true);
        const form = document.getElementById("CreateEntryForm");
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);

        }
        else {
            handleCreateClassClick();
            setLoading(false);
        }

    };


    const handleReset = () => {
        // setCourseId('')
        setQuestion('<p></p>')
        setQuestionType('objective')
        setMarks('')
        setchoice_1('')
        setchoice_2('')
        setchoice_3('')
        setchoice_4('')
        setAnswer('')
        setValidated(false);
        setdltShow(false);

    };

    function handleCreateClassClick() {

        try {
            let no_of_choices = 0
            let choices = []
            if (questionType == 'objective') {
                no_of_choices = 4
                choices.push(choice_1)
                choices.push(choice_2)
                choices.push(choice_3)
                choices.push(choice_4)
            }

            if (questionType == 'conditional') {
                no_of_choices = 2
                choices.push("true")
                choices.push("false")
            }

            let reqObj = {
                "question": question,
                "question_type": questionType,
                "no_of_choices": no_of_choices,
                "choices": choices,
                "answer": answer,
                "marks": marks,
                "course_id": course,
                "tenant_id": 1,
                "last_modified_by": 1
            }
            console.log(reqObj)
            setLoading(true);
            CommonService.httpPutService(`admin-api/course-question/${questionId}`, reqObj)
                .then(users => {
                    alert.success("Question has been updated successfully");
                    console.log(users)
                    setLoading(false);

                }, error => {
                    alert.error(error);
                    console.log(error);
                    setLoading(false);

                })

        } catch (error) {
            console.log('Error while updating questions : ',error)
            alert.error("Please fill all the required fields!");
            // alert('Something went wrong with login')
        }
    }

    const getQuestion = () => {
        try {
            setLoading(true);
            CommonService.httpGetService(`admin-api/course-question?id=${questionId}`)
                .then(res => {
                     
                    var data = res[0]
                    setCourseId(data.course_id)
                    setQuestion(data.question)
                    setQuestionType(data.question_type)
                    setMarks(data.marks)
                    setchoice_1(data.choices[0])
                    setchoice_2(data.choices[1])
                    setchoice_3(data.choices[2])
                    setchoice_4(data.choices[3])
                    setAnswer(data.answer)
                    // setName(data.name)
                    // setmarks(Number(data.marks))
                    // setduration(Number(data.duration_in_min))
                    // setTestInstruction(data.instructions)
                    setLoading(false)
                }, error => {
                    alert.error(error)
                    setLoading(false);
                })
        }
        catch {
            alert.error("Failed to get question Data")
        }
    }

    const getCourses = async (id) => {
        try {
            CommonService.httpGetService(`admin-api/courses`)
                .then(data => {
                    setCourseData(data)
                }, error => {
                    alert.error(error)
                })
        }
        catch {
            // alert.error("Please fill valid details")
        }
    }

    useEffect(() => {
        if(questionId){
            getQuestion()
        }
        getCourses()
        
        setLoading(true);
        
    }, []);


    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading...'
            className="loaderStyle"
        >
            <Container className="containerBox" fluid>
                <Header></Header>
                <Container fluid>
                    <div className="heading">
                        <div className="lheading">
                            <span className="headTitle">Update Question </span>
                            <span className="searchItem"> </span>
                        </div>
                        <div className="rheading">
                            <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>

                        </div>
                    </div>

                    <Form className="CreateClassForm" id="CreateEntryForm" noValidate validated={validated}  >

                        <Form.Group controlId="formGridCode">
                            <Form.Label>Course</Form.Label>
                            <Form.Control as="select" value={course} custom onChange={e => setCourseId(e.target.value)} >
                            <option  value="">Select Course</option>
                                {courseData.map((course, index) => (
                                    <option key={index} value={course.id}>{course.name} ( {course.code} )</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select the course.
                        </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group  className="descriptionRow">
                                <Form.Label >Total Marks</Form.Label>
                                <Form.Control type="number" value={marks} onChange={e => setMarks(e.target.value)} required />
                                <Form.Control.Feedback type="invalid">
                                    Please fill the marks.
                                </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="descriptionRow">
                            <Form.Label >Type</Form.Label>
                            <ButtonGroup aria-label="Basic example">
                                
                                <Button variant="secondary"
                                    className={`radioBtn ${questionType == 'objective' ? 'active' : ''}`}
                                    onClick={() => setQuestionType('objective')}>
                                    Multiple Choice
                                    </Button>
                                <Button variant="secondary"
                                    className={`radioBtn ${questionType == 'conditional' ? 'active' : ''}`}
                                    onClick={() => setQuestionType('conditional')}>
                                    True/False
                                    </Button>
                                    <Button variant="secondary"
                                    className={`radioBtn ${questionType == 'subjective' ? 'active' : ''}`}
                                    onClick={() => setQuestionType('subjective')}>
                                    Free Text
                                    </Button>
                            </ButtonGroup>
                            {/* <Form.Control as="select" type="text" value={name} onChange={e => setName(e.target.value)} required >
                                <option>Subjective</option>
                                <option>Objective</option>
                                </Form.Control> */}

                            <Form.Control.Feedback type="invalid">
                                Please fill the name of test.
                                </Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Row> */}
                        <Form.Group className="descriptionRow">
                            <Form.Label >Question</Form.Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={question}
                                onChange={(event, editor) => {
                                    setQuestion(editor.getData())
                                }}
                            />
                        </Form.Group>

                        {/* Question Type Multiple Choice */}
                        {
                            questionType == 'objective' ? (
                                <div>
                                    <Form.Group>
                                        <Form.Label >Answer</Form.Label>

                                        <Form.Check
                                            name="answer"
                                            label="This answer is correct"
                                            id="choice_1"
                                            type="radio"
                                            checked = {answer == choice_1 ? true : false}
                                            onChange={e => setAnswer(choice_1)}
                                            feedbacktooltip="true"
                                        />

                                        <Form.Control type="text" value={choice_1} onChange={e => setchoice_1(e.target.value)} />

                                    </Form.Group>

                                    <Form.Group>

                                        <Form.Check
                                            name="answer"
                                            label="This answer is correct"
                                            id="choice_2"
                                            type="radio"
                                            checked = {answer == choice_2 ? true : false}
                                            onChange={e => setAnswer(choice_2)}
                                            feedbacktooltip="true"
                                        />
                                        <Form.Control type="text" value={choice_2} onChange={e => setchoice_2(e.target.value)} />

                                    </Form.Group>

                                    <Form.Group>

                                        <Form.Check
                                            name="answer"
                                            label="This answer is correct"
                                            id="choice_3"
                                            type="radio"
                                            checked = {answer == choice_3 ? true : false}
                                            onChange={e => setAnswer(choice_3)}
                                            feedbacktooltip="true"
                                        />
                                        <Form.Control type="text" value={choice_3} onChange={e => setchoice_3(e.target.value)} />

                                    </Form.Group>

                                    <Form.Group>

                                        <Form.Check
                                            name="answer"
                                            label="This answer is correct"
                                            id="choice_4"
                                            type="radio"
                                            checked = {answer == choice_4 ? true : false}
                                            onChange={e => setAnswer(choice_4)}
                                            feedbacktooltip="true"
                                        />
                                        <Form.Control type="text" value={choice_4} onChange={e => setchoice_4(e.target.value)} />

                                    </Form.Group>
                                </div>
                            ) : ''
                        }


                        {/* Question Type True/False */}

                        {
                            questionType == 'conditional' ? (
                                <div>
                                    <Form.Group>
                                        <Form.Label >Answer</Form.Label>

                                        <Form.Check
                                            name="answer"
                                            label="True"
                                            id="trueRadio"
                                            type="radio"
                                            checked = {answer == "true" ? true : false}
                                            onChange={e => setAnswer("true")}
                                            feedbacktooltip="true"
                                        />

                                    </Form.Group>

                                    <Form.Group>

                                        <Form.Check
                                            name="answer"
                                            label="False"
                                            id="falseRadio"
                                            type="radio"
                                            checked = {answer == "false" ? true : false}
                                            onChange={e => setAnswer("false")}
                                            feedbacktooltip="true"
                                        />

                                    </Form.Group>
                                </div>
                            ) : ''
                        }

                        <div className="submitBtnRow">
                            <button type="button" onClick={() => handleSubmit()} className="saveBtn"> Submit </button>
                            <button type="button" onClick={() => deleteHandleShow()} className="resetBtn"> Reset </button>

                            {/* <button type="button" className="resetBtn"> <Link to="/view-question"> Questions</Link> </button> */}
                        </div>
                    </Form>
                    <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to reset this form?
                    </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
                            </Button>
                            <Button variant="primary" onClick={() => handleReset()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>


                </Container>
            </Container>
        </LoadingOverlay>
    );
};

export default updateQuestion;


