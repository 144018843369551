import React, { useRef, useState, useContext, useEffect } from "react";
// import "./create-course.css";
import Container from 'react-bootstrap/Container';
import Header from '../../../common/header/header'
// import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import { CommonService } from '../../../service/common.service';
import { history } from '../../../_helpers/history'
import { useForm } from "react-hook-form";
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal';
// import DatetimeRangePicker from 'react-datetime-range-picker';
// import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';

const createClass = ({ match }) => {
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [value, onChange] = useState(new Date());

    const [startDate, setstartDate] = useState('');
    const [endDate, setendDate] = useState('');
    const [startTime, setstartTime] = useState('');
    const [endTime, setendTime] = useState('');
    const [name, setName] = useState('');

    const [classId , setClassId] = useState('')
    const [id, setId] = useState('');

    const alert = useAlert();
    const [courseData, setCourseData] = useState([]);
    const [teacherData, setTeacherData] = useState([]);
    const [teacher, setTeacherId] = useState('')
    const [assistantTeacher, setAssistantTeacherId] = useState('')
    const [course, setChapterId] = useState('')
    const [dltShow, setdltShow] = useState(false);
    // const  paramId = match.params?.id;
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('user')))
    const delethandleClose = () => setdltShow(false);
    const deleteHandleShow = async () => {
        setdltShow(true);
    }

    const handleSubmit = () => {
         
        setLoading(true);
        const form = document.getElementById("CreateEntryForm");
        if (form.checkValidity() === false) {
            setValidated(true);
             
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);

        }
        else {
            handleCreateClassClick();
            setLoading(false);
        }

    };

    const handleReset = () => {
        setName('')
        setstartTime('')
        setendTime('')
        setstartDate('')
        setendDate('')
        setValidated(false);
        setdltShow(false);
    };

    function handleCreateClassClick() {

        try {
            let obj = {
                "course_id": course,
                "name": name,
                "teacher_id": teacher, 
                "start_time": startTime,
                "end_time": endTime,
                "start_date": startDate,
                "end_date": endDate,
                "assistant_teacher_id": assistantTeacher,
            }
            setLoading(true);
            CommonService.httpPostService(`admin-api/class`, obj)
                .then(users => {
                    let classId = users.data[0].id
                    setClassId(classId)
                    alert.success("Class has been created successfully");
                    console.log(users)
                    setLoading(false);
                }, error => {
                    alert.error(error);
                    console.log(error);
                    setLoading(false);

                })

        } catch (error) {
            alert.error("Please fill all the required fields!");
        }
    }

    useEffect(() => {
        setLoading(true);
        getTeacher();
        CommonService.httpGetService(`admin-api/courses`)
            .then(data => {
                setCourseData(data)
                if (data.length > 0) {
                    setChapterId(data[0].id)
                    setLoading(false);
                }
            }, error => {
                alert.error(error)
                setLoading(false);
            })
    }, []);

    const getTeacher = () => {
        setLoading(true);
        CommonService.httpGetService(`admin-api/teacher`)
        .then(res => {
            setTeacherData(res)
            if(res.length > 0) {
                setTeacherId(res[0].id)
                setLoading(false)
            }
        }, error => {
            alert.error(error)
            setLoading(false);
        })
    }



    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading...'
            className="loaderStyle"
        >
            <Container className="containerBox" fluid>
                <Header></Header>
                <Container fluid>
                    <div className="heading">
                        <div className="lheading">
                            <span className="headTitle">Create Class </span>
                            <span className="searchItem"> </span>
                        </div>
                        <div className="rheading">
                            <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>

                        </div>
                    </div>

                    <Form className="CreateClassForm" id="CreateEntryForm" noValidate validated={validated}  >
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridCode">
                            <Form.Label>Courses *</Form.Label>
                            <Form.Control as="select" custom onChange={e => setChapterId(e.target.value)} required>
                                {courseData.map((course, index) => (
                                    <option key={index} value={course.id}>{course.name} ( {course.code} )</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select the course.
                        </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} className="descriptionRow">
                                <Form.Label >Name *</Form.Label>
                                <Form.Control type="text" value={name} onChange={e => setName(e.target.value)} required />
                                <Form.Control.Feedback type="invalid">
                                    Please fill the name of class.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                        <Form.Group as={Col} controlId="formGridCode">
                            <Form.Label>Teacher *</Form.Label>
                            {/* <Form.Control type="text" /> */}

                            <Form.Control as="select" value={teacher} defaultValue={teacher} custom onChange={e => setTeacherId(e.target.value)} required>
                                {teacherData.map((teacher, index) => (
                                    <option key={index} value={teacher.id}>{teacher.first_name}</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select the teacher.
                        </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridCode">
                            <Form.Label>Assistant Teacher</Form.Label>
                            {/* <Form.Control type="text" /> */}

                            <Form.Control as="select" value={assistantTeacher} defaultValue={assistantTeacher} custom onChange={e => setAssistantTeacherId(e.target.value)} >
                            <option value="">Select Assistant Teacher</option>
                                {teacherData.map((teacher, index) => (
                                    <option key={index} value={teacher.id}>{teacher.first_name}</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select the teacher.
                        </Form.Control.Feedback>
                        </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            
                            <Form.Group as={Col}  className="descriptionRow">
                                <Form.Label >Start Time *</Form.Label>
                                <Form.Control type="time"  value={startTime} onChange={e => setstartTime(e.target.value)}  required/>
                                <Form.Control.Feedback type="invalid">
                                    Please fill time start time of class.
                                </Form.Control.Feedback>
                            </Form.Group>

                              
                            <Form.Group as={Col}  className="descriptionRow">
                                <Form.Label >End Time *</Form.Label>
                                {/* <DatetimeRangePicker
                                dateFormat={false}
                                 /> */}
                                <Form.Control type="time" value={endTime} onChange={e => setendTime(e.target.value)}  required/>
                                <Form.Control.Feedback type="invalid">
                                    Please fill the end time of class.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}  className="descriptionRow">
                                <Form.Label >Start Date *</Form.Label>
                                <Form.Control type="date" value={startDate} onChange={e => setstartDate(e.target.value)} required />
                                <Form.Control.Feedback type="invalid">
                                    Please fill the start date of class.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridZip" className="descriptionRow">
                                <Form.Label >End Date *</Form.Label>
                                <Form.Control type="date"  value={endDate} onChange={e => setendDate(e.target.value)} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please fill the end date of class.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <div className="submitBtnRow">
                            <button type="button" onClick={() => handleSubmit()} className="saveBtn"> Submit </button>
                            <button type="button" onClick={() => deleteHandleShow()} className="resetBtn"> Reset </button>
                           
                            <Link to={`/view-student/${classId}/${name}?courseId=${course}`} className=""  disabled={classId == "" ? true : false}> <button type="button" className="resetBtn">  Students </button></Link>
                        </div>
                    </Form>

                    <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to reset this form?
                    </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
                            </Button>
                            <Button variant="primary" onClick={() => handleReset()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>


                </Container>
            </Container>
        </LoadingOverlay>
    );
};

export default createClass;


