import React, { useState, useContext, useEffect } from "react";
import "./create-chapter.css";
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Header from '../../../common/header/header'
// import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import {CommonService} from '../../../service/common.service';
import {history} from '../../../_helpers/history'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal'

const createChapter = ({match}) => {
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const alert = useAlert();
    const [courseData, setCourseData] = useState([]);
    const [title, setTitle] = useState('');
    const [course, setChapterId] = useState('')
    const [isPublished, setIsPublished] = useState('Y')
    const [lectureId , setLectureId] = useState(null);

    const [courseStateId , setCourseStateId] = useState(null)
    const  courseParamId = match.params?.courseId;

    const [dltShow, setdltShow] = useState(false);
    const [isSwitchOn, setIsSwitchOn] = useState(true);
    const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
     
    if (!isSwitchOn){
        setIsPublished('N')
     }
    else {
        setIsPublished('Y')
    }
    };
    const delethandleClose = () => setdltShow(false);
    const deleteHandleShow = async () => {
        setdltShow(true);
    }

    useEffect(() => {
        setLoading(true);
        if(courseParamId != null) {
            CommonService.httpGetService(`admin-api/courses?id=${courseParamId}`)
                .then(data => {
                    setCourseData(data)
                    if (data.length > 0) {
                        setChapterId(data[0].id)
                        setLoading(false);
                    }
                },error =>{
                    alert.error(error)
                    setLoading(false);
                })
        }
    }, []);

    const handleSubmit = () => {
        setLoading(true);
        const form = document.getElementById("CreateChapterForm");
        // const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
            handleCreateChapterBtn();
            
        }
       
    };

    const handleReset = () => {
        setTitle('');
        setIsPublished("Y");
        setValidated(false);
        setdltShow(false);

    };

    function handleCreateChapterBtn ()  {
        try {
            let obj = {
                "title": title,
                "is_published": isPublished,
                "course_id": course,
                "last_modified_by": "ADMIN",
                "tenant_id": "TENANT"
            }

            CommonService.httpPostService(`admin-api/course_chapter`, obj)
            .then(res => {
                let lectId = res.data[0].id;
                setLectureId(lectId)
                setLoading(false);
                alert.success(`Chapter Created successfully.`);
				},error =>{
                    setLoading(false);
					alert.error(error)
			})
           

        } catch (error) {
             
            setLoading(false);
            alert.error("Please fill the required details!");
            // alert('Something went wrong with login')
        }
    }



    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
            <Header></Header>           
            <Container fluid>
                <div className="heading">
                    <div className="lheading">
                        <span className="headTitle">Create Chapter </span>
                        {/* <span className="searchItem"> Code : A10922 </span> */}
                    </div>
                    <div className="rheading">
                    <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>
                    </div>
                </div>

                <Form className="CreateEntryForm" id="CreateChapterForm" noValidate validated={validated} >
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridCode">
                            <Form.Label>Course</Form.Label>
                            <Form.Control as="select" custom onChange={e => setChapterId(e.target.value)} required>
                                {courseData.map((course, index) => (
                                    <option key={index} value={course.id}>{course.name} ( {course.code} )</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please fill name of the course.
                        </Form.Control.Feedback>
                               </Form.Group>
                        <Form.Group as={Col} controlId="formGridName">
                            <Form.Label>Chapter Title *</Form.Label>
                            <Form.Control type="text" value={title} onChange={e => setTitle(e.target.value)} placeholder="Chapter Title"  pattern="[a-zA-Z0-9\s]+" required/>
                            <Form.Control.Feedback type="invalid">
                                Please fill title of the chapter.
                        </Form.Control.Feedback>
                        </Form.Group>

                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} className="" controlId="formGridDuration">
                            <Form.Label>Published</Form.Label>
                            <Form.Check 
                                    type="switch"
                                    id="custom-switch"
                                    label=""
                                    // defaultValue={isPublished}
                                    checked={isSwitchOn}
                                    onChange={onSwitchAction}
                                    
                                />
                         <Form.Control.Feedback type="invalid">
                                Please fill title of the chapter.
                        </Form.Control.Feedback>
                            {/* <Form.Control as="select" defaultValue={isPublished} custom onChange={e => setIsPublished(e.target.value)} >
                                <option value="Y" >Yes</option>
                                <option value="N">No</option>
                            </Form.Control>  */}
                        </Form.Group>
                    </Form.Row>

                    <div className="submitBtnRow">
                        <button type="button" onClick={() => handleSubmit()} className="saveBtn"> Submit </button>
                        <button type="button"  onClick={() =>  deleteHandleShow()} className="resetBtn"> Reset </button>
                        <Link to={`/view-lecture/${course}/${lectureId}`} className="" disabled={lectureId == null? true: false}><button type="button" className="resetBtn"> Lectures </button></Link>

                    </div>
                </Form>

                <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to reset this form?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
                            </Button>
                            <Button variant="primary"  onClick={() => handleReset()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>

            </Container>
        </Container>
      </LoadingOverlay>
    );
};

export default createChapter;


