import React, { useRef, useState, useContext, useEffect } from "react";
import "./create-courses-resource.css";
import Container from 'react-bootstrap/Container';
import Header from '../../../common/header/header'
// import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import { useAlert } from "react-alert";
import { CommonService } from '../../../service/common.service';
import { history } from '../../../_helpers/history'
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal'

const createCourseResource = ({ match }) => {
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const alert = useAlert();
    const courseId = match.params?.id;
    const baseUrl = CommonService.getBaseUrl()
    const [courseData, setCourseData] = useState('');
    const [name , setName] = useState('')
    const [fileData, setFileData] = useState('')
    const [dltShow, setdltShow] = useState(false);
    const delethandleClose = () => setdltShow(false);
    const deleteHandleShow = async () => {
        setdltShow(true);
    }
    useEffect(() => {
        setLoading(true);
        getCourseData()
    }, []);

    const getCourseData = () => {
        CommonService.httpGetService(`admin-api/courses?id=${courseId}`)
        .then(data => {
             
            if(data.length > 0) {
                setCourseData(data[0])
                setLoading(false);
            }
        },error =>{
            alert.error(error)
            setLoading(false);
        })
    }

    const selectFile = event => {
        setFileData(event.target.files[0])
      }
 

    const handleSubmit = () => {
         
        setLoading(true);
        const form = document.getElementById("createCourseResource");
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);

        }
        else {
            saveCourseResource();
        }

    };

    const handleReset = () => {
        setName('')
        setFileData('')

    };

    function saveCourseResource() {
        setLoading(true);
        try {
            const data = new FormData()
            data.append('course_resource', fileData)
            data.append('name', name)
            data.append('course_name', courseData.name)
            data.append('course_id', courseId)
            axios.post(`${baseUrl}/admin-api/course-resource`, data, {
                headers : {
                    'Content-Type' : 'multipart/form-data'
                }
             }).then(res => { // then print response status
                 setLoading(false);
                 alert.success('Resource Saved.')
             }, error => {
                 setLoading(false);
                 alert.error(error)
             })

        } catch (error) {
            console.error(error)
            setLoading(false);
            // alert.error("Please fill all the required fields!");
        }
    }





    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading...'
            className="loaderStyle"
        >
            <Container className="containerBox" fluid>
                <Header></Header>
                <Container fluid>
                    <div className="heading">
                        <div className="lheading">
                            <span className="headTitle">Add Resource </span>
                            <span className="searchItem"> {courseData && courseData.name ? courseData.name : ''} </span>
                        </div>
                        <div className="rheading">
                            <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>

                        </div>
                    </div>

                    <Form className="CreateEntryForm" id="createCourseResource" noValidate validated={validated} >
                    <Form.Row>
                        <Form.Group as={Col} controlId="formTitleId">
                            <Form.Label>Title *</Form.Label>
                            <Form.Control value={name} onChange={e => setName(e.target.value)} placeholder="Resource Title" required />
                            <Form.Control.Feedback type="invalid">
                                Please fill the title.
                        </Form.Control.Feedback>
                        </Form.Group>
                       
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} className="col-6" controlId="formFileUpload">
                            <Form.Label>Upload File *</Form.Label>
                            <Form.File  onChange={selectFile} id="custom-file" label="Custom file input" custom required/>
                            <Form.Control.Feedback type="invalid">
                                Please upload the content.
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <div className="submitBtnRow">
                        <button type="button" onClick={(e) => handleSubmit()} className="saveBtn"> Submit </button>
                        <button type="button" onClick={() =>  deleteHandleShow()} className="resetBtn"> Reset </button>
                    </div>
                </Form>

                <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to reset this form?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
                            </Button>
                            <Button variant="primary"  onClick={() => handleReset()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>

                </Container>
            </Container>
        </LoadingOverlay>
    );
};

export default createCourseResource;


