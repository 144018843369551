import React, { useState, useContext, useEffect } from "react";
// import "./view-course.css";
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Header from '../../../common/header/header'
import Pagination from 'react-bootstrap/Pagination'
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { CommonService } from '../../../service/common.service';
import { useAlert } from "react-alert";
import NotFoundIMg from '../../../assets/question-mark.png'
import LoadingOverlay from 'react-loading-overlay';
// import 'moment-timezone';
import moment from 'moment';

const viewClass = ({match}) => {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dltShow, setdltShow] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [dltId, setdltId] = useState();
    const [searchData, setSearchData] = useState();
    const [courseData, setCourseData] = useState([]);

    // const  paramName = match.params?.name;

    const delethandleClose = () => setdltShow(false);

    const deleteHandleShow = async (datal) => {
        setdltShow(true);
        setdltId(datal);
    }



    const getClass = async () => {
        setLoading(true);
        CommonService.httpGetService(`admin-api/class`)
        .then(data => {
            // let courseid = data['id']
            setData(data);
            setFilteredData(data);
            setLoading(false);
        }, error => {
            alert.error(error)
            setLoading(false);
        })
    }

    const filterData = (value) => {
            let tempArr = Object.assign([], data)
            if (value.length >= 3) {
                let upperCaseFilter = value.toUpperCase()
                let filterDataArr = tempArr.filter(
                    option => {
                        if (
                            (
                                option.name != undefined &&
                                option.name != null &&
                                (   
                                    option.name.toUpperCase().toString().includes(upperCaseFilter)
                                )
                            )
                        ) {
                            return option
                        }
                    }
                )
                setFilteredData(filterDataArr)
            } else {
                setFilteredData(data)
            }
        
    }

    const getCourses = async () => {
        try {
            setLoading(true);
            CommonService.httpGetService(`admin-api/courses`)
            .then(data => {
                setCourseData(data)
                setLoading(false);
            }, error => {
                alert.error(error)
                setLoading(false);
            })
        }
        catch {
            alert.error("Please check all the valid details")
        }
    }

    const deleteCourses = (id) => {
        setLoading(true);
        CommonService.httpDeleteService(`admin-api/class/${dltId}`)
            .then(users => {
                setdltShow(false);
                alert.success("Class has been deleted successfully");
                getClass()
                setLoading(false);
            }, error => {
                setdltShow(false);
                alert.error(error)
                setLoading(false);
            })

    }

    useEffect(() => {
        // search(paramName)
        setLoading(false);
        getClass();
        getCourses();
        
    }, []);




    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
            <Header></Header>

            <Container fluid>
                <div className="heading">
                    <div className="lheading">
                        <span className="headTitle"> Classes </span>
                        <span className="searchItem"> </span>
                    </div>
                    <div className="rheading">
                        <Link to="/create-class"> <button type="button" className="addNew">+ Add New Class</button></Link>
                    </div>
                </div>
                <div className="subheading">
                    <div className="lsubhead">
                    <input type="text" onChange={e => filterData(e.target.value)} className="tableSearch" placeholder="Search here..." />
                    </div>

                </div>



                {

                    filteredData && filteredData.length > 0 ? (

                        <Table responsive>
                            <thead>
                                <tr className="tableHead">
                                    <th>#</th>
                                    {/* <th>Id</th> */}
                                    <th>Name</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredData.map((datal, index) => (

                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{datal.name}</td>
                                            {/* {
                                                courseData.map((course, index) => (
                                                <td key={index} value={course.id}>{course.name}</td>
                                                ))
                                            } */}
                                            {/* {moment().format('dddd, MMMM Do YYYY')} */}
                                            <td>  {moment(datal.start_date).format('dddd, MMMM Do YYYY')}  </td>
                                            <td>{moment(datal.end_date).format('dddd, MMMM Do YYYY')} </td>
                                            <td>
                                                <Link to={`/update-class/${datal.id}`} className=""><i className="fas fa-pencil-alt" ></i></Link>
                                                <i className="fas fa-trash-alt" onClick={() => {
                                                    deleteHandleShow(datal.id)
                                                }} ></i></td>
                                        </tr>
                                    ))

                                }



                            </tbody>
                        </Table>
                    ) : (
                            <div className="nofoundRow">
                                <img className="nofoundimg" src={NotFoundIMg} />
                                <span className="nofoundimgCaption">Sorry! Classes are not available </span>
                            </div>
                        )

                }
                <Modal show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to edit this class?
       </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
       </Button>
                        <Button variant="primary">Yes</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={dltShow}
                    onHide={delethandleClose}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to delete this class?
       </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={delethandleClose}>
                            No
       </Button>
                        <Button variant="primary" onClick={() => deleteCourses(dltId)}> Yes</Button>
                    </Modal.Footer>
                </Modal>


            </Container>
        </Container>
        </LoadingOverlay>



    );
};

export default viewClass;








