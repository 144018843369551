import React, { useRef, useEffect,useState } from 'react';
import PropTypes from 'prop-types';

import * as pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import LoadingOverlay from 'react-loading-overlay';

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// const PdfComponent = ( props) => {
const PdfComponent = React.memo(function PdfComponent(props) {


    const [loading, setLoading] = useState(false);
	const canvasRef = useRef(null)
	const prevCountRef = useRef(null);
	const prevLoadingTask = useRef(null);
	const componentRef = useRef()
	var renderTask = useRef(null)
	const [totalPage, setTotalPage] = useState(0)
	const [pageIndex, setPageIndex]  = useState(1)

	

	useEffect( () => {
		console.log('Props : ',props)
		try {
			if(props.src.url.includes('.pdf')) {

				if(props.page_number < 1) {
					// props.page_number = 1;
					setPageIndex(1)
				}
				else{
					setPageIndex(props.page_number)
				}
				setLoading(true);
		
				let timer = null
				if(props.role == 'STUDENT') {
					 
					if(prevCountRef.current != null) {
						if(prevCountRef.current.src.url != props.src.url &&  props.page_number == 1) {
							setPageIndex(1)
							timer = setTimeout(() => {
								fetchPdf();
								setLoading(false);
							}, 2000);
							
							
						}
						else if(prevCountRef.current.src.url == props.src.url && prevCountRef.current.page_number != props.page_number) {
							timer = setTimeout(() => {
								fetchPdf();
								setLoading(false);
							}, 2000);
						}
						else{
							setLoading(false);
							// It's blank for stop multiple rendering issue 
						}
					}
					else{
						timer = setTimeout(() => {
							fetchPdf();
							setLoading(false);
						}, 2000);
					}
					
				}
				else{
					timer = setTimeout(() => {
						fetchPdf();
						setLoading(false);
					}, 2000);
				}
				return () => clearTimeout(timer);
			}
		} catch (error) {
			setLoading(false);
			console.log(error)
		}
		
	}, [props]);

	const fetchPdf = async () => {

		try {
			
			let loadingTask = null;
			let pdf  = null;
			if(prevLoadingTask.current == null) {
				
				loadingTask = pdfjs.getDocument(props.src);
				pdf = await loadingTask.promise;
				let numPage = pdf._pdfInfo.numPages;
				setTotalPage(numPage)
				props.onChangePageNum(numPage)
	
				let obj = {
					loadingTask : loadingTask,
					pdf : pdf
				}
				prevLoadingTask.current = obj;
				await renderPdf(pdf)
			}
			else if(prevCountRef.current != null) {
				if(prevCountRef.current.src.url != props.src.url && props.page_number == 1) {
					
					loadingTask = pdfjs.getDocument(props.src);
					pdf = await loadingTask.promise;
					let numPage = pdf._pdfInfo.numPages;
					setTotalPage(numPage)
					props.onChangePageNum(numPage)
					setPageIndex(1)
					let obj = {
						loadingTask : loadingTask,
						pdf : pdf
					}
					prevLoadingTask.current = obj;
					await renderPdf(pdf)
				}
				else{
					
					loadingTask = prevLoadingTask.current.loadingTask;
					pdf = await  prevLoadingTask.current.pdf;
					let numPage = pdf._pdfInfo.numPages;
					if(numPage > props.page_number) {
						// setPageIndex(props.page_number)
						await renderPdf(pdf)
					}
					else{
						// props.page_number = props.page_number-1;
						setPageIndex(props.page_number - 1)
					}
				}
			}
			else{
				
				loadingTask = prevLoadingTask.current.loadingTask;
				pdf = await prevLoadingTask.current.pdf;
				let numPage = pdf._pdfInfo.numPages;
				if(numPage > props.page_number) {
					// setPageIndex(props.page_number)
					renderPdf(pdf)
				}
				else{
					// props.page_number = props.page_number-1;
					setPageIndex(props.page_number -1)
	
				}
			}
		} catch (error) {
			console.log('Error while fetching PDF : ',error )
			setLoading(false);
		}

		
		
	};

	const renderPdf = async (pdf) =>{

		try {
			
			var firstPageNumber = props.page_number;
			if (firstPageNumber > pdf._pdfInfo.numPages) {
				setPageIndex(1)
				firstPageNumber = 1
			}
			const page = await pdf.getPage(firstPageNumber);
			if(renderTask.current != null && !renderTask.current._internalRenderTask.cancelled) {
				renderTask.current._internalRenderTask.cancel();
				renderTask.current = null
				// return false;
			}
			let obj = {
				src :props.src,
				page_number: pageIndex ? pageIndex : 1
			}
			prevCountRef.current = obj;
	
			const scale = 1.2;
			// Prepare canvas using PDF page dimensions
			const canvas = canvasRef.current;
			// var viewport = page.getViewport({ scale: scale });
			var pdf_original_width = page.getViewport({"scale": 1.0}).width;
			var scale_required = (componentRef.current['scrollWidth'] -5) / pdf_original_width;
			var viewport = page.getViewport({scale: scale_required});
			const context = canvas.getContext('2d');
			canvas.height = viewport.height;
			canvas.width = viewport.width;
	
			if (context) {
				context.clearRect(0, 0, canvas.width, canvas.height);
				context.beginPath();
			}
	
			// Render PDF page into canvas context
			const renderContext = {
				canvasContext: context,
				viewport: viewport
			};
	
			
			
			renderTask.current = page.render(renderContext);
			try {
			
				await renderTask.current.promise.then(data=>{
					console.log('data : ', data)
					setLoading(false);
				}, error=>{
					 
					console.log(error)
					setLoading(false);
					if(renderTask.current != null && renderTask.current._internalRenderTask) {
	
						renderTask.current._internalRenderTask.cancel();
						renderTask.current = null;
						renderPdf(pdf)
					}
	
					
				});
			} catch (error) {
				 
				console.log(error)
				setLoading(false);
				if(renderTask.current != null && renderTask.current._internalRenderTask) {
					renderTask.current._internalRenderTask.cancel();
					renderTask.current = null;
					renderPdf(pdf)
				}
				// console.log(error);
				
			}
			
		} catch (error) {
			setLoading(false);
		}

	} 

	return (
		

			<div ref={componentRef}	
				style={{ height: '100%', width:'100%', overflow: 'auto' , position:'relative'}}
			>
				<LoadingOverlay
					active={loading}
					spinner
					text='Loading...'
					className="loaderStyle"
				>

					<canvas
						ref={canvasRef}
						width="100%"
						height="100%"
						style={{ 'transform': `scale(${props.scale})` }}
					/>
				</LoadingOverlay>
				
			</div>
		
	);
})

PdfComponent.propTypes = {
	src: PropTypes.any,
	page_number: PropTypes.number
};

PdfComponent.defaultProps = {
	src: `${process.env.PUBLIC_URL}/employee-guidelines-24-May-2020.pdf`
};

export default  PdfComponent;
