import React, { useState , useEffect} from "react";
import "./update-lecture.css";
import Container from 'react-bootstrap/Container';
import Header from '../../../common/header/header'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import {CommonService} from '../../../service/common.service';
import { Link } from "react-router-dom";
import {history} from '../../../_helpers/history'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal'


const updateLecture = ({match}) => {
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const alert = useAlert();
    const [id, setId] = useState('');
    const [cheptorData, setChapterData] = useState([]);
    const [testData, setTestData] = useState([]);
    const [title, setTitle] = useState('');
    const [type, setType] = useState('ASSET');
    const [test, setTestId] = useState('')
    const [chapter, setCourseId] = useState('')
    const [isPublished, setIsPublished] = useState(true)
    const[stateCourseId , setStateCourseId] = useState('')
    const[stateChapterId , setStateChapterId] = useState('')
    const  paramId = match.params?.id;
    const  chapterParamId = match.params?.chapterId;
    const  courseParamId = match.params?.courseId;

    const [showTestDiv , setshowTestDiv] = useState(false);

    const [dltShow, setdltShow] = useState(false);
    const delethandleClose = () => setdltShow(false);
    const deleteHandleShow = async () => {
        setdltShow(true);
    }

    const getChapter = async (id) => {
        setLoading(true);
        CommonService.httpGetService(`admin-api/course_chapter?id=${id}`)
            .then(jsonResponse => {
                setChapterData(jsonResponse);
                setLoading(false);
            }, error => {
                setdltShow(false);
                alert.error(error)
                setLoading(false);
            })
    }

    const getTest = async (id) => {
        setLoading(true);
        CommonService.httpGetService(`admin-api/course-test?course_id=${id}`)
        .then(data => {
            setTestData(data);
            setTestId(data[0].id)
            setLoading(false);
        }, error => {
            alert.error(error)
            setLoading(false);
        })
    }

    useEffect(() => {
        if(courseParamId != null) {
            setStateCourseId(courseParamId)
            getTest(courseParamId)
        }
        if(chapterParamId != null) {
            getChapter(chapterParamId);
            setStateChapterId(chapterParamId)
        }
        setLoading(true);
        if(paramId) {
            setId(paramId);
            CommonService.httpGetService(`admin-api/chapter_lecture?id=${paramId}`)
            .then(data => {
                if(data.length > 0) {
                    let chapterTitle = data[0].title
                    let courseId = data[0].course_id
                    let chapterIsPublished = data[0].is_published
                    let chaptertype = data[0].type
                    let testId = data[0].course_test_id


                    setTitle(chapterTitle);
                    setIsPublished(chapterIsPublished);
                    setType(chaptertype);
                    setCourseId(courseId);
                    setTestId(testId);

                    setLoading(false);
                }
            },error =>{
                alert.error(error)
                setLoading(false);
			})
        }
    }, []);

    const handleSubmit = (chapter, title, type, test, isPublished) => {
        setLoading(true);
        const form = document.getElementById("UpdateLectureForm");
        // const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
             
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
            handleUpdateButtonClick(chapter, title, type, test, isPublished);
        }
       
    };

    const onClickTest = (type) => 
    {
        if(type == 'TEST' || type == 'QUIZ'){
            setshowTestDiv(true);
            if (testData.length > 0) {
               var filterArr =  testData.filter(option => {
                    return  option.type == type
                  })
                  if (filterArr.length > 0) {
                      setTestId(filterArr[0].id)
                  }
            }
        }
        else{
            setshowTestDiv(false);
            setTestId('');
        }

    }  

    const handleReset = () => {
        setTitle('');
        setIsPublished('Y');
        setType('ASSET');
        setValidated(false);
        setdltShow(false);

    };

    const handleUpdateButtonClick = (chapter, title, type, test, isPublished ) => {
        
        try {
            let obj = {
                "title": title,
                "is_published": isPublished,
                "type":type,
                "course_test_id": test,
                "chapter_id": stateChapterId,
                "last_modified_by": "ADMIN",
                "tenant_id": "TENANT"
            }

            CommonService.httpPutService(`admin-api/chapter_lecture/${id}`, obj)
            .then(res => {
                setLoading(false);
                alert.success(`Lecture updated successfully.`);
				},error =>{
                    setLoading(false);
					alert.error(error)
			})
           

        } catch (error) {
            setLoading(false);
            alert.error("Please fill the required details!");
        }
    }

   


    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
            <Header></Header>           
            <Container fluid>
                <div className="heading">
                    <div className="lheading">
                        <span className="headTitle">Update Lecture </span>
                        {/* <span className="searchItem"> Code : XYZ </span> */}
                    </div>
                    <div className="rheading">
                    <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>
                    </div>
                </div>

                <Form className="CreateEntryForm"  id="UpdateLectureForm" noValidate validated={validated} >
                <Form.Row>
                        <Form.Group as={Col} controlId="formGridCode">
                            <Form.Label>Chapter *</Form.Label>
                            <Form.Control as="select" defaultValue={chapter}  custom onChange={e => setChapterId(e.target.value)} required>
                                {cheptorData.map((chapter, index) => (
                                    <option key={index} value={chapter.id}>{chapter.title}</option>
                                ))}
                            </Form.Control>
                               </Form.Group>
                        <Form.Group as={Col} controlId="formGridName">
                            <Form.Label>Title *</Form.Label>
                            <Form.Control type="text" value={title} onChange={e => setTitle(e.target.value)} placeholder="Lecture Title"  pattern="[a-zA-Z0-9\s]+" required/>
                            <Form.Control.Feedback type="invalid">
                                Please fill title of the course.
                        </Form.Control.Feedback>
                        </Form.Group>

                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}  controlId="formGridCode">
                            <Form.Label>Type *</Form.Label>
                            <Form.Control value={type} as="select"  onClick={() => onClickTest(type)}  custom onChange={e => setType(e.target.value)}  required>
                                <option  value="ASSET">ASSET</option>
                                <option  value="TEST">TEST</option>
                                <option  value="QUIZ">QUIZ</option>
                                {/* <option  value="VIDEO">VIDEO</option> */}
                            </Form.Control>
                        </Form.Group>

                        {
                          type == "TEST" || type == "QUIZ" ?                           
                            (
                                <Form.Group as={Col}  controlId="selectTest">
                                <Form.Label>{type.trim().toLowerCase().replace(/^\w/, (c) => c.toUpperCase())} *</Form.Label>
                                    <Form.Control as="select" value={test}  custom onChange={e => setTestId(e.target.value)} required>
                                    <option  value="">Select</option>
                                        {testData.map((test, index) => test.type == type ? (
                                            <option key={index} value={test.id}>{test.name}</option>
                                        ) : '')}
                                    </Form.Control>
                                       </Form.Group>
                            ) : (
                                null
                            )
                        }
                       
                        {/* {
                          type == "TEST" ?                           
                            (
                                <Form.Group as={Col}  controlId="formGridCode">
                                <Form.Label>Test</Form.Label>
                                    <Form.Control as="select" defaultValue={test}  custom onChange={e => setTestId(e.target.value)} required>
                                        {testData.map((test, index) => (
                                            <option key={index} value={test.id}>{test.name}</option>
                                        ))}
                                    </Form.Control>
                                       </Form.Group>
                            ) : (
                                null
                            )
                        } */}

                        <Form.Group as={Col}  className="" controlId="formGridDuration">
                            <Form.Label>Published</Form.Label>
                            {/* <Form.Check 
                                    type="switch"
                                    id="custom-switch"
                                    label=""
                                    defaultValue={isPublished}
                                    onChange={e => setIsPublished(e.target.value)}
                                /> */}
                            <Form.Control as="select" defaultValue={isPublished} custom onChange={e => setIsPublished(e.target.value)}  >
                                <option value="Y" >Yes</option>
                                <option value="N">No</option>
                            </Form.Control> 
                        </Form.Group>
                    </Form.Row>

                    <div className="submitBtnRow">
                        <button type="button" onClick={() => {handleSubmit(chapter, title, type, test, isPublished )}} className="saveBtn"> Submit </button>
                        <button type="button" onClick={() =>  deleteHandleShow()} className="resetBtn"> Reset </button>
                        {
                          type == "TEST" || type == "QUIZ" ?                           
                            (
                               <Link to={`/update-test/${test}`} className="" disabled={id == null? true: false}> <button type="button" className="resetBtn"> Tests </button></Link>

                            ) : (
                                 <Link to={`/view-lecture-asset/${id}`} className="" disabled={id == null? true: false}><button type="button" className="resetBtn">Assets </button></Link>
                            )
                        }
                        {/* <button type="button" className="resetBtn">
                            <Link to={`/view-lecture-asset/${id}`} className="">Assets</Link>    
                         </button> */}
                    </div>
                </Form>
                <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to reset this form?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
                            </Button>
                            <Button variant="primary"  onClick={() => handleReset()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>
            </Container>
        </Container>
        </LoadingOverlay>
    );
};

export default updateLecture;