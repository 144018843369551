import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { Provider } from 'react-redux'
import { createStore } from 'redux';
import rootReducer from './_reducers/rootReducers';
const store = createStore(rootReducer)
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing"
Sentry.init({
    dsn: process.env.sentry_url,
    integrations: [new Integrations.BrowserTracing()],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    environment: process.env.name,
  });
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider> , 
    document.querySelector("#root")
);

window.onerror = (message, source, lineno, colno, error) => {
    console.log("UNCAUGHT ERROR:", message, error);
};

// Global handler for unhandled promise rejections
window.onunhandledrejection = (event) => {
    console.log('UNHANDLED ERRORS', event)
};