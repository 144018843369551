import React, { useEffect } from "react";

const Message =  (props) => {

    useEffect(()=>{
        // console.log('Message : ',props);
    },[])

    return ( 
        <>

            {
                props && props.data && (
                    <>
                        {

                        props.data.user == props.token ? 
                                (
                                    <div className="" >
                                        <div className="ci"><div className="jHldnd"></div></div>
                                        <span className="sentMsg">{props.data.message}</span>
                                    </div>
                                )  : (
                                    <div className="receivedMsg" >
                                        {/* <div className="s-ci"><div className="s-jHldnd"></div></div> */}
                                        <span className="sender-name">{props.data.name}</span><br />
                                        <span>{props.data.message}</span>
                                        {/* {props.data.message}  */}
                                    </div>
                                )
                            
                            }
                        </>
                )
            }
        </>
    );
};



export default Message;
