import React, { useState, useContext, useEffect } from "react";
import '../../student/view/view-student.css';
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import Header from '../../../common/header/header'
import Pagination from 'react-bootstrap/Pagination'
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { CommonService } from '../../../service/common.service';
import { useAlert } from "react-alert";
import NotFoundIMg from '../../../assets/question-mark.png'
import LoadingOverlay from 'react-loading-overlay';
import { history } from '../../../_helpers/history'
import * as moment from 'moment';
import Form from 'react-bootstrap/Form'

const viewQuestion = ({ match }) => {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [dltShow, setdltShow] = useState(false);
    const [data, setData] = useState([]);
    const [dltId, setdltId] = useState();
    const [filterInputValue, setFilterInputValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [questionUrl, setQuestionUrl] = useState('');
    const [totalScore, setTotalScore] = useState('');

    const [courseData, setCourseData] = useState([]);
    const [course, setCourseId] = useState(null)


    const [isEdit, setIsEdit] = useState(true);
    const testId = match.params?.testId;
    const courseId = match.params?.courseId;

    const delethandleClose = () => setdltShow(false);

    const deleteHandleShow = async (datal) => {
        setdltShow(true);
        setdltId(datal);
    }


    const filterData = (value) => {
        let tempArr = Object.assign([], data)
        setFilterInputValue(value)
        if (value.length >= 3) {
            let upperCaseFilter = value.toUpperCase()
            let filterDataArr = tempArr.filter(
                option => {
                    if (
                        (
                            option.question != undefined &&
                            option.question != null &&
                            (
                                option.question.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        ) 
                    ) {
                        return option
                    }
                }
            )
            setFilteredData(filterDataArr)
        } else {
            setFilteredData(data)
        }

    }


    const deleteQuestion = (id) => {
        setLoading(true);
         
        CommonService.httpDeleteService(`admin-api/course-question/${dltId}`)
            .then(users => {
                setdltShow(false);
                alert.success("Question has been deleted successfully");
                getQuestions(questionUrl)
                setLoading(false);
            }, error => {
                setdltShow(false);
                alert.error(error)
                setLoading(false);
            })
    }

    const addTestQuestion = (datal) => {

        try {
            setLoading(true);
            let obj = {
                "course_test_id": testId,
                "course_question_id": datal.id,
                "tenant_id": 1,
                "last_modified_by": 1
            }
            CommonService.httpPostService(`admin-api/course-test/add-question`, obj)
                .then(res => {
                    setdltShow(false);
                    alert.success("Question Added successfully");
                    getQuestions(questionUrl)
                    setLoading(false);
                }, error => {
                    setdltShow(false);
                    alert.error(error)
                    setLoading(false);
                })
        } catch (error) {
            setLoading(false);
        }
    }
    const removeTestQuestion = (datal) => {
        setLoading(true);
            let obj = {
                "course_test_id": testId,
                "course_question_id": datal.id,
                "tenant_id": 1,
                "last_modified_by": 1
            }
        CommonService.httpDeleteBodyService(`admin-api/course-test/remove-question`,obj)
            .then(res => {
                // setData(res)
                setdltShow(false);
                alert.success("Question removed successfully");
                getQuestions(questionUrl)
                setLoading(false);
            }, error => {
                alert.error(error)
                setLoading(false);
            })
    }

    const filterApiData = (value, filterArr) => {
        let tempArr = Object.assign([], filterArr)
         
        setFilterInputValue(value)
        if (value.length >= 3) {
            let upperCaseFilter = value.toUpperCase()
            let filterDataArr = tempArr.filter(
                option => {
                    if (
                        (
                            option.question != undefined &&
                            option.question != null &&
                            (
                                option.question.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        ) 
                    ) {
                        return option
                    }
                }
            )
            setFilteredData(filterDataArr)
        } else {
            setFilteredData(filterArr)
        }

    }

    const getCourses = () => {
        setLoading(true);
        CommonService.httpGetService(`admin-api/courses`)
            .then(data => {
                setCourseData(data)
                if (data.length > 0) {
                    setCourseId(data[0].id)
                    setLoading(false);
                }
            }, error => {
                alert.error(error)
                setLoading(false);
            })
    }


    useEffect(() => {
        let getQuestionUrl = `admin-api/course-question`
        if (testId != undefined && courseId != undefined) {
            getQuestionUrl = `admin-api/course-question/test?course_test_id=${testId}&course_id=${courseId}`
            setIsEdit(false)
        }
        setQuestionUrl(getQuestionUrl)
        getQuestions(getQuestionUrl);
        getCourses();
    }, []);

    const getQuestions = (url) => {
        setLoading(true)
        CommonService.httpGetService(url)
            .then(res => {
                setData(res)
                setFilteredData(res)
                // if(res.is_selected == true){
                    let totalScore = 0;
                    res.forEach((data) => {
                        if(data.is_selected==true){
                            totalScore += data.marks;
                        }
                    })
                setTotalScore(totalScore);
                // }
                setLoading(false)
                 
                if (filterInputValue != '') {
                    filterApiData(filterInputValue,res)
                }
                   

            }, error => {
                setData([])
                alert.error(error)
                setLoading(false);
            })
    }

    const getFilteredQues = (courseId) => {
        setLoading(true)
        if (courseId && courseId.length > 0) {
            CommonService.httpGetService(`admin-api/course-question?course_id=${courseId}`)
            .then(res => {
                setData(res)
                setFilteredData(res)
                // // if(res.is_selected == true){
                //     let totalScore = 0;
                //     res.forEach((data) => {
                //         if(data.is_selected==true){
                //             totalScore += data.marks;
                //         }
                //     })
                // setTotalScore(totalScore);
                // // }
                setLoading(false)
                 
                if (filterInputValue != '') {
                    filterApiData(filterInputValue,res)
                }

            }, error => {
                setData([])
                alert.error(error)
                setLoading(false);
            })
        } else {
            setLoading(false);
            getQuestions(questionUrl)
        }
    }



    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading...'
            className="loaderStyle"
        >
            <Container className="containerBox" fluid>
                <Header></Header>

                <Container fluid>
                    <div className="heading">
                        <div className="lheading">
                            <span className="headTitle"> QUESTIONS </span>
                            <span className="searchItem"> {isEdit == false &&  filteredData && filteredData[0] && filteredData[0].course_name ? filteredData[0].course_name : ''} </span>
                        </div>
                        <div className="rheading">
                           {
                               isEdit == true ? ( <Link to="/create-question"> <button type="button" className="addNew">+ Add New Question</button></Link>) : ''
                           }
                            <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>
                        </div>
                    </div>
                    <div className="subheading">
                    {
                      isEdit == true ? (
                        <Form.Group controlId="formGridCode">
                        {/* <Form.Label>Course</Form.Label> */}
                        <Form.Control as="select" custom onChange={e => setCourseId(e.target.value)} onChange={e => getFilteredQues(e.target.value)} >
                            <option key="0" value="" >All Questions</option>
                            {courseData.map((course, index) => (
                                <option key={index+1} value={course.id}>{course.name} ( {course.code} )</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                           ) : ''
                    }
                   
                    <div className="lsubhead mr-3">
                    {
                    isEdit == false ? (<span className="test-marks">Test Score: {totalScore}</span>) : ''
                    }
                        </div>
                        <div className="lsubhead">
                            <input type="text" onChange={e => filterData(e.target.value)} className="tableSearch" placeholder="Search here..." />
                        </div>


                    </div>


                    {

                        filteredData && filteredData.length > 0 ? (

                            <Table responsive>
                                <thead>
                                    <tr className="tableHead">
                                        <th>#</th>
                                        {/* {isEdit == true ? (<th>Course</th>) : ''} */}
                                        <th>Question</th>
                                        <th>Type</th>
                                        <th>Marks</th>
                                        <th className=""></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        filteredData.map((datal, index) => (

                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                {/* {isEdit == true ? (<td>{datal.course_name}</td>) : ''} */}
                                                <td> <span dangerouslySetInnerHTML={{__html: datal.question}}></span> </td>
                                                <td> {datal.question_type}</td>
                                                <td> {datal.marks} </td>
                                                {isEdit == true ? (
                                                    <td>
                                                    <Link to={`/update-question/${datal.id}`} className=""><i className="fas fa-pencil-alt" ></i></Link>
                                                    <i className="fas fa-trash-alt" onClick={() => {
                                                        deleteHandleShow(datal.id)
                                                    }} ></i>
                                                </td>
                                                ) : (
                                                    <td className="">
                                                {
                                                    datal?.is_selected == false ? (
                                                        <button type="button" onClick={() => addTestQuestion(datal) } className='addStudent mr-2'>Add</button>

                                                    ) : (
                                                        <button type="button" onClick={() => removeTestQuestion(datal)} className='removeBtn' >Remove</button>
                                                    )
                                                }
                                                </td>
                                                )
                                                }
                                            </tr>
                                        ))

                                    }



                                </tbody>
                            </Table>
                        ) : (
                                <div className="nofoundRow">
                                    <img className="nofoundimg" src={NotFoundIMg} />
                                    <span className="nofoundimgCaption">Sorry! Questions are not available </span>
                                </div>
                            )

                    }
                    <Modal show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to edit this question?
       </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                No
       </Button>
                            <Button variant="primary">Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to delete this question?
       </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
       </Button>
                            <Button variant="primary" onClick={() => deleteQuestion(dltId)}> Yes</Button>
                        </Modal.Footer>
                    </Modal>


                </Container>
            </Container>
        </LoadingOverlay>



    );
};

export default viewQuestion;
