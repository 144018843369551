import React, { useState, useContext, useEffect,useReducer } from "react";
// import "../start/start-test";
import "./quiz.css"
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
// import Header from '../../../common/header/header'
// import { CommonService } from '../../../service/common.service';
import { useAlert } from "react-alert";
import LoadingOverlay from 'react-loading-overlay';
import queryString from 'query-string';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl'
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Quiz = ({ match }) => {
	
	const [loading, setLoading] = useState(false);
	const [role, setRole] = useState('')
	
	const alert = useAlert();
	const queryParams = queryString.parse(location.search)
	const [isMaximized,setIsMaximized] = useState(false);
	useEffect(() => {
		setLoading(false);

	}, []);


	return (
		// <LoadingOverlay
		// 	active={loading}
		// 	spinner
		// 	text='Loading...'
		// 	className="loaderStyle"
		// >

            <div className="quizContent">
            <div className="qsHeading mb-4">
                        <span className="quesNum">00:04:50</span>
                        <span className="quesNum">Question 1 of 8</span>
                        <span className="quesNum" style={{cursor: "pointer"}}>Next  <i className="fas fa-chevron-right"></i></span></div>
                        <div className="currentQ">
                            <span className="areaQues">Can revolver be used ?</span>
                        <div className="option-area">
                        <InputGroup className="mb-3">
                        {/* Use variant="success" when correct answer is selected
                            Use style={{border: "2px solid"}} + variant="outline-success" to display right ans , when wrong is selected.*/}


                        {/* <Button className="w-100" variant="success">Only in emergency</Button> */}
                        <Button className="w-100" variant="danger">Only in emergency</Button>
                        </InputGroup>
                        <InputGroup className="mb-3">
                        <Button className="w-100" style={{border: "2px solid"}} variant="outline-success">Yes</Button>
                        {/* <Button className="w-100" variant="outline-secondary">Yes</Button> */}
                        </InputGroup>
                        <InputGroup className="mb-3">
                        <Button className="w-100" variant="outline-secondary">No</Button>
                        </InputGroup>
                        <InputGroup className="mb-3">
                        <Button className="w-100" variant="outline-secondary">Depends</Button>
                        </InputGroup>
                        </div>
           
                        </div>
                        <div className="submitBtnRow">
                            {/* <span className="right-ans-msg">Correct answer, Keep it up!</span> */}
                            <span className="wrong-ans-msg">Oops! Wrong answer, Try harder</span>

                    </div>
            </div>
            
		// </LoadingOverlay>
	);
};


export default Quiz;
