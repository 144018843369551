import React, { useState, useContext, useEffect, useReducer } from "react";
import './certificatex.css';
import backGroundImage from '../../assets/fls-certificate/certificate-borders/background1.jpg'
import BigLogo from '../../assets/fls/cof_mark.png'
import queryString from 'query-string';
import {CommonService} from '../../service/common.service'
import * as moment from 'moment'
const CertificatePrint = () => {
    const queryParams = queryString.parse(location.search)
    const [user_name, setUserName] = useState("");
    const [teacher_name, setTeacherName] = useState("");
    const [teacherSignature, setTeacherSignature] = useState("");
    const [teacherDILicense, setTeacherDILicense] = useState("");
    const [description, setDescription] = useState("");
    const [courseName, setCourseName] = useState("");
    const [completionDate, setCompletionDate] = useState("");

    useEffect(() => {
       if (queryParams?.type) {
        if (queryParams.type == 'test') {
            getTestData(queryParams);
        }else if (queryParams.type == 'course') {
            getCourseData(queryParams);
        }
       }
    }, []);

    const getCourseData = (qParams) =>{
        CommonService.httpGetService(`admin-api/class_student?class_id=${qParams.id}&user_id=${qParams.user_id}`)
            .then(res => {
                 
                if (res.length > 0 && res[0])  {
                    setUserName(res[0].user_name)
                    setTeacherName(res[0].teacher_name)
                    setTeacherSignature(res[0].teacher_signature ? res[0].teacher_signature : null)
                    setTeacherDILicense(res[0].teacher_license)
                    setCourseName(res[0].course_name)
                    setCompletionDate(res[0].completion_date)
                    setDescription(res[0].course_certificate_details ? res[0].course_certificate_details : '')
                    
                }
            }, err => {
               
        })
    }

    const getTestData = (qParams) =>{
        CommonService.httpGetService(`admin-api/user-test/result?id=${qParams.id}&user_id=${qParams.user_id}`)
            .then(res => {
                if (res.length > 0 && res[0])  {
                setUserName(res[0].student_name)
                setTeacherName(res[0].teacher_name)
                setCourseName(res[0].course_name)
                setDescription(`for completing florida security school's test ${res[0].test_name} of course ${res[0].course_name}`)
                }
            }, err => {
               
        })
    }


	return (
        <div className="div1 certificateContainerArea">
        <div className={`div2 ${user_name ? 'voucher-container' : ''} `}>
        <img src={backGroundImage} ></img> </div>
        <div className="certificate-container">
            <span className="font-italic font-weight-bold">this</span>
            <h1 className="certificate-header">Certificate of Completion</h1>
            <h5 className="ctf-short-desc">Upon the recommendation of the undersigned <br></br> Florida Licensed DI Instructor, let it be known that </h5>
            <span className="holder-name">{user_name}</span>
            <span className="ctf-short-desc">has successfully completed the 42-hr. requisite program of studies in the course</span> <br/>
            <span className="ctf-course-name">Florida D License</span> 
            <span className="ctf-short-desc">as dictated by the Florida Division of Licensing</span> <br/>
            <span className="ctf-short-desc" >on : {completionDate ? moment(completionDate).format('MM/DD/YYYY') : ''}  </span> 
            <span className="ctf-short-desc" >under the tutelage of the </span> 
            <div className="ctf-instructor-details mb-5">
                <div className="name-desig mr-2">
                <span className="fa certi-tag border-botttom-2" style={{width:"200px"}}>{teacherDILicense}</span>
                <span className="ctf-font-thirteen certi-tag">DI License Number</span>
                <span className="fa certi-tag border-botttom-2">DS3000051</span>
                <span className="ctf-font-thirteen certi-tag">DS License Number</span>
                </div>

                <div className="name-desig ml-2">
                <span className="fa certi-tag " style={{width:"200px", height:"50px"}}> {teacherSignature ? <img className="img-fluid" src={teacherSignature}></img> : ''} </span>
                <span className="ctf-font-thirteen certi-tag" style={{borderTop:"2px solid"}}>Instructor Signature</span>
                <span className="fa certi-tag border-botttom-2">{teacher_name}</span>
                <span className="ctf-font-thirteen certi-tag">Instructor Name (print)</span>
                </div>
            </div>
            
        </div>
        </div>
        );
};

export default CertificatePrint;
