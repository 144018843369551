import React, { useState, useRef,useEffect } from 'react';
import io from 'socket.io-client';
import { Device } from 'mediasoup-client';
// const serverUrl = 'http://localhost:8991'

const TeacherKurento = (props) => {
    var socket;
    const [classroomId, setClassroomId] = useState('');
    const [isProducer, setIsProducer] = useState(false);
    const [videoSteamStarted, setVideoStreamStarted] = useState(false);
    const localVideoRef = useRef(null);
    let device, producerTransport, producer;

    // useEffect(() => {
    //     handleStartProducing()
    // }, [])

    useEffect(() => {
      if(!socket){
        const socketConfig = {
          transports : ['websocket'],
          query: {
            source: 'teacher', 
            email: props.userData?.email,
            userId: props.userData?.id
          },
          reconnection: true,      // Enable reconnections
          reconnectionDelay: 50000, // Delay between retries (in milliseconds)
          reconnectionDelayMax: 50000, // Maximum delay between retries
          // transportOptions: {
          //   polling: {
          //     requestTimeout: 10000, // Time in milliseconds to wait for a polling response
          //   },
          // },
        }
        // socket = io('http://localhost:8991',{
        //   ...socketConfig
        // });
        socket = io('https://app.flsecurityschool.com', {
            path: '/camera/socket.io/',
            ...socketConfig
        });

      }
        if(!props.video && videoSteamStarted == true){
            handleStopProducing()
        }
        if(props.video && videoSteamStarted == false){
            handleStartProducing()
        }
        return () => {
            handleStopProducing()
        }
    },[props.video])
    

    const handleStartProducing = async () => {
        if (!props.roomName) return alert("Please enter a Classroom ID");
    //  socket.emit('joinClassroom', { classroomId, isProducer: true }, (response) => {
    
        //
        // const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        // const videoTrack = stream.getVideoTracks()[0];
        // document.getElementById("localVideo").srcObject = stream;
      
        socket.emit('joinClassroom', { classroomId : props.roomName, isProducer: true }, async(response) => {
          if (response.status === 'ok') {
            setIsProducer(true);
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            localVideoRef.current.srcObject = stream;
            const videoTrack = stream.getVideoTracks()[0];
            initializeProducer(videoTrack);
          } else {
            alert(`Failed to join classroom: ${response.error}`);
          }
        });
    };

  
    const initializeProducer = async (videoTrack) => {
      device = new Device();
      const rtpCapabilities = await new Promise((resolve) => {
        socket.emit('getRouterRtpCapabilities', resolve);
      });
      await device.load({ routerRtpCapabilities: rtpCapabilities });
  
      socket.emit('createProducerTransport', { classroomId : props.roomName }, async (transportInfo) => {
        producerTransport = device.createSendTransport(transportInfo);
        producerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
          try {
            await socket.emit('connectProducerTransport', { classroomId : props.roomName, dtlsParameters });
            callback();
            console.log('Producer transport connected.');
          } catch (error) {
            errback(error);
          }
        });
        // producerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
        //   socket.emit('connectProducerTransport', { classroomId, dtlsParameters }, callback);
        // });
        producerTransport.on('produce', async ({ kind, rtpParameters }, callback, errback) => {
          try {
            const { id } = await new Promise((resolve) =>
              socket.emit('produce', { classroomId : props.roomName, kind, rtpParameters }, resolve)
            );
            callback({ id });
            console.log('Producer created with ID:', id);
          } catch (error) {
            errback(error);
          }
        });
  
        try {
          producer = await producerTransport.produce({ track: videoTrack });
          console.log('Media production started with track:', videoTrack);
          setVideoStreamStarted(true)
        } catch (error) {
          console.error('Error producing media:', error);
        }
      });
    };

    const handleStopProducing = () => {
        if (producer) {
            producer.close(); // Stop producing
            socket.emit('closeProducer', { classroomId: props.roomName });
            setVideoStreamStarted(false);
        }

        if (localVideoRef.current && localVideoRef.current.srcObject) {
            localVideoRef.current.srcObject.getTracks().forEach((track) => track.stop()); // Stop all tracks
            localVideoRef.current.srcObject = null; // Clear the video element
        }
    };


    return (
        <>
            {/* {
                showVideo ? <video id="broadcast" style={{ display: "none" }} muted autoPlay playsInline></video> : null
            } */}

<video id="broadcast" style={{ display: "none" }} ref={localVideoRef}  muted autoPlay playsInline></video>
        </>
    )
};

export default TeacherKurento;