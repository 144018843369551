import React, { useState, useContext, useEffect } from "react";
import './student-certificate.css';
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import Header from '../../common/header/header'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { CommonService } from '../../service/common.service';
import { useAlert } from "react-alert";
import NotFoundIMg from '../../assets/question-mark.png'
import LoadingOverlay from 'react-loading-overlay';
import { history } from '../../_helpers/history'
import * as FileSaver from 'file-saver'; 
import * as moment from 'moment'
const CourseCertificate = () => {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    // const [backUrlType , setSackUrlType]
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dltShow, setdltShow] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [dltId, setdltId] = useState();
    const [classStudentId, setclassStudentId] = useState();
    const [classId, setClassId] = useState(null);
    const [searchData, setSearchData] = useState();
    const [filterInputValue, setFilterInputValue] = useState('');
    const delethandleClose = () => setdltShow(false);

    const deleteHandleShow = async (datal) => {
        setdltShow(true);
        setdltId(datal);
    }


    const filterData = (value) => {
            let tempArr = Object.assign([], data)
            setFilterInputValue(value)
            if (value.length >= 3) {
                let upperCaseFilter = value.toUpperCase()
                let filterDataArr = tempArr.filter(
                    option => {
                        if (
                            (
                                option.name != undefined &&
                                option.name != null &&
                                (   
                                    option.name.toUpperCase().toString().includes(upperCaseFilter)
                                )
                            ) ||
                            (
                                option.primary_email != undefined &&
                                option.primary_email != null &&
                                (   
                                    option.primary_email.toUpperCase().toString().includes(upperCaseFilter)
                                )
                            )
                        ) {
                            return option
                        }
                    }
                )
                setFilteredData(filterDataArr)
            } else {
                setFilteredData(data)
            }
        
    }

    const search = async() => {
        CommonService.httpGetService('admin-api/class_student/student')
        .then(jsonResponse => {
            setData(jsonResponse);
            setFilteredData(jsonResponse);
        }, error => {
            alert.error(error)
        })
        // const jsonResponse = await response.json();       
        // setData(jsonResponse);
        // setFilteredData(jsonResponse);
	}

    const getCertificates = () => {
        let userData = JSON.parse(localStorage.getItem('user'))
        CommonService.httpGetService(`admin-api/class_student/student/certificate?user_id=${userData.id}`)
        .then(res => {
             
            setData(res)
            setFilteredData(res)
            if (filterInputValue != '') {
                filterApiData(filterInputValue,res)
            }
        }, error => {
            alert.error(error)
            setLoading(false);
        })
    }

    const filterApiData = (value,filterArr) => {
        let tempArr = Object.assign([], filterArr)
         
        setFilterInputValue(value)
        if (value.length >= 3) {
            let upperCaseFilter = value.toUpperCase()
            let filterDataArr = tempArr.filter(
                option => {
                    if (
                        (
                            option.name != undefined &&
                            option.name != null &&
                            (   
                                option.name.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        ) ||
                        (
                            option.primary_email != undefined &&
                            option.primary_email != null &&
                            (   
                                option.primary_email.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        )
                    ) {
                        return option
                    }
                }
            )
            setFilteredData(filterDataArr)
        } else {
            setFilteredData(filterArr)
        }
    
}
    

    useEffect(() => {
        setLoading(false);
        getCertificates()
    }, []);

    const downloadCertificate = (fileURL, fileName) => {
        CommonService.downloadPdf(fileURL)
        .then(fileBlob => {
             
            // let fileBlob = res.blob();
			let blob = new Blob([fileBlob], { 
			   type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
        }, error => {
            
        })
        }


    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
            <Header></Header>

            <Container fluid>
                <div className="heading">
                    <div className="lheading">
                        <span className="headTitle"> Certificates </span>
                    </div>
                </div>
                <div className="subheading">
                    <div className="lsubhead">
                    <input type="text" onChange={e => filterData(e.target.value)} className="tableSearch" placeholder="Search here..." />
                    </div>

                </div>



                {

                    filteredData && filteredData?.length > 0 ? (

                        <Table responsive>
                            <thead>
                                <tr className="tableHead">
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th className=""></th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredData.map((datal, index) => (

                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            {/* {moment(datal.start_date).format('MMMM Do YYYY hh:mm')} */}
                                            <td>{datal?.name} </td>
                                            <td>{datal?.type}</td>
                                            <td>{moment(datal.created_on).format('MMMM Do YYYY')}</td>
                                          
                                            <td className="">
                                                <button type="button" onClick={() => downloadCertificate(datal.certificate_url,`${datal.name}.pdf`) } className='addStudent mr-2'>Download</button>
                                            </td>
                                        </tr>
                                    ))

                                }



                            </tbody>
                        </Table>
                    ) : (
                            <div className="nofoundRow">
                                <img className="nofoundimg" src={NotFoundIMg} />
                                <span className="nofoundimgCaption">Sorry! No Certificates are available. Either you didn't attempted any test or have failed in it. </span>
                            </div>
                        )

                }
                <Modal show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to edit this class?
       </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
       </Button>
                        <Button variant="primary">Yes</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={dltShow}
                    onHide={delethandleClose}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to delete this class?
       </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={delethandleClose}>
                            No
       </Button>
                        <Button variant="primary" onClick={() => deleteCourses(dltId)}> Yes</Button>
                    </Modal.Footer>
                </Modal>


            </Container>
        </Container>
        </LoadingOverlay>



    );
};

export default CourseCertificate;
