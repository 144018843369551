import React, { useState, useContext, useEffect } from "react";
import './user-activity.css';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container';
import { CommonService } from '../../service/common.service';
import { useAlert } from "react-alert";
import NotFoundIMg from '../../assets/question-mark.png'
import LoadingOverlay from 'react-loading-overlay';
import * as FileSaver from 'file-saver'; 
import BigLogo from '../../assets/fls/logo-large-with-slogan.png'
import * as moment from 'moment'
import queryString from 'query-string';
const userActivity = ({match}) => {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    
    const [data, setData] = useState([]);
    const userId = match.params?.userId
    const type = match.params?.type ? match.params?.type : 'general'
    const queryParams = queryString.parse(location.search)
    const userName = match.params?.name
    const getUserActivity = () => {
        CommonService.httpGetService(`admin-api/user-activity/view/${type}/${userId}?activity_id=${queryParams.activity_id ? queryParams.activity_id : ''}`)
        .then(res => {
            setData(res.data)
        }, error => {
            alert.error(error)
            setLoading(false);
        })
    }

    useEffect(() => {
        setLoading(false);
        if (userId) {
            getUserActivity()
        }
    }, []);

    const downloadCertificate = (fileURL, fileName) => {
        CommonService.downloadPdf(fileURL)
        .then(fileBlob => {
             
            // let fileBlob = res.blob();
			let blob = new Blob([fileBlob], { 
			   type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
        }, error => {
            
        })
        }

        const groups = data.reduce((groups, row) => {
            const date = row.activity_at.split(' ')[0];
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(row);
            return groups;
          }, {});
          
          // Edit: to add it in the array format instead
          const activityArray = Object.keys(groups).map((date) => {
            return {
              date,
              data: groups[date]
            };
          });


    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <div className="w-100 bg-white p-3">
        <h2 className="d-flex align-items-center justify-content-between">{userName}'s Activities<i onClick={()=>window.close()} className="fa fa-times cursor-pointer text-right text-danger"></i> </h2>

                {

                    // data && data?.length > 0 ? (
                    //     <>
                    //     {
                    //                 data.map((datal, index) => (
                    //                     <div key={index} className="mb-3">
                    //                         <h5># {index+1} : {moment(datal.activity_at).format('ddd, MMM DD, YYYY HH:mm')} : {datal.activity_title}</h5>
                    //                            {
                    //                                datal.description.length > 0 ? (
                    //                                <h6 className="ml-5 pl-5" dangerouslySetInnerHTML={{__html: datal.description}}>

                    //                                </h6>
                    //                                ) : ''
                    //                            }
                    //                     </div>
                    //                 ))

                    //             }
                    //     </>
                    //     ) : (
                    //         <div className="nofoundRow">
                    //             <img className="nofoundimg" src={NotFoundIMg} />
                    //             <span className="nofoundimgCaption">Sorry! No Activity is available. </span>
                    //         </div>
                    //     )

                }
                {
                    // data && data?.length > 0 ? (
                    //     <div className=" student-activities ">
                    //     <p className="activity-date"><span><i className="fa fa-calendar-check-o mr-1"></i>{moment().format("ddd, YYYY MMM DD")}</span></p>

                    //     {
                    //                 data.map((datal, index) => (
                    //                     <div key={index} className="activity-box">
                    //                     <div className="row ml-0 mr-0">
                    //                         <div className="col-sm-1">
                    //                         <p className="activity-time mb-0"><i className="fa fa-clock-o mr-1"></i>{moment(datal.activity_at).format('HH:mm')}</p>
                    //                         </div>
                    //                         <div className="col-sm-11">                                           <p className="mb-0">
                    //                        <span className="activity-title">
                    //                        {datal.activity_title}</span>
                    //                            {
                    //                                datal.description.length > 0 ? (
                    //                                <span className="activity-description" dangerouslySetInnerHTML={{__html: datal.description}}>
                    //                                </span>
                    //                                ) : ''
                    //                            }</p>
                    //                        </div>
                    //                     </div>
                    //                     </div>
                    //                 ))

                    //             }
                    //     </div>
                    //     ) : (
                    //         <div className="nofoundRow">
                    //             <img className="nofoundimg" src={NotFoundIMg} />
                    //             <span className="nofoundimgCaption">Sorry! No Activity is available. </span>
                    //         </div>
                    //     )
                }
                {
                    activityArray && activityArray.length > 0 ? (
                        <div className=" student-activities ">    
                           {
                                        activityArray.map((activity, index) => (
                                            <div>
                                            <p className="activity-date"><span><i className="fa fa-calendar-check-o mr-1"></i>{moment(activity.date).format("MMMM DD YYYY")}</span></p>
                                            <div key={index} className="activity-box">
                                                {activity.data.map((act) => {
                                                    return (
                                                    <div className="row ml-0 mr-0">
                                                        <div className="col-sm-1">
                                                        <p className="activity-time mb-0"><i className="fa fa-clock-o mr-1"></i>{moment(act.activity_at).format('HH:mm')}</p>
                                                        </div>
                                                        <div className="col-sm-11">                                           
                                                        <p className="mb-0">
                                                       <span className="activity-title">
                                                       {act.activity_title}</span>
                                                           {
                                                               act.description.length > 0 ? (
                                                               <span className="activity-description" dangerouslySetInnerHTML={{__html: act.description}}>
                                                               </span>
                                                               ) : ''
                                                           }</p>
                                                       </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                             </div>
                                        ))
    
                                    }
                            </div>
                            ) : (
                                <div className="nofoundRow">
                                    <img className="nofoundimg" src={NotFoundIMg} />
                                    <span className="nofoundimgCaption">Sorry! No Activity is available. </span>
                                </div>
                            )
                }
               
        </div>
        </LoadingOverlay>



    );
};

export default userActivity;
