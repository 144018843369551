import React, { useState, useContext, useEffect, useReducer } from "react";
import "./pass-result.css";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Firework from '../../../assets/sparkle.png';
import Passtest from '../../../assets/pass-test.png';
import Failtest from '../../../assets/assignment.png';
import Blade from '../../../assets/blade.png';
import Cross from '../../../assets/cross.png';
import * as jsPDF from 'jspdf'
import * as html2canvas from 'html2canvas';
import CertificatePrint from '../../certificate/certificate';
import { useAlert } from "react-alert";
import {CommonService} from '../../../service/common.service';
import * as FileSaver from 'file-saver'; 
const PassResult = ({match,closeResult, onMinimize}) => {
  const testParamId = match.params?.testId
  const [marksObtained, setMarksObtained] = useState('')
  const [totalScore, setTotalScore] = useState('')
  const [totalQues, setTotalQues] = useState('')
  const [rightAns, setRightAns] = useState('')
  const [passingSts, setPassingSts] = useState('')
  const [certificateUrl, setCertificateUrl] = useState('')
	const [percent, setPercentage] = useState(0)
	const [isMaximized, setIsMaximized] = useState(false);

  const alert = useAlert();

    const printDocument = () => {
      CommonService.downloadPdf(certificateUrl)
      .then(fileBlob => {
    let blob = new Blob([fileBlob], { 
       type: 'application/pdf'
    });
    FileSaver.saveAs(blob, "certificate");
      }, error => {
          
      })
      }



      const testResult = () => {
        try {
            CommonService.httpGetService(`admin-api/user-test/result?id=${testParamId}`)
            .then(data => {
              setMarksObtained(data[0].total_marks_obtained)
              // setTotalScore(data[0].score)
              setTotalQues(data[0].total_questions)
              setRightAns(data[0].total_correct)
              setPassingSts(data[0].passing_status)
              setCertificateUrl(data[0].test_certificate_url)
                console.log(data , 'this is result');
                // let testScore =data[0].total_marks_obtained
                // let totalScore = data[0].score
                // let percent= ((testScore/totalScore) * 100).toFixed(2)
                  setPercentage(data[0].score)
            }, error => {
                alert.error(error)
                setLoading(false);
            })
        }
        catch {
            alert.error("Please check all the valid details")
        }
    }

    useEffect(() => {
      testResult();
      
  }, []);

  const hanldeCallback = ()=> {
    closeResult()
  }



	return (
		<Container className="p-0" fluid>
            <div className="result-screen">
           { 
           passingSts == "Passed" ?
            (<>
                    <div className="u-side">
                    <div className="image-col">
                    <img className="sparkle upstar" src={Firework} />
                    <img className="testbook" src={Passtest} />
                    <img className="sparkle downstar" src={Firework} />
                    </div>
                    <div className="caption-col">
                    Test Score : {marksObtained}
                    </div>
                    </div>
                    <div className="d-side">
                    <span className="congrats">Congratulations, you passed!</span>
                    <span className="congrats_tagline">you have successfully passed this test and you did well</span>
                    <div className="divider-row">
                        <div className="l-divider">
                        <span className="test-stat">{rightAns} of {totalQues}</span>
                        <span className="stat-caption">Correct Answers</span>
                        </div>
                        <div className="m-divider"></div>
                        <div className="r-divider">
                        <span className="course-stat">{percent}%</span>
                        <span className="stat-caption">Total Percentage</span>
                        </div>
                    </div>
                    {/* <div className="dwn-btn">
                    <Button className="grnColor" variant="success" onClick={() => printDocument()}>Download Certificate</Button>
                    </div>
                    <div id="divToPrint" style={{display: "none"}}>
                    <CertificatePrint  />
                    </div> */}
                    {/* <div className="dwn-btn">
                    <span className="congrats_tagline back-btn" onClick={()=>onMinimize(true)}>Back to Class</span>
                    </div> */}
                    {/* <div className="dwn-btn">
                    <span className="congrats_tagline back-btn" onClick={()=> hanldeCallback()}>Close</span>
                    </div> */}
                    <div className="dwn-btn">
                    <span className="congrats_tagline back-class back-btn" onClick={()=>hanldeCallback()}>Close &amp; Back to Class</span>
                    </div>
                    </div>
                </>
                ) : (
               <>
                    <div className="u-side" style={{backgroundColor: "#ff03076b"}}>
                    <div className="image-col">
                    <img className="sparkle upstar" src={Blade} />
                    <img className="testbook" src={Failtest} />
                    <img className="sparkle downstar" src={Blade} />
                    </div>
                    <div className="caption-col">
                    Test Score : {marksObtained}
                    </div>
                    </div>
                    <div className="d-side">
                    <span className="congrats">Oops, you didn't passed!</span>
                    {/* <span className="congrats_tagline">you can retry this test now or try again later</span> */}
                    <div className="divider-row">
                        <div className="l-divider">
                        <span className="test-stat">{rightAns} of {totalQues}</span>
                        <span className="stat-caption">Correct Answers</span>
                        </div>
                        <div className="m-divider"></div>
                        <div className="r-divider">
                        <span className="course-stat">{percent} %</span>
                        <span className="stat-caption">Total Percentage</span>
                        </div>
                    </div>
                    {/* <div className="dwn-btn">
                    <Button className="" variant="danger">Retry Test</Button>
                    </div> */}
                    <div className="dwn-btn">
                    <span className="congrats_tagline back-class back-btn" onClick={()=>hanldeCallback()}>Close &amp; Back to Class</span>
                    </div>
                    {/* <div className="dwn-btn">
                    <span className="congrats_tagline back-btn" onClick={()=> hanldeCallback()}>Close</span>
                    </div> */}
                    </div>
               </>
               )
              }
            </div>
        </Container>
	);
};

export default PassResult;
