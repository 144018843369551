import React, { useState, useEffect } from "react";
import LoadingOverlay from 'react-loading-overlay';
import queryString from 'query-string';
import {CommonService} from '../../service/common.service';
import Form from 'react-bootstrap/Form'
import * as moment from 'moment'
var jwt = require('jsonwebtoken');
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import cardImg from '../../assets/payment-img.png';
var AuthorizeNetIFrame = {};
var courseDataVar ;
var classDataVar ;
var userDataVar ;
var requestedDataObj = null;

const Payment = () => {
    const [loading, setLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const queryParams = queryString.parse(location.search)
    const [message, setMessage] = useState('')
    const [loaderMessage, setLoaderMessage] = useState('Loading...')
    const [redirectUrl, setRedirectUrl] = useState(queryParams?.payment_redirect_url);

    const [validated, setValidated] = useState(false);
    const [userId, setUserId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [mobileNo, setMobileNo] = useState('')
    const [zip, setZip] = useState('')

    const [countryData, setCountryData] = useState([])
    const [countryId, setCountryId] = useState('')
    const [countryName, setCountryName] = useState('')
    const [stateData, setStateData] = useState([])
    const [stateId, setStateId] = useState('')
    const [stateName, setStateName] = useState('')
    const [cityData, setCityData] = useState([])
    const [cityId, setCityId] = useState('')
    const [cityName, setCityName] = useState('')

    const [classId, setClassId] = useState('')
    const [courseId, setCourseId] = useState('')
    const [courseData, setCourseData] = useState('')
    const [classData, setClassData] = useState('')
    
    
    const [paymentAccessToken, setPaymentAccessToken] = useState(null)
    const [requestedData, setRequestedData] = useState(null)
    const [billingInfoEditable, setBillingInfoEditable] = useState(true)
    const [showCancelModal, setShowCancelModal] = useState(false);

    useEffect(() => {
        // const isLoggedIn = localStorage && localStorage.getItem('user') ? true : false;
        
        // if(isLoggedIn) {
        // 	if (redirectUrl) {
        //         let userInfo = JSON.parse(localStorage.getItem('user'))
        //         setLoading(false)
        //         location.href = `${process.env.baseUrl}/pg/payment-request?userId=${userInfo.id}&courseId=${queryParams?.course_id}&class_id=${queryParams?.class_id}&redirectUrl=${redirectUrl}` 

        // 	}else{
        //         setLoading(false)
        //         setMessage('No Redirect Url Found. Please Retry.')
        // 	}
        // }else{
        //     setLoading(false)
        //     let url = `?redirect_url=payment&course_id=${queryParams?.course_id}&class_id=${queryParams?.class_id}&order_id=${queryParams?.order_id}&payment_redirect_url=${queryParams?.payment_redirect_url}`
        //     history.push(`/${url}`)
        // }

        getCountry();
        getStates(231)
        getCities(4164)
        // if (isLoggedIn) {
        //     let userData = JSON.parse(localStorage.user)
        //     setIsLoggedIn(true)
        //     getUserData(userData.id)
        // }else{
        // }
        if (queryParams.payment_key) {
            parseToken(queryParams.payment_key)
        }

        if (!window.AuthorizeNetIFrame) window.AuthorizeNetIFrame = {};
				window.AuthorizeNetIFrame.onReceiveCommunication = function (querystr) {
                    var params = parseQueryString(querystr);
                    
						switch (params["action"]) {
							case "successfulSave":
								break;
							case "cancel":
                                console.log(params)
                                getSetCancelPaymentResponse()
								break;
							case "resizeWindow":
								console.log(params)
								break;
							case "transactResponse":
                                var ifrm = document.getElementById("add_payment");
                                if (params.response) {
                                    getSetPaymentResponse(JSON.parse(params.response),'success')
                                }
								// ifrm.style.display = 'none';
							}
					};

        if (window.addEventListener) {
            window.addEventListener("message", receiveMessage, false);
            } else if (window.attachEvent) {
                window.attachEvent("onmessage", receiveMessage);
            }

        if (window.location.hash && window.location.hash.length > 1) {
            callParentFunction(window.location.hash.substring(1));
            }



    }, []);

    const callParentFunction = (str) =>{
        if (str && str.length > 0 
            && window.parent 
            && window.parent.parent
            && window.parent.parent.AuthorizeNetIFrame 
            && window.parent.parent.AuthorizeNetIFrame.onReceiveCommunication)
            {
// Errors indicate a mismatch in domain between the page containing the iframe and this page.
                window.parent.parent.AuthorizeNetIFrame.onReceiveCommunication(str);
            }
        }

    const receiveMessage = (event) => {
        if (event && event.data) {
           
            callParentFunction(event.data);
            }
        }

        const getSetPaymentResponse = (response,status) => {
            debugger
            CommonService.httpGetService(`pg/payment-response?payment_status=${status}&order_id=${response.orderInvoiceNumber}&course_id=${courseDataVar[0].id}&course_name=${courseDataVar[0].name}&user_id=${userDataVar && userDataVar.id ? userDataVar.id : ''}`)
            .then(res => {
                console.log(res)
                location.href= process.env.baseUrl + `/payment_status?payment_status=${res.paymentStatus}&token=${res.token}`
				},error =>{ 
                    location.href= process.env.baseUrl + `/payment_status?payment_status=failed`

			})
        }

        const getSetCancelPaymentResponse = () => {
            debugger
            CommonService.httpGetService(`pg/payment-response?payment_status=failed&order_id=${requestedDataObj.orderId}&course_id=${requestedDataObj.courseId}&course_name=${requestedDataObj.courseName}&user_id=${userDataVar && userDataVar.id ? userDataVar.id : ''}`)
            .then(res => {
                console.log(res)
                location.href= process.env.baseUrl + `/payment_status?payment_status=${res.paymentStatus}&token=${res.token}`
				},error =>{ 
                    location.href= process.env.baseUrl + `/payment_status?payment_status=failed`

			})
        }

     const parseQueryString = (str) => {
            var vars = [];
            var arr = str.split('&');
            var pair;
            for (var i = 0; i < arr.length; i++) {
                pair = arr[i].split('=');
                vars.push(pair[0]);
                vars[pair[0]] = unescape(pair[1]);
                }
            return vars;
    }
    const parseToken = (token) => {
        try {
            let response = {}
            jwt.verify(token, process.env.PAYMENT_SECRET, async (err, result) => {
                if (err) {
                    response = {};
                } else {
                    response = result;
                    if (result.course_id) {
                        setCourseId(result.course_id)
                        setClassId(result.class_id)
                        if (result.class_id) {
                            getClassData(result.class_id)
                        }
                        getCourseData(result.course_id)
                    }
                }
            })
            return response
        }
        catch (err) {
            response = {};
            return response
        }
    }

    const getClassData = (id) => {
        CommonService.httpGetService(`admin-api/class?id=${id}`)
            .then(classData => {
                console.log('Class Data : ',classData)
                if (classData[0] != undefined) {
                    setClassData(classData[0])
                    classDataVar = classData[0]
                }
				},error =>{
                    setLoading(false);

			})
    }

    const getCourseData = async (id) => {
        try {
            setLoading(true);
            CommonService.httpGetService(`admin-api/courses?id=${id}`)
            .then(data => {
                console.log('Course Data : ',data)
                setCourseData(data)
                courseDataVar = data
            }, error => {
                console.log('Failed to get course Data due to : ',error)
            })
        }
        catch (error) {
            console.log('Failed to get course Data due to : ',error)
        }
    }

    const getUserData = (id) => {
        CommonService.httpGetService(`user/user?id=${id}`)
        .then(async data => {
            if (data.length > 0) {
                let userId = data[0].id
                let name = data[0].full_name
                let email = data[0].primary_email
                let address = data[0].address_1
                let mobile_no = data[0].mobile_no
                let zip = data[0].zip
                let country_id = data[0]?.country_id
                let state_id= data[0]?.state_id
                let city_id= data[0].city_id
                let city_name= data[0].city_name
                setUserId(userId)
                userDataVar = data[0]
                setName(name)
                setEmail(email)
                setAddress(address)
                setMobileNo(mobile_no)
                setZip(zip)
                setCountryId(country_id)
                let state =  await getStates(231) // Country Code of united states.
                let stateName = getLocationName(state,state_id)
                setStateName(stateName)
                setCityName(city_name)
                setStateId(state_id)
                setCityId(city_id)
            }
        },error =>{
            setLoading(false);
        })
    }

    const getLocationName = (locationArr,id) =>{
        let foundLocation = locationArr.filter(option => {
            return option.location_id == id
        })
        if (foundLocation.length > 0) {
            return foundLocation[0].name
        }else{
           return ""
        }
     }

     const getCountry = () => {

        try {
            setLoading(true);
            CommonService.httpGetService(`admin-api/location/country`)
            .then(countryArr => {
                console.log(countryArr);
                setCountryData(countryArr)
                setCountryName(countryArr[0].name)
                setLoading(false)

    
            }, error => {
                setLoading(false);
            })
        }
        catch {
            
        }
    }


   const getStates = (countryId) => {
    return new Promise(async (resolve, reject) => {
        try {
            setLoading(true);
            if (countryId) {
                CommonService.httpGetService(`admin-api/location/states?country_id=${countryId}`)
            .then(res => {
                console.log(res);
                // if (res.length > 0 && res[0] != undefined) {
                //     setStateId(res[0].location_id)
                // }
                setStateData(res)
                setLoading(false)
                resolve(res)
            }, error => {
                setLoading(false);
                resolve(null)
            })
            } else {
                setLoading(false);
                resolve(null)
            }
        }
        catch {
            resolve(null)
            
        }
    })
	}

    const getCities = (stateId) => {
        return new Promise(async (resolve, reject) => {
        try {
            setLoading(true);
            if (stateId) {
                CommonService.httpGetService(`admin-api/location/city?state_id=${stateId}`)
            .then(res => {
                console.log(res);
                // if (res.length > 0 && res[0] != undefined) {
                //     setCityId(res[0].location_id)
                // }
                setCityData(res)
                setLoading(false)
                resolve(res)
            }, error => {
                setLoading(false);
                resolve(null)
            })
            } else {
                setLoading(false);
                resolve(null)
            }
        }
        catch {
            resolve(null)
            
        }
    })
    }

    const handleSubmit = (showPreview) => {
        setLoading(true);
        const form = document.getElementById("CreateLectureForm");
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
            handleCreateUserBtn(showPreview);
        }
       
    };

    function handleCreateUserBtn (showPreview) {
        try {
            debugger
            setLoading(true)
            let obj = {
                "full_name": name,
                "address_1": address,
                "address_2": "",
                "address_3": "",
                "city_name": cityName,
                "state_name": stateName,
                "country": countryName,
                "zip": zip,
                "mobile_no": mobileNo,
                "primary_email": email,
                "course_id" : courseId,
                "class_id": classId
            }
            const token = jwt.sign(obj, process.env.PAYMENT_SECRET)
            CommonService.httpGetService(`pg/payment-request?payment_key=${token}&redirectUrl=${redirectUrl}`)
            .then(res => {
               setPaymentAccessToken(res.paymentPageToken)
               setRequestedData(res.requested_data);
               requestedDataObj = res.requested_data
              setTimeout(() => {
                if(document.getElementById('send_token')){
                    document.getElementById('send_token').submit()
                   }
                   
              }, 1000);
              setTimeout(() => {
                   setBillingInfoEditable(false)
                   setLoading(false)
              }, 5000);
               console.log(res)
            }, error => {
                setLoading(false);
                resolve(null)
            })
            // location.href = `${process.env.baseUrl}/pg/payment-request?payment_key=${token}&redirectUrl=${redirectUrl}`
           

        } catch (error) {
            setLoading(false);
        }
    }


    return (
        <LoadingOverlay
        active={loading}
        spinner
        text={loaderMessage}
        className="loaderStyle"
        >
          <div className="container md-no-container mb-50 p-2px">
           <div className="row mt-50 m-0">
            <div className="col-xl-4 col-md-5 col-sm-12 col-xs-12 mt-20 col-md-push-7 p-2px">               
            <div className="shadow-box mn-h-260">
                <h4 className="titleBar">Order Summary</h4>
                <div className="p-20">
                <table className="table table-bordered table-striped  table-responsive">
                <tr>
                            <td style={{maxWidth:"100px"}} className="text-left"><b>Course</b></td>
                            <td className="pr-2">{courseData && courseData[0].name ? courseData[0].name : ''}</td>
                        </tr>
                        {
                            classData != undefined ? 
                            <>
                             <tr>
                            <td style={{maxWidth:"100px"}} className="text-left"><b>Class</b></td>
                            <td className="pr-2">{classData.name ? classData.name : ''}</td>
                        </tr>
                        <tr>
                            <tr>
                            <td style={{maxWidth:"100px"}} className="text-left"><b>Schedule</b></td>
                            <td className="pr-2">
                                <tr className="float-right">
                                {classData.start_date ? moment(classData.start_date).format('MMM Do YYYY')  : ''} - {classData.end_date ? moment(classData.end_date).format('MMM Do YYYY')  : ''}
                                </tr>
                                <tr className="float-right">
                                {classData.start_time ? moment(classData.start_time, "HH:mm:ss").format('hh:mm A')  : ''} - {classData.end_time ? moment(classData.end_time , "HH:mm:ss").format('hh:mm A')  : ''}
                                </tr>
                            </td>
                            </tr>
                        </tr>
                            </> : <tr></tr>
                        }
                       

                        <tr>
                            <td style={{maxWidth:"100px"}} className="text-left"><b>Amount</b></td>
                            <td className="pr-2">$ {courseData && courseData[0].fee ? courseData[0].fee : ''}</td>
                        </tr>
                </table>
                </div>
            </div>
            </div>
           <div className="col-xl-8 col-md-7 col-sm-12 col-xs-12  mt-20 col-md-pull-5 p-2px">
               <div className="shadow-box mn-h-260">
                    <h4 className="titleBar">Billing Info</h4>
                    <div className="p-20">
                    {
                        billingInfoEditable == true  ?  <div className="">

                        <Form  id="CreateLectureForm" noValidate validated={validated}>
                        <div className="row">
                        <div className="col-sm-12" id="formGridname">
                                    <Form.Label>Name *</Form.Label>
                                    <Form.Control type="text" placeholder="" value={name} onChange={e => setName(e.target.value)}   pattern="[a-zA-Z0-9\s]+" required>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                        Please fill your name.
                                </Form.Control.Feedback>
                                </div>

                            </div>
                            <div className="row">
                                {isLoggedIn ? 
                                <div className="col-sm-6 col-xs-12" id="formGridEmail">
                                    <Form.Label>Email Address *</Form.Label>
                                    <Form.Control type="text" value={email} onChange={e => setEmail(e.target.value)} pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder="" readOnly>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                                Please fill your email address.
                                    </Form.Control.Feedback>
                                </div> : 
                                <div className="col-sm-6 col-xs-12" id="formGridEmail">
                                <Form.Label>Email Address *</Form.Label>
                                    <Form.Control type="text" value={email} onChange={e => setEmail(e.target.value)} pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder="" required>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                        Please fill your email address.
                                </Form.Control.Feedback>
                                </div>}
                                <div className="col-sm-6 col-xs-12" id="formGridMobile">
                                <Form.Label >Mobile Number *</Form.Label>
                                    <Form.Control value={mobileNo} onChange={e => setMobileNo(e.target.value)} pattern="(?<!\d)\d{10,12}(?!\d)" maxLength="12" type="text" required>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                        Invalid Phone Number
                                </Form.Control.Feedback>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-9 col-sm-12" id="formGridAddress">
                                <Form.Label >Address *</Form.Label>
                                    <Form.Control value={address} onChange={e => setAddress(e.target.value)} type="text" as="textarea" rows="1" required >
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                        Address is required
                                </Form.Control.Feedback>
                                </div>
                                <div className="col-xs-12 col-sm-3" id="formGridCity">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" placeholder="" value={cityName} onChange={(e) => setCityName(e.target.value)} required>
                                    </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                       City is required
                                </Form.Control.Feedback>
                                
                                </div>
                                
                            </div>

                            <div className="row">
                            <div className="col-md-4 col-sm-12" id="formGridZipCode">
                                <Form.Label >Zip Code *</Form.Label>
                                    <Form.Control value={zip} onChange={e => setZip(e.target.value)} type="number" required>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                        Zip Code is required
                                </Form.Control.Feedback>
                                </div>
                                <div className="col-xs-12 col-sm-4" id="formGridCountry">
                                <Form.Label>Country</Form.Label>
                                <Form.Control as="select" className="form-control" custom value={countryId} 
                                onChange={e => {
                                    setCountryId(e.target.value)
                                    getStates(e.target.value)
                                }}>
                                        {countryData.map((country, index) => (
                                            <option key={index} value={country.location_id}>{country.name}</option>
                                        ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                        Country is required
                                </Form.Control.Feedback>
                                </div>
                                <div className="col-xs-12 col-sm-4" id="formGridState">
                                <Form.Label>State</Form.Label>
                                <Form.Control as="select" className="form-control" custom value={stateId} onChange={e => {
                                    setStateId(e.target.value)
                                    getCities(e.target.value)
                                }} required>
                                    <option value="">Select</option>
                                        {stateData.map((state, index) => (
                                            <option key={index} value={state.location_id}>{state.name}</option>
                                        ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                        State is required
                                </Form.Control.Feedback>
                                </div>
                                
                            </div>
                            <div className=" row my-btns mt-50">
                                <div className="col-sm-6">
                                    <button type="button" className=" btn" onClick={()=> setShowCancelModal(true)} > Cancel </button>
                                </div>
                                <div className="col-sm-6">
                                    <button type="button" onClick={() => handleSubmit(false)} className=" active btn"> Next </button>
                                </div>
                            </div>
                        </Form>
                    </div> : 
                    <div>
                        <p><b>Name</b> : {name}</p>
                        <p><b>Email</b> : {email}</p>
                        <p><b>Phone</b> : {mobileNo} </p>
                        <p><b>Address</b> : {address}, {cityName} {stateName} {countryName}</p>
                        <p><b>Zip Code</b> : {zip}</p>
                    </div>
                    }
                        {/* {
                            paymentAccessToken ? 
                            <>
                            <div id="iframe_holder" className="center-block payment-gateway-area " >
                                    <iframe id="add_payment" className="embed-responsive-item panel" name="add_payment" width="100%" height="100%"  frameBorder="0" scrolling="no">
                                    </iframe>
                                </div>
                                <form id="send_token" action="https://test.authorize.net/payment/payment" method="post" target="add_payment" >
                                    <input type="hidden" name="token" value={paymentAccessToken} />
                                </form>
                            </> : ''
                        } */}
                    </div>
                    </div>
                </div>  
            </div>        
           {
                            paymentAccessToken ? 
                            <>
                            <div className="col-xs-12">
                                <div className="shadow-box mt-20">
                                    <h4 className="titleBar">Payment Info</h4>
                                    <div className="p-1px">
                                        <div id="iframe_holder" className="center-block payment-gateway-area " >
                                                <iframe id="add_payment" className="embed-responsive-item panel" name="add_payment" width="100%" height="100%"  frameBorder="0" scrolling="no">
                                                </iframe>                                                
                                        </div>
                                        <div className="payment-img">
                                                    <img src={cardImg} />
                                                </div>
                                        <div className="justify-content-around my-btns row mb-20 mx-0">
                                        <div className="col-sm-4">
                                            </div>
                                            <div className="col-sm-4">
                                                <button type="button" className=" btn" onClick={() => setShowCancelModal(true)} > Return To Home </button>
                                            </div>
                                            <div className="col-sm-4">
                                            </div>
                                            </div>
                                            <form id="send_token" action={process.env.pg_url} method="post" target="add_payment" >
                                                <input type="hidden" name="token" value={paymentAccessToken} />
                                            </form>
                                    </div>
                                </div>  
                            </div>                  
                            </> : ''
                        }
            <Modal show={showCancelModal}
                    onHide={()=> setShowCancelModal(false)}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Cancel this transaction?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=> setShowCancelModal(false)}>
                            No
                        </Button>
                        <Button variant="primary" onClick={()=> {
                            location.href=process.env.mainWebsiteUrl
                        }}> Yes</Button>
                    </Modal.Footer>
                </Modal>
           </div>
        </LoadingOverlay>
       );
};

export default Payment;
