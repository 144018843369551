import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useJwt } from "react-jwt";

var jwt = require('jsonwebtoken');

export const PrivateRoute = ({ component: Component, ...rest }) => {
     
    
    // Add your own authentication on the below line.
    const isLoggedIn = localStorage.getItem('user') ? true : false;
    var jwtToken = null;
    if(isLoggedIn) {
        var userDetails = JSON.parse(localStorage.getItem('user'))
        jwtToken = userDetails.access_token;
        var isExpired = false;
        var current_time = new Date().getTime() / 1000; 
        const jwt = parseToken(jwtToken);
        if (current_time > jwt.exp) { 
            localStorage.clear();
            isExpired = true;
        }

        return (
            <Route
              {...rest}
              render={props =>
                !isExpired ? (
                  <Component {...props} />
                ) : (
                  <Redirect to={{ pathname: '/' }} />
                )
              }
            />
        )
    }
    else{
        return (
            <Route
              render={props =>
                <Redirect to={{ pathname: '/' }} />
              }
            />
          )
    }
    
  }

  const parseToken = (token) => {
    // parseJwt(token) {
		var base64Url = token.split('.')[1];
		var base64 = base64Url.replace('-', '+').replace('_', '/');
		return JSON.parse(window.atob(base64));
	// }
}
  
  