import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// const OngoinClassStudentsComponent = ({classId, studentData,channelName}) => {
const TermsAndConditions = React.memo(function TermsAndConditions(props) {

    useEffect(() => {
    }, [props]);

    return (
        <div className="tNc-box">
            <h1>Terms and Conditions</h1>
<h4>Agreement between User and www.flsecurityschool.com</h4>
<p>Welcome to www.flsecurityschool.com. The www.flsecurityschool.com website (the "Site") is comprised of various web pages operated by Florida Security School LLC. . Please read these terms carefully, and keep a copy of them for your reference.</p>
<p><a href="www.flsecurityschool.com" target="_blank">www.flsecurityschool.com</a> is an E-Commerce Site.</p>
<p>Florida Security School is an online Security Officer Training courses. Our Courses include the Class D Unarmed Security Officer 40 Hour training course as well as The Class G Armed Security Officer training Course. Along with Security Officer training programs Florida security School also offer additional training in Firearms, first aid and Non lethal Weapons.</p>
<h4>Privacy</h4>
<p>Your use of www.flsecurityschool.com is subject to Florida Security School LLC's Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.</p>
<h4>Electronic Communications</h4>
<p>Visiting www.flsecurityschool.com or sending emails to Florida Security School LLC constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.</p>
<h4>Your Account</h4>
<p>If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that Florida Security School LLC is not responsible for third party access to your account that results from theft or misappropriation of your account. Florida Security School LLC and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.</p>

<h4>Children Under Thirteen</h4>
<p>Florida Security School LLC does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use www.flsecurityschool.com only with permission of a parent or guardian.</p>
<h4>Cancellation/Refund Policy</h4>
<p>You may cancel your registration to any course 5 days before the class commencement in order to get a full refund for your purchase. The cancelation policy does not include classes that have been rescheduled from their original start date. Classes may be rescheduled at any time free of charge</p>
<p>for up to 90 days from the purchase date.</p>
<p>Links to Third Party Sites/Third Party Services<br />www.flsecurityschool.com may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of Florida Security School LLC and Florida Security School LLC is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Florida Security School LLC is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Florida Security School LLC of the site or any association with its operators.</p>
<p>Certain services made available via www.flsecurityschool.com are delivered by third party sites and organizations. By using any product, service or functionality originating from the www.flsecurityschool.com domain, you hereby acknowledge and consent that Florida Security School LLC may share such information and data with any third party with whom Florida Security School LLC has a contractual relationship to provide the requested product, service or functionality on behalf of www.flsecurityschool.com users and customers.</p>

<h4>No Unlawful or Prohibited Use/Intellectual Property</h4>
<p>You are granted a non-exclusive, non-transferable, revocable license to access and use www.flsecurityschool.com strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to Florida Security School LLC that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.</p>
<p>All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of Florida Security School LLC or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.</p>
<p>You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Florida Security School LLC content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of Florida Security School LLC and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of Florida Security School LLC or our licensors except as expressly authorized by these Terms.</p>
<h4>Third Party Accounts</h4>
<p>You will be able to connect your Florida Security School LLC account to third party accounts. By connecting your Florida Security School LLC account to your third party account, you acknowledge and agree that you are consenting to the continuous release of information about you to others (in accordance with your privacy settings on those third party sites). If you do not want information about you to be shared in this manner, do not use this feature.</p>
<h4>International Users</h4>
<p>The Service is controlled, operated and administered by Florida Security School LLC from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the Florida Security School LLC Content accessed through www.flsecurityschool.com in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>
<h4>Indemnification</h4>
<p>You agree to indemnify, defend and hold harmless Florida Security School LLC, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. Florida Security School LLC reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with Florida Security School LLC in asserting any available defenses.</p>
<h4>Arbitration</h4>
<p>In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.</p>
<h4>Class Action Waiver</h4>
<p>Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE</p>
<p>THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE<br />OTHER. Further, unless both you and Florida Security School LLC agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.</p>

<h4>Liability Disclaimer</h4>
<p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. FLORIDA SECURITY SCHOOL LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.</p>
<p>FLORIDA SECURITY SCHOOL LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. FLORIDA SECURITY SCHOOL LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON- INFRINGEMENT.</p>
<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL FLORIDA SECURITY SCHOOL LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF FLORIDA SECURITY SCHOOL LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,</p>
<p>THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.</p>
<h4>Termination/Access Restriction</h4>
<p>Florida Security School LLC reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Florida and you hereby consent to the exclusive jurisdiction and venue of courts in Florida in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>
<p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and Florida Security School LLC as a result of this agreement or use of the Site. Florida Security School LLC's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of Florida Security School LLC's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by Florida Security School LLC with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.</p>
<p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and Florida Security School LLC with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and Florida Security School LLC with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.</p>
<p><b>Changes to Terms</b><br />Florida Security School LLC reserves the right, in its sole discretion, to change the Terms under which www.flsecurityschool.com is offered. The most current version of the Terms will supersede all previous versions. Florida Security School LLC encourages you to periodically review the Terms to stay informed of our updates.</p>
<p><b>Contact Us</b><br />Florida Security School LLC welcomes your questions or comments regarding the Terms:</p>
<p>Florida Security School LLC 301 Yamato Road, Suite 1240</p>
<p>Boca Raton, Florida 33431</p>
<p><br /><b>Email Address</b>: info@flsecurityschool.com</p>
<p><b>Telephone number</b>:<br />855-533-5233</p>
<p>Effective as of January 01, 2020</p>
                               
        </div>
        
        );
})



export default TermsAndConditions;
