import React, { useState, useContext, useEffect, useReducer } from "react";
import "./quizComponent.css";
import Button from 'react-bootstrap/Button';
// import { Link } from "react-router-dom";
import { CommonService } from '../../service/common.service';
import { useAlert } from "react-alert";
import { history } from '../../_helpers/history'
import QuizQuestionComponent from './quizQuestions/quizQuestionComponent'


const QuizComponent = ({ courseId,classId, testId, selectedQuiz,selectedLecture }) => {
	const alert = useAlert();
	const [isQuizStarted, setIsQuizStarted] = useState(false)
	const [quizResultId, setQuizResultId] = useState(false)

	const onClickSubmit = () => {
		try {
			var userData = JSON.parse(localStorage.getItem('user'))
			let reqObj = {
				"user_id": userData.id,
				"course_test_id": testId,
				"class_id": classId,
				"lecture_id" : selectedLecture.id,
				"tenant_id": 1,
				"last_modified_by": userData.id
			}
			console.log(reqObj)
			CommonService.httpPostService(`admin-api/user-test/start`, reqObj)
			.then(resp => {
					setIsQuizStarted(true)
					setQuizResultId(resp.data.id)
				}, error => {

					alert.error(error)
				})
		}
		catch {
			alert.error('Something went wrong..')
		}
	}


	return (
		isQuizStarted == false ? (
			<div className="quizScreen">
				<div className="testDetails">
					<div className="ltdetails">
						<span className="testName fontwt mb-1">{selectedQuiz?.name}</span>
						{
							selectedQuiz.duration_in_min ? <span className="testDuration mb-1">Duration <span className="fontwt">{selectedQuiz?.duration_in_min} min</span></span> : ''
						}
					</div>
					<div className="rtdetails">
						<span className="systemCompatible"><i className="fas fa-check-circle" style={{ color: "#A5C75F" }}></i> System is compatible for taking this quiz</span>
						{

							(testId != null) ? <Button onClick={() => onClickSubmit()} className="grnColor" variant="success">Enter Quiz  <i className="fas fa-arrow-circle-right"></i></Button> : null
						}
					</div>
				</div>
				{/* <div className="instructionDetails">
					<div className="generalIns">
						<span className="listHeading">General Instructions</span>
						{selectedQuiz && selectedQuiz.instructions ? (<span className="typeoftest d-flex" dangerouslySetInnerHTML={{ __html: selectedQuiz.instructions }}></span>) : ''}
					</div>

					<div className="otherIns">
						<span className="listHeading">Help and Support</span>
						<div className="typeoftest">Please contact the test administrator. <br />
							<span className="fontwt">{selectedQuiz?.help_and_support?.name}</span> at the given email address : <br />
							<a href={`mailto:${selectedQuiz?.help_and_support?.email}`}>{selectedQuiz?.help_and_support?.email}</a></div>
					</div>

				</div> */}
		</div>
		) : <QuizQuestionComponent quizId={testId} resultId={quizResultId}/>
	);
};

export default QuizComponent;
