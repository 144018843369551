import React, { useState, useContext, useEffect } from "react";
import "./view-course.css";
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Header from '../../../common/header/header'
import Pagination from 'react-bootstrap/Pagination'
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { CommonService } from '../../../service/common.service';
import { useAlert } from "react-alert";
import NotFoundIMg from '../../../assets/question-mark.png'
import LoadingOverlay from 'react-loading-overlay';
import tableDragger from '../../../assets/utils/table-dragger/dist/table-dragger';

const viewCourse = ({match}) => {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dltShow, setdltShow] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [dltId, setdltId] = useState();
    const [searchData, setSearchData] = useState();
    const  paramName = match.params?.name;
    const [isOrderChanged, setIsOrderChanged] = useState(false);
    const delethandleClose = () => setdltShow(false);

    const deleteHandleShow = async (datal) => {
        setdltShow(true);
        setdltId(datal);
    }



    const getCourses = async () => {
        try {
            setLoading(true);
            CommonService.httpGetService(`admin-api/courses`)
            .then(data => {
                setData(data);
                setFilteredData(data);
                setLoading(false);
                setTimeout(() => {
                    init_drag()
                }, 100);
            }, error => {
                alert.error(error)
                setLoading(false);
            })
        }
        catch {
            alert.error("Something went wrong")
        }
    }

    const filterData = (value) => {
            let tempArr = Object.assign([], data)
            if (value.length >= 3) {
                let upperCaseFilter = value.toUpperCase()
                let filterDataArr = tempArr.filter(
                    option => {
                        if (
                            (
                                option.name != undefined &&
                                option.name != null &&
                                (   
                                    option.name.toUpperCase().toString().includes(upperCaseFilter)
                                )
                            )
                        ) {
                            return option
                        }
                    }
                )
                setFilteredData(filterDataArr)
            } else {
                setFilteredData(data)
            }
            setTimeout(() => {
                init_drag()
            }, 100);
        
    }

    const init_drag = () => {
        var el = document.getElementById('table');
       if(el){
        var dragger = tableDragger(el, {
            mode: 'row',
            dragHandler: '.handle',
            onlyBody: true,
            animation: 300
          });
          dragger.on('drop',(from, to)=>{
            setIsOrderChanged(true)
          });
       }
      }



    const search = async() => {
        try {
            setLoading(true);
            CommonService.httpGetService(`admin-api/courses`)
            .then(data => {
                setData(data);
                setFilteredData(data);
                setLoading(false);
                setTimeout(() => {
                    init_drag()
                }, 100);
            }, error => {
                alert.error(error)
                setLoading(false);
            })
        }
        catch {
            alert.error("Something went wrong")
        }
	}


    const deleteCourses = (id) => {
        setLoading(true);
        CommonService.httpDeleteService(`admin-api/courses/${dltId}`)
            .then(users => {
                setdltShow(false);
                alert.success("Cources has been deleted successfully");
                getCourses()
                setLoading(false);
            }, error => {
                setdltShow(false);
                alert.error(error)
                setLoading(false);
            })

    }

    useEffect(() => {
        // search(paramName)
        setLoading(false);
        getCourses();
        
    }, []);

    const updateOrder = () => {
        let table = document.getElementById('table');
        let tRows = table['tBodies'][0].rows;
        let reqArr = [];
        for (let x = 0; x < tRows.length; x++) {
            let obj = {
                id: tRows[x].cells[0].innerText,
                display_order_id: x,
            }
            reqArr.push(obj);
        }
        CommonService.httpPutService(`admin-api/courses/update-order`,reqArr)
            .then(res => {
                alert.success('Ordering Updated.')
                setData([])
                setFilteredData([])
                getCourses()
            }, error => {
                alert.error(`Failed to update ordering.`)
                setLoading(false);
            })
    }




    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
            <Header></Header>

            <Container fluid>
                <div className="heading">
                    <div className="lheading">
                        <span className="headTitle"> Courses </span>
                        <span className="searchItem"> </span>
                    </div>
                    <div className="rheading">
                        <Link to="/create-course"> <button type="button" className="addNew">+ Add New Course</button></Link>
                    </div>
                </div>
                <div className="subheading">
                    <div className="lsubhead">
                    {
                        isOrderChanged ? <button type="button" onClick={e => updateOrder()}  className="addNew">Update Order</button> : ''
                    }
                    <input type="text" onChange={e => filterData(e.target.value)} className="tableSearch" placeholder="Search here..." />
                    </div>

                </div>



                {

                    filteredData && filteredData.length > 0 ? (

                        <Table responsive id="table">
                            <thead>
                                <tr className="tableHead">
                                    <th>#</th>
                                    {/* <th>Id</th> */}
                                    <th>Name</th>
                                    <th>Duration</th>
                                    <th>Fee</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredData.map((datal, index) => (

                                        <tr key={index} className="handle">
                                            <td hidden>{datal.id}</td>
                                            <td>{index + 1}</td>
                                            {/* <td>{datal.id}</td> */}
                                            <td>{datal.name}</td>
                                            <td>{datal.duration} {datal.duration_type}</td>
                                            <td>$ {datal.fee}</td>
                                            <td>
                                                <Link to={`/update-course/${datal.id}`} className=""><i className="fas fa-pencil-alt" ></i></Link>
                                                <i className="fas fa-trash-alt" onClick={() => {
                                                    deleteHandleShow(datal.id)
                                                }} ></i></td>
                                        </tr>


                                    ))

                                }



                            </tbody>
                        </Table>
                    ) : (
                            <div className="nofoundRow">
                                <img className="nofoundimg" src={NotFoundIMg} />
                                <span className="nofoundimgCaption">Sorry! Courses are not available </span>
                            </div>
                        )

                }
                <Modal show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to edit this course?
       </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
       </Button>
                        <Button variant="primary">Yes</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={dltShow}
                    onHide={delethandleClose}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to delete this course?
       </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={delethandleClose}>
                            No
       </Button>
                        <Button variant="primary" onClick={() => deleteCourses(dltId)}> Yes</Button>
                    </Modal.Footer>
                </Modal>


            </Container>
        </Container>
        </LoadingOverlay>



    );
};

export default viewCourse;








