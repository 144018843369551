import React, { useState, useContext, useEffect } from "react";
import "./view-lecture-asset.css";
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Header from '../../../common/header/header'
import Pagination from 'react-bootstrap/Pagination'
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { CommonService } from '../../../service/common.service';
import { useAlert } from "react-alert";
import { history } from '../../../_helpers/history'
import NotFoundIMg from '../../../assets/question-mark.png'
import LoadingOverlay from 'react-loading-overlay';
import tableDragger from '../../../assets/utils/table-dragger/dist/table-dragger';

const viewLectureAssets = ({ match }) => {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [dltShow, setdltShow] = useState(false);
    const delethandleClose = () => setdltShow(false);
    // const deleteHandleShow = () => setdltShow(true);
    const [dltId, setdltId] = useState();
    const [data, setData] = useState([]);
    const lectureId = match.params?.id
    const [isOrderChanged, setIsOrderChanged] = useState(false);

    const getLectureAssets = async () => {
        setLoading(true);
         
        try
        {        
            CommonService.httpGetService(`admin-api/lecture_asset?lecture_id=${lectureId}`)
            .then(data => {
                setData(data)
                setFilteredData(data)
                setLoading(false);
                setTimeout(() => {
                    init_drag()
                }, 100);
            }, error => {
                alert.error(error)
                setLoading(false);
            })
        }
        catch {
           alert.error('Something went wrong...') 
        }

    }

    const deleteHandleShow = async (datal) => {
         
        setLoading(true);
        setdltShow(true);
        setdltId(datal);
        setLoading(false);
    }


    const deleteCourses = (id) => {
        CommonService.httpDeleteService(`admin-api/lecture_asset/${dltId}`)
        .then(users => {
            setdltShow(false);
            alert.success("Assest has been deleted successfully");
            getLectureAssets()
            },error =>{
                setdltShow(false);
                alert.error(error)
        })
        // setLoading(false);
        // getLectureAssets();
    }

    const search = async() => {
        try
        {        
            CommonService.httpGetService(`admin-api/lecture_asset?lecture_id=${lectureId}`)
            .then(data => {
                setData(data)
                setFilteredData(data)
                setLoading(false);
            }, error => {
                alert.error(error)
                setLoading(false);
            })
        }
        catch {
           alert.error('Something went wrong...') 
        }
	}

    const filterData = (value) => {
        let tempArr = Object.assign([], data)
        if (value.length >= 3) {
            let upperCaseFilter = value.toUpperCase()
            let filterDataArr = tempArr.filter(
                option => {
                    if (
                        (
                            option.title != undefined &&
                            option.title != null &&
                            (   
                                option.title.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        )
                    ) {
                        return option
                    }
                }
            )
            setFilteredData(filterDataArr)
        } else {
            setFilteredData(data)
        }

        setTimeout(() => {
            init_drag()
        }, 100);
    
}

    useEffect(() => {
        search()
        setLoading(false);
        if (lectureId) {
            getLectureAssets();
        }
        
    }, []);

    const init_drag = () => {
        var el = document.getElementById('table');
        if(el){
            var dragger = tableDragger(el, {
                mode: 'row',
                dragHandler: '.handle',
                onlyBody: false,
                animation: 300
              });
              dragger.on('drop',(from, to)=>{
                setIsOrderChanged(true)
              });
        }
      }


    const updateOrder = () => {
        let table = document.getElementById('table');
        let tRows = table['tBodies'][0].rows;
        let reqArr = [];
        for (let x = 0; x < tRows.length; x++) {
            let obj = {
                id: tRows[x].cells[0].innerText,
                display_order_id: x,
            }
            reqArr.push(obj);
        }
        CommonService.httpPutService(`admin-api/lecture_asset/update-order`,reqArr)
            .then(res => {
                alert.success('Ordering Updated.')
                setData([])
                setFilteredData([])
                getLectureAssets()
            }, error => {
                alert.error(`Failed to update ordering.`)
                setLoading(false);
            })
    }



    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
            <Header></Header>

            <Container fluid>
                <div className="heading">
                    <div className="lheading">
                        <span className="headTitle"> Assets </span>
                        {/* <span className="searchItem"> 11 entries found </span> */}
                    </div>
                    <div className="rheading">
                        <Link to={`/create-lecture-asset/${lectureId}`}> <button type="button" className="addNew">+ Add New Asset</button></Link>
                        <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>

                    </div>
                </div>
                <div className="subheading">
                    <div className="lsubhead">
                    {
                        isOrderChanged ? <button type="button" onClick={e => updateOrder()}  className="addNew">Update Order</button> : ''
                    }
                      <input type="text" onChange={e => filterData(e.target.value)} className="tableSearch" placeholder="Search here..." />
                    </div>

                </div>



                {

                    filteredData && filteredData.length > 0 ? (
                        <Table responsive id="table">
                            <thead>
                                <tr className="tableHead">
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Category</th>
                                    <th>Published</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredData.map((datal, index) => (

                                        <tr key={index} className="handle">
                                            <td hidden>{datal.id}</td>
                                            <td>{index + 1}</td>
                                            <td>{datal.title}</td>
                                            <td>{datal.category}</td>
                                            <td>{datal.is_published.toString()}</td>
                                            <td>
                                                <Link to={`/update-lecture-asset/${lectureId}/${datal.id}`} className=""><i className="fas fa-pencil-alt" ></i></Link> <i className="fas fa-trash-alt" onClick={() => deleteHandleShow(datal?.id)}></i></td>
                                        </tr>
                                    ))
                                }




                            </tbody>
                        </Table>
                    ) : (
                            <div className="nofoundRow">
                                <img className="nofoundimg" src={NotFoundIMg} />
                                <span className="nofoundimgCaption">Sorry! Assets are not available </span>
                            </div>
                        )

                }

                <Modal show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to edit this Chapter?
    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
    </Button>
                        <Button variant="primary">Yes</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={dltShow}
                    onHide={delethandleClose}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to delete this Asset?
    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={delethandleClose}>
                            No
    </Button>
                        <Button variant="primary" onClick={() => deleteCourses()}>Yes</Button>
                    </Modal.Footer>
                </Modal>


            </Container>
        </Container>

        </LoadingOverlay>


    );
};

export default viewLectureAssets;








