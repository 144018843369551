import React, { useState, useContext, useEffect } from "react";
import "./create-lecture.css";
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Header from '../../../common/header/header'
// import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import {CommonService} from '../../../service/common.service';
import {history} from '../../../_helpers/history'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal'

const createLecture = ({match}) => {
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const alert = useAlert();
    const [chapertData, setChapertDat] = useState([]);
    const [title, setTitle] = useState('');
    const [type, setType] = useState('ASSET');
    const [course, setChapterId] = useState('')
    const [isPublished, setIsPublished] = useState('Y')
    // const [courseId , setCourseId] = useState(null)
    const [lectureId , setLectureId] = useState(null);
    const  courseParamId = match.params?.chapterId;
    const [testData, setTestData] = useState([]);
    const [test, setTestId] = useState('');
    const [courseId , setCourseId] = useState(null);

    const [dltShow, setdltShow] = useState(false);
    const delethandleClose = () => setdltShow(false);
    const deleteHandleShow = async () => {
        setdltShow(true);
    }

    useEffect(() => {
        setLoading(true);
        if(courseParamId != null) {
            
            CommonService.httpGetService(`admin-api/course_chapter?id=${courseParamId}`)
                .then(data => {
                    setChapertDat(data)
                    if (data.length > 0) {
                        setChapterId(data[0].id)
                        setCourseId(data[0].course_id)
                        getTest(data[0].course_id)
                        setLoading(false);
                    }
                },error =>{
                    alert.error(error)
                    setLoading(false);
                })
        }
    }, []);

    const handleSubmit = () => {
        setLoading(true);
        const form = document.getElementById("CreateLectureForm");
        // const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
            handleCreateLectureBtn();
            
        }
       
    };

    const getTest = async (id) =>  {

        setLoading(true);
        CommonService.httpGetService(`admin-api/course-test?course_id=${id}`)
        .then(data => {
            setTestData(data);
            setTestId(data[0].id)
            setLoading(false);
        }, error => {
            alert.error(error)
            setLoading(false);
        })

    }

    const onClickTest = (type) => 
    {
        if(type == 'TEST' || type == 'QUIZ')
        {
            if (testData.length > 0) {
               var filterArr =  testData.filter(option => {
                    return  option.type == type
                  })
                  if (filterArr.length > 0) {
                      setTestId(filterArr[0].id)
                  }
            }
        }

    } 

    const handleReset = () => {
        setTitle('');
        setIsPublished('Y');
        setType('ASSET');
        setValidated(false);
        setdltShow(false);

    };


    function handleCreateLectureBtn () {
        try {
            let obj = {
                "chapter_id": course,
                "title": title,
                "type" :type,
                "course_test_id": test,
                "is_published": isPublished,
                "last_modified_by": "ADMIN",
                "tenant_id": "TENANT"
            }
            // chapter_lecture
            CommonService.httpPostService(`admin-api/chapter_lecture`, obj)
            .then(res => {
                let lectId = res.data[0].id;
                setLectureId(lectId)
                setLoading(false);
                alert.success(`Lecture Created successfully.`);
				},error =>{
                    setLoading(false);
					alert.error(error)
			})
           

        } catch (error) {
            setLoading(false);
            alert.error("Please fill the required details!");
            // alert('Something went wrong with login')
        }
    }



    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
            <Header></Header>           
            <Container fluid>
                <div className="heading">
                    <div className="lheading">
                        <span className="headTitle">Create Lecture </span>
                        {/* <span className="searchItem"> Code : A10922 </span> */}
                    </div>
                    <div className="rheading">
                    <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>
                    </div>
                </div>

                <Form className="CreateEntryForm" id="CreateLectureForm" noValidate validated={validated}>
                <Form.Row>
                        <Form.Group as={Col} controlId="formGridCode">
                            <Form.Label>Chapter *</Form.Label>
                            <Form.Control as="select" custom onChange={e => setChapterId(e.target.value)} required>
                                {chapertData.map((course, index) => (
                                    <option key={index} value={course.id}>{course.title}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridName">
                            <Form.Label>Title *</Form.Label>
                            <Form.Control type="text"  value={title} onChange={e => setTitle(e.target.value)} placeholder="Lecture Title"  pattern="[a-zA-Z0-9\s]+" required>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                Please fill title of the course.
                        </Form.Control.Feedback>
                        </Form.Group>

                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="lectureType">
                            <Form.Label>Type *</Form.Label>
                            <Form.Control as="select" custom  onClick={() => onClickTest(type)} onChange={e => setType(e.target.value)} required>
                                <option  value="ASSET">ASSET</option>
                                <option  value="TEST">TEST</option>
                                <option  value="QUIZ">QUIZ</option>
                                {/* <option  value="VIDEO">VIDEO</option> */}


                            </Form.Control>
                        </Form.Group>
                        {
                          type == "TEST" || type == "QUIZ"  ?                           
                            (
                                <Form.Group as={Col}  controlId="selectTest">
                                <Form.Label>{type.trim().toLowerCase().replace(/^\w/, (c) => c.toUpperCase())} *</Form.Label>
                                    <Form.Control as="select" defaultValue={test}  custom onChange={e => setTestId(e.target.value)} required>
                                    <option  value="">Select</option>
                                        {testData.map((testL, index) => testL.type == type ? (
                                            <option key={index} value={testL.id}>{testL.name}</option>
                                        ) : '')}
                                    </Form.Control>
                                       </Form.Group>
                            ) : (
                                null
                            )
                        }
                        <Form.Group as={Col} className="" controlId="formGridDuration">
                            <Form.Label>Published</Form.Label>
                            {/* <Form.Check 
                                    type="switch"
                                    id="custom-switch"
                                    label=""
                                    defaultValue={isPublished}
                                    checked={isSwitchOn}
                                    onChange={e => setIsPublished(e.target.value)}
                                /> */}
                            <Form.Control as="select" defaultValue={isPublished} custom onChange={e => setIsPublished(e.target.value)}  >
                                <option value="Y" >Yes</option>
                                <option value="N">No</option>
                            </Form.Control> 
                        </Form.Group>
                    </Form.Row>

                    <div className="submitBtnRow">
                        <button type="button" onClick={() => handleSubmit()} className="saveBtn"> Submit </button>
                        <button type="button" onClick={() =>  deleteHandleShow()} className="resetBtn"> Reset </button>
                        {
                          type == "TEST" || type == "QUIZ" ?                           
                            (
                                <Link to={`/update-test/${test}`} className="" disabled={lectureId == null? true: false}> <button type="button" className="resetBtn">Tests</button></Link> 

                            ) : (
                                 <Link to={`/view-lecture-asset/${lectureId}`} className="" disabled={lectureId == null? true: false}><button type="button" className="resetBtn">Assets </button></Link>

                            )
                        }

                        {/* <button type="button" className="resetBtn"> <Link to={`/view-lecture-asset/${lectureId}`} className="" disabled={lectureId == null? true: false}>Assets</Link> </button> */}

                    </div>
                </Form>
                <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to reset this form?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
                            </Button>
                            <Button variant="primary"  onClick={() => handleReset()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>

            </Container>
        </Container>
        </LoadingOverlay>
    );
};

export default createLecture;


