import React, { useState, useContext, useEffect } from "react";
import "./view-student.css";
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import Header from '../../../common/header/header'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { CommonService } from '../../../service/common.service';
import { useAlert } from "react-alert";
import NotFoundIMg from '../../../assets/question-mark.png'
import LoadingOverlay from 'react-loading-overlay';
import { history } from '../../../_helpers/history'
import queryString from 'query-string';
const viewStudent = ({ match }) => {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    // const [backUrlType , setSackUrlType]
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showPaymentWarning, setShowPaymentWarning] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);

    const [dltShow, setdltShow] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [dltId, setdltId] = useState();
    const [classStudentId, setclassStudentId] = useState();
    const [classId, setClassId] = useState(null);
    const [filterInputValue, setFilterInputValue] = useState('');

    const paramId = match.params?.id;
    const paramClassName = match.params?.name;
    const queryParams = queryString.parse(location.search)
	const courseId = queryParams?.courseId;

    const [classList, setClassList] = useState([])
    const [newClassId, setNewClassId] = useState()
    // const  paramType = match.params?.type;
    const delethandleClose = () => setdltShow(false);

    const deleteHandleShow = async (datal) => {
        setdltShow(true);
        setdltId(datal);
    }

    const handleClosePaymentWarning = () => setShowPaymentWarning(false);

    const handleShowPaymentWarning = async (datal) => {
        setShowPaymentWarning(true);
        setSelectedStudent(datal);
    }
    const [showMoveStudentModal, setShowMoveStudentModal] = useState(false);

    const handleCloseMoveModal = () => setShowMoveStudentModal(false);

    const handleShowMoveModal = async (datal) => {
        setSelectedStudent(datal);
        setShowMoveStudentModal(true)
    }

    useEffect(() => {
        // search(paramName)
        // if(paramType != null) {
        //     setSackUrlType(paramType)
        // }
        let userData = {}
        if (localStorage.getItem('user') != null && localStorage.getItem('user') != undefined) {
			userData = JSON.parse(localStorage.getItem('user'))
		}
        setLoading(false);
        if (paramId != null) {
            setClassId(paramId)
            getStudent(paramId)
        }
        if(courseId && userData.role != undefined){
            getClasses(userData)
        }


    }, []);


    const getClasses = (userData) => {
        CommonService.httpGetService(`admin-api/class/get-classes/${userData.role}/${userData.id}/${courseId}`)
        .then(response => {
            setClassList(response)
        }, error => {
        })
    }

    const handleMoveStudent = () => {
        try {
            setLoading(true);
            if(newClassId){
                CommonService.httpPostService(`admin-api/class_student/migrate/${selectedStudent.id}/${paramId}/${newClassId}`, {})
                    .then(response => {
                        setdltShow(false);
                        alert.success("Student has been moved successfully");
                        getStudent(classId)
                        setNewClassId(null)
                        setLoading(false);
                        handleCloseMoveModal()
                    }, error => {
                        setdltShow(false);
                        alert.error(error)
                        setLoading(false);
                    })
            }
        } catch (error) {
            setLoading(false);
        }
    }



    const filterData = (value) => {
        let tempArr = Object.assign([], data)
        setFilterInputValue(value)
        if (value.length >= 3) {
            let upperCaseFilter = value.toUpperCase()
            let filterDataArr = tempArr.filter(
                option => {
                    if (
                        (
                            option.full_name != undefined &&
                            option.full_name != null &&
                            (
                                option.full_name.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        ) ||
                        (
                            option.primary_email != undefined &&
                            option.primary_email != null &&
                            (
                                option.primary_email.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        )
                    ) {
                        return option
                    }
                }
            )
            setFilteredData(filterDataArr)
        } else {
            setFilteredData(data)
        }

    }

    const search = async () => {
        CommonService.httpGetService('admin-api/class_student/student')
            .then(jsonResponse => {
                setData(jsonResponse);
                setFilteredData(jsonResponse);
            }, error => {
                alert.error(error)
            })
        // const jsonResponse = await response.json();       
        // setData(jsonResponse);
        // setFilteredData(jsonResponse);
    }


    const removeStudent = (id) => {
        setLoading(true);
        CommonService.httpDeleteService(`admin-api/class_student/student/${dltId}`)
            .then(users => {
                setdltShow(false);
                alert.success("Student has been deleted successfully");
                getStudent()
                setLoading(false);
            }, error => {
                setdltShow(false);
                alert.error(error)
                setLoading(false);
            })

    }

    const addClassStudent = (datal) => {

        try {
            setLoading(true);
            let obj = {
                "class_id": classId,
                "student_id": datal['id'],
                "status": "active"
            }
            CommonService.httpPostService(`admin-api/class_student`, obj)
                .then(users => {
                    setdltShow(false);
                    alert.success("Student has been added successfully");
                    getStudent(classId)
                    setLoading(false);
                }, error => {
                    setdltShow(false);
                    alert.error(error)
                    setLoading(false);
                })
        } catch (error) {
            setLoading(false);
        }
    }


    const removeClassStudent = (classStudentId) => {
        CommonService.httpDeleteService(`admin-api/class_student/${classStudentId}`)
            .then(res => {
                // setData(res)
                setdltShow(false);
                alert.success("Student has been removed successfully");
                getStudent(classId)
                setLoading(false);
            }, error => {
                alert.error(error)
                setLoading(false);
            })
    }

    const checkPaymentStatus = (datal) => {

        try {
            setLoading(true);

            CommonService.httpGetService(`admin-api/payment/status/${classId}/${datal['id']}`)
                .then(res => {
                    addClassStudent(datal)
                    setLoading(false);
                }, error => {
                    setdltShow(false);
                    handleShowPaymentWarning(datal)
                    // alert.error(error)
                    setLoading(false);
                })
        } catch (error) {
            setLoading(false);
        }
    }

    const doCashPayment = (datal) => {
        try {
            setLoading(true);
            handleClosePaymentWarning()
            let reqObj = {
                "userId": datal['id'],
                "classId": classId,
            }
            CommonService.httpPostService(`admin-api/payment/cash-payment`, reqObj)
                .then(res => {
                    addClassStudent(datal)
                    setLoading(false);
                }, error => {
                    setdltShow(false);
                    alert.error(error)
                    setLoading(false);
                })
        } catch (error) {
            setLoading(false);
        }
    }


    const getStudent = (classParamId) => {

        CommonService.httpGetService(`admin-api/class_student/student?class_id=${paramId}`)
            .then(res => {
                setData(res)
                let class_student_id = res[0].class_student_id
                setclassStudentId(class_student_id)
                setFilteredData(res)
                if (filterInputValue != '') {
                    filterApiData(filterInputValue, res)
                }
                // if (data.length > 0) {
                //     setChapterId(data[0].id)
                //     setLoading(false);
                // }
            }, error => {
                alert.error(error)
                setLoading(false);
            })
    }

    const filterApiData = (value, filterArr) => {
        let tempArr = Object.assign([], filterArr)

        setFilterInputValue(value)
        if (value.length >= 3) {
            let upperCaseFilter = value.toUpperCase()
            let filterDataArr = tempArr.filter(
                option => {
                    if (
                        (
                            option.full_name != undefined &&
                            option.full_name != null &&
                            (
                                option.full_name.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        ) ||
                        (
                            option.primary_email != undefined &&
                            option.primary_email != null &&
                            (
                                option.primary_email.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        )
                    ) {
                        return option
                    }
                }
            )
            setFilteredData(filterDataArr)
        } else {
            setFilteredData(filterArr)
        }

    }


   



    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading...'
            className="loaderStyle"
        >
            <Container className="containerBox" fluid>
                <Header></Header>

                <Container fluid>
                    <div className="heading">
                        <div className="lheading">
                            <span className="headTitle"> STUDENTS </span>
                            <span className="searchItem"> {paramClassName} </span>
                        </div>
                        <div className="rheading">
                            {/* <Link to="/create-student">  */}
                            {/* <button type="button" className="addNew" onClick={() => setIsCertificate(true)}>Generate Certificate</button> */}
                            {/* </Link> */}
                            <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>
                        </div>
                    </div>
                    <div className="subheading">
                        <div className="lsubhead">
                            <input type="text" onChange={e => filterData(e.target.value)} className="tableSearch" placeholder="Search here..." />
                        </div>

                    </div>



                    {

                        filteredData && filteredData?.length > 0 ? (

                            <Table responsive>
                                <thead>
                                    <tr className="tableHead">
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email Address</th>
                                        <th></th>
                                        <th className=""></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        filteredData.map((datal, index) => (

                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                {/* {moment(datal.start_date).format('MMMM Do YYYY hh:mm')} */}
                                                <td>{datal?.full_name} </td>
                                                <td>{datal?.primary_email}</td>
                                                <td className="">
                                                    {datal.class_student_status == 'pending' ? (
                                                        <span className="font-weight-bold text-danger"> Pending </span>
                                                    ) : ''}
                                                </td>
                                                <td className="">
                                                    {
                                                        datal.class_student_status != 'active' ? (
                                                            <button type="button" onClick={() => checkPaymentStatus(datal)} className='addStudent mr-2'>Add</button>

                                                        ) : (
                                                                <>
                                                                    <button type="button" onClick={() => removeClassStudent(datal['class_student_id'])} className='removeBtn mr-2' >Remove</button>

                                                                    <button type="button" onClick={() => handleShowMoveModal(datal)} className='addStudent mr-2' >Migrate</button>
                                                                </>
                                                            )
                                                    }
                                                    {/* <Link to="#" className=""><i className="fas fa-plus"></i></Link> */}
                                                    {/* <i className="fas fa-minus"></i> */}
                                                </td>
                                            </tr>
                                        ))

                                    }



                                </tbody>
                            </Table>
                        ) : (
                                <div className="nofoundRow">
                                    <img className="nofoundimg" src={NotFoundIMg} />
                                    <span className="nofoundimgCaption">Sorry! Students are not available </span>
                                </div>
                            )

                    }
                    <Modal show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to edit this class?
                         </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                No
                            </Button>
                            <Button variant="primary">Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to delete this class?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => delethandleClose()}>
                                No
                            </Button>
                            <Button variant="primary" onClick={() => removeStudent(dltId)}> Yes</Button>
                        </Modal.Footer>
                    </Modal>



                    {/* Show Payment warning Confirmation */}
                    <Modal show={showPaymentWarning}
                        onHide={handleClosePaymentWarning}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                No payment  Exist for requested student with class : {paramClassName}.
                    <br></br>
                    Do you still want to add this student in the class ?

                    </p>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClosePaymentWarning()}>
                                No
                            </Button>
                            <Button variant="primary" onClick={() => doCashPayment(selectedStudent)} >Yes</Button>
                        </Modal.Footer>
                    </Modal>


                    <Modal show={showMoveStudentModal}
                        onHide={handleCloseMoveModal}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="d-flex">
                                Are you sure to move {selectedStudent?.full_name} from current class?
                            </div>
                            <Form  id="CreateEntryForm" noValidate  >
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridCode">
                                        <Form.Label>All Classes *</Form.Label>
                                        <Form.Control as="select" custom onChange={e => setNewClassId(e.target.value)} required>
                                            <option value="">------Select------</option>
                                            {classList && classList.map((classData, index) => (
                                                <option key={index} value={classData.id}>{classData.name}</option>
                                            ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please select the class.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                            </Form>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseMoveModal}>
                                Cancel
                            </Button>
                            <Button type="button" variant="primary" onClick={()=>handleMoveStudent()}>Confirm</Button>
                        </Modal.Footer>
                    </Modal>


                </Container>
            </Container>
        </LoadingOverlay>



    );
};

export default viewStudent;








