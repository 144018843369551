import React, { useState, useContext, useEffect } from "react";
import './student-test-activity.css';
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import Header from '../../common/header/header'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { CommonService } from '../../service/common.service';
import { useAlert } from "react-alert";
import NotFoundIMg from '../../assets/question-mark.png'
import LoadingOverlay from 'react-loading-overlay';
import { history } from '../../_helpers/history'
import * as FileSaver from 'file-saver'; 
import * as moment from 'moment'
const StudentTestActivity = () => {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    // const [backUrlType , setSackUrlType]
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dltShow, setdltShow] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [dltId, setdltId] = useState();
    const [classStudentId, setclassStudentId] = useState();
    const [classId, setClassId] = useState(null);
    const [searchData, setSearchData] = useState();
    const [filterInputValue, setFilterInputValue] = useState('');
    const delethandleClose = () => setdltShow(false);

    const deleteHandleShow = async (datal) => {
        setdltShow(true);
        setdltId(datal);
    }


    const filterData = (value) => {
            let tempArr = Object.assign([], data)
            setFilterInputValue(value)
            if (value.length >= 3) {
                let upperCaseFilter = value.toUpperCase()
                let filterDataArr = tempArr.filter(
                    option => {
                        if (
                            (
                                option.class_name != undefined &&
                                option.class_name != null &&
                                (   
                                    option.class_name.toUpperCase().toString().includes(upperCaseFilter)
                                )
                            ) ||
                            (
                                option.course_name != undefined &&
                                option.course_name != null &&
                                (   
                                    option.course_name.toUpperCase().toString().includes(upperCaseFilter)
                                )
                            ) || 
                            (
                                option.passing_status != undefined &&
                                option.passing_status != null &&
                                (   
                                    option.passing_status.toUpperCase().toString().includes(upperCaseFilter)
                                )
                            )

                        ) {
                            return option
                        }
                    }
                )
                setFilteredData(filterDataArr)
            } else {
                setFilteredData(data)
            }
        
    }

    const search = async() => {
        CommonService.httpGetService('admin-api/class_student/student')
        .then(jsonResponse => {
            setData(jsonResponse);
            setFilteredData(jsonResponse);
        }, error => {
            alert.error(error)
        })
        // const jsonResponse = await response.json();       
        // setData(jsonResponse);
        // setFilteredData(jsonResponse);
	}

    const getStudentTestActivity = () => {
        let userData = JSON.parse(localStorage.getItem('user'))
        CommonService.httpGetService(`admin-api/user-test/result?user_id=${userData.id}`)
        .then(res => {
            setData(res)
            setFilteredData(res)
            if (filterInputValue != '') {
                filterApiData(filterInputValue,res)
            }
        }, error => {
            alert.error(error)
            setLoading(false);
        })
    }

    const filterApiData = (value,filterArr) => {
        let tempArr = Object.assign([], filterArr)
        setFilterInputValue(value)
        if (value.length >= 3) {
            let upperCaseFilter = value.toUpperCase()
            let filterDataArr = tempArr.filter(
                option => {
                    if (
                        (
                            option.class_name != undefined &&
                            option.class_name != null &&
                            (   
                                option.class_name.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        ) ||
                        (
                            option.course_name != undefined &&
                            option.course_name != null &&
                            (   
                                option.course_name.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        ) || 
                        (
                            option.passing_status != undefined &&
                            option.passing_status != null &&
                            (   
                                option.passing_status.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        )
                    ) {
                        return option
                    }
                }
            )
            setFilteredData(filterDataArr)
        } else {
            setFilteredData(filterArr)
        }
    
}
    

    useEffect(() => {
        setLoading(false);
        getStudentTestActivity()
    }, []);

    const downloadCertificate = (fileURL, fileName) => {
        CommonService.downloadPdf(fileURL)
        .then(fileBlob => {
            // let fileBlob = res.blob();
			let blob = new Blob([fileBlob], { 
			   type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
        }, error => {
            
        })
        }


    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
            <Header></Header>

            <Container fluid className="container mt-50">
                <div className="flx-box flx-h-start-end flx-v-center mob-flx-center flx-center">
                    <div className="heading">
                    <div className="lheading">
                        <h2 className="pageTitle "> Test Activity </h2>
                    </div>
                    </div>
                    <div className="subheading">
                        <div className="lsubhead rel srch-bar">
                        <i className="abs fa fa-search"></i>
                        <input type="text" onChange={e => filterData(e.target.value)} className="tableSearch" placeholder="Search here..." />
                        </div>
                    </div>
                </div>


                {

                    filteredData && filteredData?.length > 0 ? (

                        <Table responsive className="shadow-box mt-20">
                            <thead>
                                <tr className="tableHead">
                                    <th className="mx-25">#</th>
                                    <th className="mx-200">Course</th>
                                    <th className="mx-200">Class</th>
                                    <th className="mx-200" >Test</th>
                                    <th className="mx-75">Status</th>
                                    <th className="text-center">Date</th>
                                    {/* <th className="text-center">Activity</th> */}

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredData.map((datal, index) => (

                                        <tr key={index}>
                                            <td className="mx-25">{index + 1}</td>
                                            {/* {moment(datal.start_date).format('MMMM Do YYYY hh:mm')} */}
                                            <td className="mx-200" >{datal?.course_name} </td>
                                            <td className="mx-200">{datal?.class_name}</td>
                                            <td className="mx-200">{datal?.class_name}</td>
                                            <td className="mx-75">{datal?.passing_status}</td>
                                            <td className="text-center">{moment(datal.submit_timestamp).format('DD/MM/YYYY  HH:mm')}</td>
{/*                                           
                                            <td className="text-center">
                                                { datal.answer_sheet_url ? 
                                                 <button type="button" onClick={() => downloadCertificate(datal.answer_sheet_url,`${datal.test_name ? datal.test_name : ''}_test_activity.pdf`) } className='addStudent mt-2 mr-2 '><span className="mr-2">Download</span> <i className="fa fa-download"></i> </button> : ''
                                                }
                                            </td> */}
                                        </tr>
                                    ))

                                }



                            </tbody>
                        </Table>
                    ) : (
                            <div className="nofoundRow">
                                <img className="nofoundimg" src={NotFoundIMg} />
                                <span className="nofoundimgCaption">Sorry! No Test Activities are available. </span>
                            </div>
                        )

                }
            </Container>
        </Container>
        </LoadingOverlay>



    );
};

export default StudentTestActivity;
