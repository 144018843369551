import React, { useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown'
import newChatIcon from '../assets/new_chat.png'
const Student = React.memo(function (props) {

    useEffect(()=>{
        // console.log(props);
    },[])

    return ( 
        <>
        <div className="participantDetails">
                    <div className="usr-avatar d-flex justify-center" style={{ backgroundColor: `${props.student.profileColor}` ,  justifyContent: "center" }}>
                        <span className="usr-name">{props.student.shortName}</span>
                    </div>

                    {/* <i className={props.student.login_status == 'online' ? 'fa fa-circle mr-3 fontThirteen text-success' : 'fa fa-circle mr-3 fontThirteen'} aria-hidden="true"></i> */}

                    <i className={`fa fa-circle mr-3 fontThirteen onlineStatus ${props.student.login_status == 'online' ? 'text-success' : '' }`} aria-hidden="true"></i>
                    <div className={`label ml-1`} onClick={() => {
                        props.setActiveChatStudent(props.student)
                        props.setClassList(true)
                        props.getGroupMessages(props.student)
                    }} style={{fontSize: "12px", fontWeight: "500"}}>
                        {props.student.full_name} 
                </div>
               
                </div>
                <div className="option d-none justify-content-between align-items-center">
                {/* <i className={props.student.login_status == 'online' ? 'fa fa-circle mr-3 fontThirteen text-success' : 'fa fa-circle mr-3 fontThirteen'} aria-hidden="true"></i>
                 */}
                                     {/* <i className={`fa fa-circle mr-3 fontThirteen onlineStatus ${props.student.login_status == 'online' ? 'text-success' : '' }`} aria-hidden="true"></i> */}
                
                                     {props.student['has_new_message'] == true ?  <i className="fa fa-comment new-message"></i> : ''}
                
                {/* {props.student['has_new_message'] == true ?  <img src={newChatIcon} style={{width : "15px"}} alt="new"></img> : ''} */}
                    <Dropdown className="d-flex align-items-center d-none">
                        <Dropdown.Toggle variant="" id="dropdown-basic">
                            <i className="fas fa-ellipsis-v"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="read-status">
                            <Dropdown.Item onClick={() => {
                                props.removeStudent(props.student)
                            }
                            }><span>Kick Out</span></Dropdown.Item>

                            <Dropdown.Item onClick={() => {
                                props.muteStudent(props.student)
                            }
                            }><span>Mute</span></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
               
                </div>
        </>
    );
});



export default Student;
