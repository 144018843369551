import React, { useState, useContext , useEffect } from "react";
import "./Signup.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import BigLogo from './../../assets/fls/logo-large-with-slogan.png'
import { history } from '../../_helpers/history'
import { useAlert } from "react-alert";
import {CommonService} from '../../service/common.service';
import Form from 'react-bootstrap/Form'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal'
import Google from './../../assets/google.png'
import facebook from './../../assets/facebook.png'

const Signup = () => {
    const [loading, setLoading] = useState(false);
    // const { handleSubmit, register, errors } = useForm();
	const [validated, setValidated] = useState(false);
	const [name, setName] = useState('')
	const [primary_email, setPrimaryEmail] = useState('');
	const [password, setPassword] = useState('')
    const alert = useAlert();
    const [showTnc, setShowTnc] = useState(false);
    const [isCLicked, setIsClicked] = useState(false);
    const handleTncClose = () => setShowTnc(false);
    const handleTncShow = () => setShowTnc(true);
    useEffect(()=>{
		const isLoggedIn = localStorage.getItem('user') ? true : false;
		if(isLoggedIn) {
			history.push('/courses');
		}

    })
    
    const handleSubmit = (name , primary_email , password) => {
        setLoading(true);
            const form = document.getElementById("loginform");
            if (form.checkValidity() === false) {
                setValidated(true);
                event.preventDefault();
                event.stopPropagation();
                setLoading(false);
            }
            else {
                handleSignupButtonClick(name, primary_email, password);
                setLoading(false);
            }       
    };


	function handleSignupButtonClick(name , primary_email , password) {
        try {
            if (isCLicked == false) {
                setIsClicked(true)
                let obj = {
                    "name": name,
                    "primary_email": primary_email,
                    "password": password
                }
                setLoading(true);
                CommonService.httpPostService('user/signup', obj)
                .then(users => {
                    localStorage.setItem('user' , JSON.stringify(users))
                    history.push('/courses');
                    setLoading(false);
    
                },error =>{
                    setIsClicked(false)
                    alert.error(error)
                    setLoading(false);
    
                })	
            }			
			
		} catch (error) {
            setIsClicked(false)
            alert.error('Something went wrong with Signup')
		}
    };
    
    function handleEnter(e) {
		if (e.keyCode === 13) {
		  const form = document.getElementById("loginform");
		//   const form = e.target.form;
          const index = Array.prototype.indexOf.call(form, e.target);
          form.elements[index + 1].focus();
          if (form.elements[index + 1] && form.elements[index + 1].className == 'signupBtn') {
            form.elements[index + 1].click()
          }
		  e.preventDefault();
		}
	}

    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerColor" fluid>
            <Container>
                <Row className="justify-space-around">
                    <Col xs={6} className="col_of_img">
                    <img className="coverpic" src={BigLogo} />

                        {/* <img className="firstpage" src="" /> */}
                    </Col>
                    <Col xs={12} md={4} >
                        <Col >
                        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                            <div className="CompanyName">
                            Florida Security School
                            </div>
                            <div className="contentHeading">
                                Welcome
                            </div>
                            <div className="headingCaption">Already Signed up ? <span className="logIn"><Link to='/Login'>Log in</Link></span> </div>
                            <div className="userdetailSection">
                        <Form className="loginformCol" id="loginform" noValidate validated={validated} > 
                           <Form.Group >
                                <Form.Control 
                                name="name"
                                type="text" 
                                className="emailInput" 
                                placeholder="Your Name" 
                                value={name} 
                                pattern="[a-zA-Z\s]+"
                                onKeyDown={handleEnter}
                                onChange={e => setName(e.target.value)}
                                required
                                />
                              <Form.Control.Feedback type="invalid">
											Please enter a valid name. Only Alphabets can be used.
									</Form.Control.Feedback>
                                </Form.Group>
									 {/* {errors.name && errors.name.type === "pattern" && <span className="errorMsgUsr">Please a valid name</span>} */}
                                <Form.Group >    
                                <Form.Control
                                name="email" 
                                type="text" 
                                className="emailInput" 
                                placeholder="Your Email Address" 
                                pattern="[^@\s]+@[^@\s]+\.[^@\s]+" 
                                value={primary_email} 
                                onKeyDown={handleEnter}
                                onChange={e => setPrimaryEmail(e.target.value)} 
                                required
                                />
                                <Form.Control.Feedback type="invalid">
											Please enter a valid email address.
									</Form.Control.Feedback>

                                </Form.Group>
                                <Form.Group>
                                <Form.Control 
                                name="password"
                                type="password" 
                                className="pwdInput" 
                                placeholder="Your Password" 
                                value={password} 
                                onKeyDown={handleEnter}
                                onChange={e => setPassword(e.target.value)} 
                                 required
                                  />
                                  	<Form.Control.Feedback type="invalid">
											Please create a new password
									</Form.Control.Feedback>
                                  </Form.Group>
									 {/* {errors.password && errors.password.type === "required" && <span className="errorThird">Please create a new password</span>} */}

                                <button type="button" className="signupBtn" 
                                onClick={(e) => {
                                    handleSubmit(name , primary_email , password)
                                    console.log('Btn Clicked')
                                }}
                               
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit(name , primary_email , password)
                                    }else{
                                        null
                                    }
                                }} 
                                >
                                    Sign up
                           </button>
                           </Form>
                                <div className="altOption mt-2">
                                {/* <span className="tagTitle">Or login With</span>
										<div className="twoTag">
											<div className="facebook">
												<button type="button" className="fbBtn">
													<img className="fblogo" src={facebook} />

												</button>
											</div>
											<div className="google">
												<button type="button" className="googleBtn">
													<img className="googlelogo" src={Google} />

												</button>
											</div>
										</div> */}
                                    <span className="tnc"> By continuing you accept our <span className="forgotpwd" onClick={handleTncShow}> Terms & Conditions</span>  </span>

                                </div>
                            </div>
                            <Modal show={showTnc}
                                onHide={handleTncClose}
                                backdrop="static"
                                keyboard={false}>
                                <Modal.Header className="d-flex justify-center">
                                    <Modal.Title >Welcome to your new account</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="tnc-body">
								Welcome to your new account: training@flsecurityschool.com. Your account is compatible with many Google services, but your fisecurityschool.com administrator decides which services you may access using your account. For tips about using your new account, visit the Google Help Center.
								When you use Google services, your domain administrator will have access to your training@flsecurityschool.com account information, including any data you store with this account in Google services. You can learn more here, or by consulting your organization's privacy policy, if one exists. 
								You can choose to maintain a separate account for your personal use of any Google services, including email. If you have multiple Google accounts, you can manage which account you use with Google services and switch between them whenever you choose. Your username and profile picture can help 
								you ensure that you're using the intended account.
								If your organization provides you access to the G Suite core services, your use of those services is governed by your organization's G Suite agreement. Any other Google services your administrator enables ("Additional Services) are available to you under the Google Terms of Service and the 
								Google Privacy Policy. Certain Additional Services may also have service Specific terms. Your use of any services your administrator allows you to access constitutes acceptance of applicable service-specific terms.
								Click Accept below to indicate that you understand this description of how your training@fisecurityschool.com account works and agree to the Google Terms of Service and the Google Privacy Policy.
                                </Modal.Body>
                                <Modal.Footer className="tnc-footer">
                                    <button className="loginBtn" onClick={handleTncClose}>
                                        Accept
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Col>
                </Row>
               
            </Container>
        </Container>
       </LoadingOverlay>
    );
};

export default Signup;
