const initialState = {
    messageArr: [],
    studentArr: [],
    message: "This is sample message"
    
}

function rootReducer(state = initialState, action) {
    var array = [];
    // if(action.type == "MESSAGE") {
    //     array = [...state.messageArr, action.payload];
    // }
    switch (action.type) {
        case "MESSAGE_ARRAY": {
            const { channel } = action;
            return {
              ...state,
              messageArr: {
                ...state.messageArr, 
                [channel]: action.payload, 
              },
            };
          }
      
          case "MESSAGE": {
            const channel  = action.channel;
            
            return {
              ...state,
              messageArr: {
                ...state.messageArr, 
                [channel]: [
                  ...(state.messageArr[channel] || []), 
                  action.payload,
                ],
              },
            };
          }
        case 'STUDENT_ARRAY':
            return Object.assign({}, state, {
                studentArr : action.payload,
            })
        default:
            return state;
    }
}

export default rootReducer;