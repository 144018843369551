import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import {Modal } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { CommonService } from '../../service/common.service';
import  './QAModal.css';

const QAModal = (props) => {
  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState([])
  const [userId, setUserId] = useState()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [updatedQuestions, setUpdatedQuestions] = useState([])
  const history = useHistory()
  const alert = useAlert()

  useEffect(() => {
    setLoading(true);
    getQuestions()
}, []);

// const handleLogout = () => {
//   localStorage.removeItem('user');
//   history.push('/');
// }
  const initialState = {
    securityQuestionOneId : "",
    securityQuestionOneAns : "",
    securityQuestionTwoId : "",
    securityQuestionTwoAns : "",
    securityQuestionThreeId : "",
    securityQuestionThreeAns : "",
    securityQuestionFourId : "",
    securityQuestionFourAns : "",
    securityQuestionFiveId : "",
    securityQuestionFiveAns : "",
    securityQuestionSixId : "",
    securityQuestionSixAns : "",
    securityQuestionSevenId : "",
    securityQuestionSevenAns : "",
    securityQuestionEightId : "",
    securityQuestionEightAns : "",
    securityQuestionNineId : "",
    securityQuestionNineAns : "",
    securityQuestionTenId : "",
    securityQuestionTenAns : ""
  }
  
  const [data, setData] = useState(initialState)

  useEffect(() => {
    if (localStorage.getItem('user') != null && localStorage.getItem('user') != undefined) {
        const user =  JSON.parse(localStorage.getItem('user'))
        setUserId(user.id)
    }
    setSelectedOptions([])
}, []);

  const getQuestions = () => {
    CommonService.httpGetService("admin-api/security_questions")
    .then(data => {
      if(data.questions.length > 0) {
        setQuestions(data.questions)
        setLoading(false)
      }
    }, error => {
      console.log(error)
      // alert.error(error)
      setLoading(false)
    })
  }

    const handleChange = async (e,type) => {
      console.log(`Selected options : `,selectedOptions)
      if(type == 'question'){
        if(selectedOptions.length > 0 && data[e.target.name] != "" && data[e.target.name] != e.target.value){
          let tempSelected = JSON.parse(JSON.stringify(selectedOptions));
          tempSelected.splice(tempSelected.indexOf(Number(data[e.target.name])),1)
          if(e.target.value){
            setSelectedOptions([...tempSelected,Number(e.target.value)])
          }
        }else{
          if(e.target.value){
            setSelectedOptions([...selectedOptions,Number(e.target.value)])
          }
        }
      }
      setData({ ...data, [e.target.name]: e.target.value })
    };

    const formSubmitHandler = async (event) => {
      event.preventDefault();
      data.user_id = userId
      CommonService.httpPostService("admin-api/security_questions", data)
      .then(() => {
        props.closeModal()
      }).catch(error =>  {
        alert.error(error)
        console.log(error)
      })
    };

  return (
    <Modal
      show={props.showModal}
      // onHide={() => setShow(false)}
      dialogClassName="modal-90w ques-ans"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header>
        <Modal.Title id="example-custom-modal-styling-title">
       <span className='mr-2'>Question & Answers</span>
        <small className='d-block'>(Please answer 10 from the following security questions.)</small>
        </Modal.Title>
      </Modal.Header>

<form onSubmit={formSubmitHandler}>

<Modal.Body>
<blockquote >
<span className='text-bold'>Very Important:</span> Due to Florida State Government policies, System must verify your active presence during the session! <span className='text-danger'>Incorrect answer of the question after 3 attempts will consider it <b>ABSENCE</b> in the class.</span> Currently, you have <span className='text-bold text-success'>2 remaining attempts</span>
</blockquote>

{/* {
  [1,2,3,4,5,6,7,8,9,10].map((queItem)=>{ */}
    {/* return ( */}
      <div className="card p-2 mb-3">
          <div class="row">
            <div className='col-sm-6'>
              <small className='mb-2 d-block required-astrick'>Security Question 1</small>
              <select id="securityQuestionOneId" required  className='p-w-100 m p-2 form-control' name="securityQuestionOneId" value={data.securityQuestionOneId} 
              onChange={(e) => {
                
                handleChange(e,'question')
                // e.persist()
                // setQuestions((prevQuestions) => prevQuestions.filter(ques => ques.id != e.target.value))
              }}
              >
              <option value="">Select Question</option>
              {
                questions.map((item, index) => {
                  if(selectedOptions.length  == 0 || data.securityQuestionOneId == item.id ||  (item.id && selectedOptions.length != 0 && selectedOptions.indexOf(item.id) == -1)){
                    return ( 
                    <option key={index} value={item.id}>
                      {item.question}
                    </option>
                    )
                  }
                })
              }
              </select>
            </div>
            <div className='col-sm-6'>
            <input type="text" placeholder='Your Answer...' className='p-w-100 mt-2 form-control' required  name="securityQuestionOneAns" value={data.securityQuestionOneAns} onChange={(e)=> handleChange(e,'answer')}/>
            </div>
          </div>
         </div> 
         <div className="card p-2 mb-3">
          <div class="row">
            <div className='col-sm-6'>
              <small className='mb-2 d-block required-astrick'>Security Question 2</small>
              <select  className='p-w-100 m p-2 form-control' name="securityQuestionTwoId" required value={data.securityQuestionTwoId} onChange={(e) => {
                
                handleChange(e,'question') 
              }}>
              <option value="">Select Question</option>
              {
                questions.map((item, index) => {
                  if(selectedOptions.length  == 0 || data.securityQuestionTwoId == item.id ||  (item.id && selectedOptions.length != 0 && selectedOptions.indexOf(item.id) == -1)){                    
                    return ( 
                    <option key={index} value={item.id}>
                      {item.question}
                    </option>
                    )
                  }
                })
              }
              </select>
            </div>
            <div className='col-sm-6'>
            <input type="text" placeholder='Your Answer...' className='p-w-100 mt-2 form-control' name='securityQuestionTwoAns' required value={data.securityQuestionTwoAns} onChange={(e)=> handleChange(e,'answer')}/>
            </div>
          </div>
         </div> 
         <div className="card p-2 mb-3">
          <div class="row">
            <div className='col-sm-6'>
              <small className='mb-2 d-block required-astrick'>Security Question 3</small>
              <select  className='p-w-100 m p-2 form-control' required name="securityQuestionThreeId" value={data.securityQuestionThreeId} onChange={(e) => {
                console.log(e.target.value)
                
                handleChange(e,'question')
              }}>
              <option value="">Select Question</option>
              {
                questions.map((item, index) => {
                  if(selectedOptions.length  == 0 || data.securityQuestionThreeId == item.id ||  (item.id && selectedOptions.length != 0 && selectedOptions.indexOf(item.id) == -1)){                    
                    return ( 
                    <option key={index} value={item.id}>
                      {item.question}
                    </option>
                    )
                  }
                })
              }
              </select>
            </div>
            <div className='col-sm-6'>
            <input type="text" placeholder='Your Answer...' className='p-w-100 mt-2 form-control' required name="securityQuestionThreeAns" value={data.securityQuestionThreeAns} onChange={(e)=> handleChange(e,'answer')}/>
            </div>
          </div>
         </div> 
         <div className="card p-2 mb-3">
          <div class="row">
            <div className='col-sm-6'>
              <small className='mb-2 d-block required-astrick'>Security Question 4</small>
              <select  className='p-w-100 m p-2 form-control' required name="securityQuestionFourId" value={data.securityQuestionFourId} onChange={(e) => {
                console.log(e.target.value)
                
                handleChange(e,'question') 
              }}>
              <option value="">Select Question</option>
              {
                questions.map((item, index) => {
                  if(selectedOptions.length  == 0 || data.securityQuestionFourId == item.id ||  (item.id && selectedOptions.length != 0 && selectedOptions.indexOf(item.id) == -1)){                    
                    return ( 
                    <option key={index} value={item.id}>
                      {item.question}
                    </option>
                    )
                  }
                })
              }
              </select>
            </div>
            <div className='col-sm-6'>
            <input type="text" placeholder='Your Answer...' className='p-w-100 mt-2 form-control' required name="securityQuestionFourAns" value={data.securityQuestionFourAns} onChange={(e)=> handleChange(e,'answer')}/>
            </div>
          </div>
         </div> 
         <div className="card p-2 mb-3">
          <div class="row">
            <div className='col-sm-6'>
              <small className='mb-2 d-block required-astrick'>Security Question 5</small>
              <select  className='p-w-100 m p-2 form-control' required name="securityQuestionFiveId" value={data.securityQuestionFiveId} onChange={(e) => {
                console.log(e.target.value)
                handleChange(e,'question') 
                 
              }}>
              <option value="">Select Question</option>
              {
                questions.map((item, index) => {
                  if(selectedOptions.length  == 0 || data.securityQuestionFiveId == item.id ||  (item.id && selectedOptions.length != 0 && selectedOptions.indexOf(item.id) == -1)){                    
                    return ( 
                    <option key={index} value={item.id}>
                      {item.question}
                    </option>
                    )
                  }
                })
              }
              </select>
            </div>
            <div className='col-sm-6'>
            <input type="text" placeholder='Your Answer...' className='p-w-100 mt-2 form-control' required name="securityQuestionFiveAns" value={data.securityQuestionFiveAns} onChange={(e)=> handleChange(e,'answer')}/>
            </div>
          </div>
         </div> 
         <div className="card p-2 mb-3">
          <div class="row">
            <div className='col-sm-6'>
              <small className='mb-2 d-block required-astrick'>Security Question 6</small>
              <select  className='p-w-100 m p-2 form-control' required name="securityQuestionSixId" value={data.securityQuestionSixId} onChange={(e) => {
                console.log(e.target.value)
                handleChange(e,'question')
                
              }}>
              <option value="">Select Question</option>
              {
                questions.map((item, index) => {
                  if(selectedOptions.length  == 0 || data.securityQuestionSixId == item.id ||  (item.id && selectedOptions.length != 0 && selectedOptions.indexOf(item.id) == -1)){                    
                    return ( 
                    <option key={index} value={item.id}>
                      {item.question}
                    </option>
                    )
                  }
                })
              }
              </select>
            </div>
            <div className='col-sm-6'>
            <input type="text" placeholder='Your Answer...' className='p-w-100 mt-2 form-control' required name="securityQuestionSixAns" value={data.securityQuestionSixAns} onChange={(e)=> handleChange(e,'answer')}/>
            </div>
          </div>
         </div> 
         <div className="card p-2 mb-3">
          <div class="row">
            <div className='col-sm-6'>
              <small className='mb-2 d-block required-astrick'>Security Question 7</small>
              <select  className='p-w-100 m p-2 form-control' required name="securityQuestionSevenId" value={data.securityQuestionSevenId} onChange={(e) => {
                console.log(e.target.value)
                handleChange(e,'question')
                
              }}>
              <option value="">Select Question</option>
              {
                questions.map((item, index) => {
                  if(selectedOptions.length  == 0 || data.securityQuestionSevenId == item.id ||  (item.id && selectedOptions.length != 0 && selectedOptions.indexOf(item.id) == -1)){                    
                    return ( 
                    <option key={index} value={item.id}>
                      {item.question}
                    </option>
                    )
                  }
                })
              }
              </select>
            </div>
            <div className='col-sm-6'>
            <input type="text" placeholder='Your Answer...' className='p-w-100 mt-2 form-control' required name="securityQuestionSevenAns" value={data.securityQuestionSevenAns} onChange={(e)=> handleChange(e,'answer')}/>
            </div>
          </div>
         </div> 
         <div className="card p-2 mb-3">
          <div class="row">
            <div className='col-sm-6'>
              <small className='mb-2 d-block required-astrick'>Security Question 8</small>
              <select  className='p-w-100 m p-2 form-control' required name="securityQuestionEightId" value={data.securityQuestionEightId} onChange={(e) => {
                console.log(e.target.value)
                handleChange(e,'question')
                
              }}>
              <option value="">Select Question</option>
              {
                questions.map((item, index) => {
                  if(selectedOptions.length  == 0 || data.securityQuestionEightId == item.id ||  (item.id && selectedOptions.length != 0 && selectedOptions.indexOf(item.id) == -1)){                    
                    return ( 
                    <option key={index} value={item.id}>
                      {item.question}
                    </option>
                    )
                  }
                })
              }
              </select>
            </div>
            <div className='col-sm-6'>
            <input type="text" placeholder='Your Answer...' className='p-w-100 mt-2 form-control' required name="securityQuestionEightAns" value={data.securityQuestionEightAns} onChange={(e)=> handleChange(e,'answer')}/>
            </div>
          </div>
         </div> 
         <div className="card p-2 mb-3">
          <div class="row">
            <div className='col-sm-6'>
              <small className='mb-2 d-block required-astrick'>Security Question 9</small>
              <select  className='p-w-100 m p-2 form-control' required name="securityQuestionNineId" value={data.securityQuestionNineId} onChange={(e) => {
                console.log(e.target.value)
                handleChange(e,'question')
                
              }}>
              <option value="">Select Question</option>
              {
                questions.map((item, index) => {
                  if(selectedOptions.length  == 0 || data.securityQuestionNineId == item.id ||  (item.id && selectedOptions.length != 0 && selectedOptions.indexOf(item.id) == -1)){                    
                    return ( 
                    <option key={index} value={item.id}>
                      {item.question}
                    </option>
                    )
                  }
                })
              }
              </select>
            </div>
            <div className='col-sm-6'>
            <input type="text" placeholder='Your Answer...' className='p-w-100 mt-2 form-control' required name="securityQuestionNineAns" value={data.securityQuestionNineAns} onChange={(e)=> handleChange(e,'answer')}/>
            </div>
          </div>
         </div> 
         <div className="card p-2 mb-3">
          <div class="row">
            <div className='col-sm-6'>
              <small className='mb-2 d-block required-astrick'>Security Question 10</small>
              <select  className='p-w-100 m p-2 form-control' required name="securityQuestionTenId" value={data.securityQuestionTenId} onChange={(e) => {
                console.log(e.target.value)
                handleChange(e,'question')
                
              }}>
              <option value="">Select Question</option>
              {
                questions.map((item, index) => {
                  if(selectedOptions.length  == 0 || data.securityQuestionTenId == item.id ||  (item.id && selectedOptions.length != 0 && selectedOptions.indexOf(item.id) == -1)){                    
                    return ( 
                    <option key={index} value={item.id}>
                      {item.question}
                    </option>
                    )
                  }
                })
              }
              </select>
            </div>
            <div className='col-sm-6'>
            <input type="text" placeholder='Your Answer...' className='p-w-100 mt-2 form-control' required name="securityQuestionTenAns" value={data.securityQuestionTenAns} onChange={(e)=> handleChange(e,'answer')}/>
            </div>
          </div>
         </div> 
    {/* ) */}
  {/* })
}   */}
  

</Modal.Body>
<div className='modal-footer my-btns d-flex align-content-center '>
{/* <button  type="submit" className="btn" onClick={handleLogout}
>Logout</button> */}
<button  type="submit" className="btn active ml-2" 
  >Submit My Answers</button>
</div>

</form>
    </Modal>
  );
}

export default QAModal;