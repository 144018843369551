import React, { useState, useContext, useEffect } from "react";
import "./view-courses-resource.css";
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Header from '../../../common/header/header'
import Pagination from 'react-bootstrap/Pagination'
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { CommonService } from '../../../service/common.service';
import { useAlert } from "react-alert";
import NotFoundIMg from '../../../assets/question-mark.png'
import LoadingOverlay from 'react-loading-overlay';

const viewCourseResource = ({match}) => {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dltShow, setdltShow] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [dltId, setdltId] = useState();
    const [courseData, setCourseData] = useState();
    const [userData, setUserData] = useState();
    const  courseId = match.params?.id;
    const delethandleClose = () => setdltShow(false);
    
    const deleteHandleShow = async (datal) => {
        setdltShow(true);
        setdltId(datal);
    }
    
    
    useEffect(() => {
        setLoading(false);
        getResources();
        getCourseData()
        if (localStorage.getItem('user')) {
            setUserData(JSON.parse(localStorage.getItem('user')))
        }
    }, []);

    const getCourseData = () => {
        CommonService.httpGetService(`admin-api/courses?id=${courseId}`)
        .then(data => {
             
            if(data.length > 0) {
                setCourseData(data[0])
                setLoading(false);
            }
        },error =>{
            alert.error(error)
            setLoading(false);
        })
    }


    const getResources = async () => {
        try {
            setLoading(true);
            CommonService.httpGetService(`admin-api/course-resource?course_id=${courseId}`)
            .then(data => {
                setData(data);
                setFilteredData(data);
                setLoading(false);
            }, error => {
                alert.error(error)
                setLoading(false);
            })
        }
        catch {
            alert.error("Something went wrong")
        }
    }

    const filterData = (value) => {
            let tempArr = Object.assign([], data)
            if (value.length >= 3) {
                let upperCaseFilter = value.toUpperCase()
                let filterDataArr = tempArr.filter(
                    option => {
                        if (
                            (
                                option.name != undefined &&
                                option.name != null &&
                                (   
                                    option.name.toUpperCase().toString().includes(upperCaseFilter)
                                )
                            )
                        ) {
                            return option
                        }
                    }
                )
                setFilteredData(filterDataArr)
            } else {
                setFilteredData(data)
            }
        
    }



    const deleteCourses = (id) => {
        setLoading(true);
        CommonService.httpDeleteService(`admin-api/course-resource/${dltId}`)
            .then(users => {
                setdltShow(false);
                alert.success("Cources has been deleted successfully");
                getResources()
                setLoading(false);
            }, error => {
                setdltShow(false);
                alert.error(error)
                setLoading(false);
            })

    }





    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
            <Header></Header>

            <Container fluid>
                <div className="heading">
                    <div className="lheading">
                        <span className="headTitle"> Resources </span>
                        <span className="searchItem"> {courseData && courseData.name ? courseData.name : ''}</span>
                    </div>
                    <div className="rheading">
                       { userData && userData.role != 'STUDENT' ? 
                            <Link to={`/courses-resource/create/${courseId}`}> <button type="button" className="addNew">+ Add New Resource</button></Link> : ''
                       }
                    </div>
                </div>
                <div className="subheading">
                    <div className="lsubhead">
                    <input type="text" onChange={e => filterData(e.target.value)} className="tableSearch" placeholder="Search here..." />
                    </div>

                </div>



                {

                    filteredData && filteredData.length > 0 ? (

                        <Table responsive>
                            <thead>
                                <tr className="tableHead">
                                    <th style={{maxWidth:"50px"}}>#</th>
                                    <th>Name</th>
                                    <th ><span className="pr-3">Action</span></th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredData.map((datal, index) => (

                                        <tr key={index}>
                                            <td style={{maxWidth:"50px"}}>{index + 1}</td>
                                            {/* <td>{datal.id}</td> */}
                                            <td>{datal.name}</td>
                                            <td>
                                            { datal.aws_url ? 
                                                 <button type="button" onClick={() => window.open(datal.aws_url,'_blank') } className='addStudent mr-2'>Download</button> : ''
                                                }
                                                {/* <Link to={`/courses-resource/update/${datal.id}`} className=""><i className="fas fa-pencil-alt" ></i></Link> */}
                                                {userData && userData.role !='STUDENT' ?  <i className="fas fa-trash-alt" onClick={() => {
                                                    deleteHandleShow(datal.id)
                                                }} ></i> : ''}
                                                </td>
                                           
                                        </tr>


                                    ))

                                }



                            </tbody>
                        </Table>
                    ) : (
                            <div className="nofoundRow">
                                <img className="nofoundimg" src={NotFoundIMg} />
                                <span className="nofoundimgCaption">Sorry! Courses are not available </span>
                            </div>
                        )

                }
                <Modal show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to edit this course?
       </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
       </Button>
                        <Button variant="primary">Yes</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={dltShow}
                    onHide={delethandleClose}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to delete this resource?
       </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={delethandleClose}>
                            No
       </Button>
                        <Button variant="primary" onClick={() => deleteCourses(dltId)}> Yes</Button>
                    </Modal.Footer>
                </Modal>


            </Container>
        </Container>
        </LoadingOverlay>



    );
};

export default viewCourseResource;








