import React, { useState, useContext, useEffect, useReducer } from "react";
// import "./pass-result.css";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Firework from '../../../assets/blade.png';
import Passtest from '../../../assets/assignment.png';

const FailResult = () => {

	return (
		<Container className="p-0" fluid>
            <div className="result-screen">
                {/* <div className="border-bg"> */}
                <div className="u-side" style={{backgroundColor: "#ff03076b"}}>
                <div className="image-col">
                <img className="sparkle upstar" src={Firework} />
                <img className="testbook" src={Passtest} />
                {/* <span className="hollow-circle"><span className="test-score"><span className="perct">88% <br/> Score</span></span></span> */}
                <img className="sparkle downstar" src={Firework} />

                </div>
                <div className="caption-col">
                 Test Score : 39%
                 
                </div>
                </div>
                <div className="d-side">
                <span className="congrats">Oops, you didn't passed!</span>
                <span className="congrats_tagline">you can retry this test now or try again later</span>
                <div className="divider-row">
                    <div className="l-divider">
                    <span className="test-stat">1 of 8</span>
                    <span className="stat-caption">Certification Progress</span>
                    </div>
                    <div className="m-divider"></div>
                    <div className="r-divider">
                    <span className="course-stat">25%</span>
                    <span className="stat-caption">Skill Completion</span>
                    </div>
                </div>
                <div className="dwn-btn">
                <Button className="" variant="danger">Retry Test</Button>
                </div>
                <div className="dwn-btn">
                <span className="congrats_tagline back-btn">Go Back</span>
                </div>

                
                </div>
                {/* </div> */}
            </div>
        </Container>
	);
};

export default FailResult;
