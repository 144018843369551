import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import { Device } from 'mediasoup-client';
import TeacherIcon from '../../../assets/security-officer.png'
const StudentKurento = (props) => {
    // const socket = io('http://localhost:8991');
    var socket;
    const [classroomId, setClassroomId] = useState('');
    const [isVideo, setIsVideo] = useState(false);

    const remoteVideoRef = useRef(null);
    let device,deviceLoaded, consumerTransport, consumer,remoteStream;
    useEffect(() => {
        handleJoinClassroom()
        // // Listen for producer's stream stop event
        socket.on('videoStreamStopped', () => {
            resetStreamData()
        });
        socket.on('videoStreamStarted', () => {
            handleJoinClassroom()
        });
        
        socket.on('disconnect', () => {
            resetStreamData()
        });
        if(props.enableVideoCall){
            
        }
        return () => {
           
            socket && socket.close()
            resetStreamData()
        }
        
    }, [])

    useEffect(() => {
        if(props.streamStopped){
            setIsVideo(true)
        }
    },[props.streamStopped])

    const resetStreamData = () => {
        consumerTransport && consumerTransport.close()
        if (remoteVideoRef && remoteVideoRef.current && remoteVideoRef.current.srcObject) {
            remoteVideoRef.current.srcObject.getTracks().forEach((track) => track.stop()); // Stop all tracks
            remoteVideoRef.current.srcObject = null; // Clear the video element
            device = null;
            consumerTransport = null;
            consumer = null;
            remoteStream = null;
            setIsVideo(false)
        }
    }
  
    const handleJoinClassroom = () => {
        if (!props.roomName) return alert("Please enter a Classroom ID");
        if(!socket){
            const socketConfig = {
                transports : ['websocket'],
              query: {
                source: 'student', 
                email: props.userData?.email,
                userId: props.userData?.id
              },
              reconnection: true,      // Enable reconnections
              reconnectionDelay: 50000, // Delay between retries (in milliseconds)
              reconnectionDelayMax: 50000, // Maximum delay between retries
              
            }
            // socket = io('http://localhost:8991',{
            //   ...socketConfig
            // });
            socket = io('https://app.flsecurityschool.com', {
                path: '/camera/socket.io/',
                ...socketConfig
            });
    
          }

        socket.emit('joinClassroom', { classroomId: props.roomName, isProducer: false }, async (response) => {
            if (response.status === 'ok') {
                initializeConsumer();
            } else {
                alert(`Failed to join classroom: ${response.error}`);
            }
        });
    };

    const initializeConsumer = async (reload = false) => {
        device = null;
        consumerTransport = null;
        consumer = null;
        remoteStream = null;
        deviceLoaded = false
        if (consumerTransport) {
            console.log('Closing existing consumer transport...');
            await consumerTransport.close();
            consumerTransport = null;
            console.log('Existing consumer transport closed.');
        }

        if (!device || reload) {
            console.log('Initializing new device for media consumption...');
            device = new Device();
        }

        if (!deviceLoaded) {
            console.log('Requesting router RTP capabilities from server...');
            socket.emit('getRouterRtpCapabilities', async (rtpCapabilities) => {
                if (!rtpCapabilities) {
                    console.error('Failed to retrieve router RTP capabilities');
                    return;
                }
                console.log('Received router RTP capabilities:', rtpCapabilities);

                try {
                    await device.load({ routerRtpCapabilities: rtpCapabilities });
                    deviceLoaded = true
                    console.log('Device loaded with router RTP capabilities.');
                } catch (error) {
                    console.error('Failed to load device:', error);
                    return;
                }

                createConsumerTransport();
            });
        } else {
            createConsumerTransport();
        }
    };

    const createConsumerTransport = () => {
        console.log(`Requesting consumer transport for classroom ${props.roomName}...`);
        socket.emit('createConsumerTransport', { classroomId : props.roomName }, async (transportInfo) => {
            if (!transportInfo) {
                console.error('Failed to create consumer transport: No transport info received');
                return;
            }
            console.log('Received consumer transport info from server:', transportInfo);

            try {
                consumerTransport = device.createRecvTransport({
                    id: transportInfo.id,
                    iceParameters: transportInfo.iceParameters,
                    iceCandidates: transportInfo.iceCandidates,
                    dtlsParameters: transportInfo.dtlsParameters,
                });

                consumerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
                    try {
                        console.log('Connecting consumer transport...');
                        await socket.emit('connectConsumerTransport', { classroomId : props.roomName, transportId: consumerTransport.id, dtlsParameters });
                        callback();
                        console.log('Consumer transport connected.');
                    } catch (error) {
                        errback(error);
                    }
                });
                consumerTransport.on('disconnect', async ({ dtlsParameters }, callback, errback) => {
                    try {
                       console.log('Stream disconnected')
                       setIsVideo(false)
                    } catch (error) {
                        setIsVideo(false)
                        errback(error);
                    }
                });

                console.log('Consumer transport successfully created.', consumerTransport);
                consumeMedia();
            } catch (error) {
                console.error('Error creating or connecting consumer transport:', error);
            }
        });
    };

    const consumeMedia = () => {
        console.log(`Requesting to consume media stream for classroom ${props.roomName}...`);
        socket.emit('consume', { classroomId : props.roomName, rtpCapabilities: device.rtpCapabilities }, async (consumerInfo) => {
            if (!consumerInfo) {
                console.error('Failed to consume media: No consumer info received');
                setIsVideo(false)
                return;
            }
            if(consumerInfo && consumerInfo.error){
                console.error('Failed to consume media due to :',consumerInfo.error);
                setIsVideo(false)
                return;
            }
            console.log('Received consumer info from server:', consumerInfo);

            try {
                consumer = await consumerTransport.consume({
                    id: consumerInfo.id,
                    producerId: consumerInfo.producerId,
                    kind: consumerInfo.kind,
                    rtpParameters: consumerInfo.rtpParameters,
                });
                console.log('Consumer created:', consumer);

                remoteStream = new MediaStream();
                remoteStream.addTrack(consumer.track);
                setIsVideo(true)
                console.log('Remote stream created and track added.');

                remoteVideoRef.current.srcObject = remoteStream;
                remoteStream.getTracks().forEach((track) => {
                    track.onended = () => {
                      console.log('Stream stopped');
                      setIsVideo(false)
                    };
                  });
                console.log('Remote video stream set on video element.');
            } catch (error) {
                setIsVideo(false)
                console.error('Error consuming media:', error);
            }
        });
    };
    return (
        <>
            {/* <div id="meetingRoom">
                <video id="broadcast" autoPlay></video>
                <p id="presenterName"></p>
                <ul id="viewers"></ul>
            </div> */}

            <video style={{ width: "100%" }}
                className={`${isVideo ? '' : 'd-none'}`}
                id="broadcast" ref={remoteVideoRef} muted autoPlay playsInline></video>
            <span className="videoReload"  onClick={()=> {
              handleJoinClassroom()
          }}>
              RELOAD
          </span>
            <div className={`'usr-avatar' ${isVideo ? 'd-none' : ''}`} style={{ padding: "10px", margin: "0 auto", display: "table" }}>
                <img src={TeacherIcon} />
            </div>
            {
                props.teacherData.length > 0 ? (
                    <span style={{ margin: "0 auto", display: "table", fontSize: "20px", fontWeight: "bold" }}>{props.teacherData[0].first_name}</span>
                ) : null
            }


        </>
    )
};

export default StudentKurento;