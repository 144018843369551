import React, { useState, useContext, useEffect } from "react";
import BigLogo from '../../assets/fls/logo-large-with-slogan.png'
import UserOne from '../../assets/userone.jpg'
import Award from '../../assets/5-star-excellence-w300-o.png'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import { Link } from "react-router-dom";
import "./header.css";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { history } from '../../_helpers/history'
import { slide as Menu } from 'react-burger-menu'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import { CommonService } from "../../service/common.service";

const Header = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [id, setId] = useState();
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [homeUrl, setHomeUrl] = useState('');
    const [shortHandName, setShortHandName] = useState(null);
    const [profileColor, setProfileColor] = useState(null);
    const [currentVersion, setCurrentVersion] = useState(CommonService.getAppVersion());

    const handleLogoutClick = () => {
        localStorage.removeItem('user');
        history.push('/');
    }

    const showSettings = (event) => {
        event.preventDefault();
    }

    var isMenuOpen = function(state) {
        return state.isOpen;
      };

    // function setUser(username , password) {

    //     let obj = {
    //         "username": username,
    //         "password": password
    //     }
    //     CommonService.httpPostService('user/login', obj)
    //         .then(users => {
    //              
    //             localStorage.setItem('user', JSON.stringify(users))
    //             console.log(user);
    //         }, error => {
    //             // alert.error(error)
    //         })    

    // }
    useEffect(() => {
        // let myData = {}

        // localStorage.setItem('items', JSON.stringify(itemsArray))
        // const data = JSON.parse(localStorage.getItem('user'))
        // console.log(data);
        // const data = localStorage.getItem('user')
        if (localStorage.getItem('user') != null && localStorage.getItem('user') != undefined) {
            var data =  JSON.parse(localStorage.getItem('user'))
            setId(data.id)
            setName(data.name ? data.name : 'User')
            setRole(data.role ? data.role : '')
            if (data.role == 'ADMIN') {
                setHomeUrl('/view-course')
            }else{
                setHomeUrl('/courses')
            }
            getShortHandName(data.name)
        }

  
    }, []);

    const getShortHandName = (fullName) =>{
        if (fullName != undefined) {
			let nameArr = fullName.split(" ")
			let shortName = ""
			if (nameArr.length == 1) {
				shortName = nameArr[0].substring(0, 2)
			} else if (nameArr.length > 1)
				for (const key in nameArr) {
					if (nameArr.hasOwnProperty(key)) {
						if (Number(key) <= 1) {
							const element = nameArr[key];
							shortName += element.substring(0, 1)
						}
					}
                }
            setShortHandName(shortName)
            setProfileColor(Math.floor(Math.random()*16777215).toString(16))
		}
    }



    return (

        <Navbar expand="lg" expand="sm" expand="md" className="rectHead">
                <div className="container-fluid">
                <div className="leftSide">
                {
                    (role.trim() == 'ADMIN') ? (
                        <span className="burgerIcon">  <i className="iconStyle fa fa-bars"></i></span>
                    ) : null
                }
                {
                    (role.trim() == 'ADMIN') ? (
                        <Menu width={ '280px' } onStateChange={ isMenuOpen }>
                            <a id="top" className="menu-item styleCloseBtn" > <i className="fas fa-times"></i> </a>
                            <Link id="coursesNav" to="/view-course" className="header-menu-item">Courses</Link>
                            <Link id="classesNav" to="/view-class" className="header-menu-item"> Classes</Link>
                            <Link id="usersNav" to="/view-user" className="header-menu-item">Users</Link>
                            <Link id="testsNav" to="/view-test" className="header-menu-item">Test</Link>
                            <Link id="questionNav" to="/view-question" className="header-menu-item">Question</Link>
                        </Menu>
                    ) : null
                }
               
                <div className="companyLogo">
                    <img className="logoheader" src={BigLogo} />
                </div>
                <div className="divider d-lg-block d-md-block d-none">  </div>
                <div className="companyName d-lg-block d-md-block d-none">  <Link id="companyNameHeader" to={homeUrl}>Florida Security School</Link> </div>
            </div>
            <div className="rightSide">
               
                <div className="actionRow d-lg-block d-md-block">
                    <span className="contact-no">Version : {currentVersion}</span> 
                </div>
                <div className="divider d-lg-block d-md-block d-none">  </div>
                <div className="actionRow d-lg-block d-md-block d-none">
                    <span className="actionIcon"><i className="iconStyle fas fa-phone-alt"></i> <span className="contact-no">855-533-5233</span> </span> 
                    <img className="awardImg" src={Award} />
                    <span className="actionIcon"> <Link id="companyNameHeader" to={homeUrl}><i className="iconStyle fas fa-home"></i></Link></span>
                </div>
                <div className="divider d-lg-block d-md-block d-none">  </div>
                <span className="username">  {name} <br />
                <span style={{  fontSize: "11px"}}>{role}</span>
                </span>
                <Dropdown>
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                        <div className="usr-avatar" style={{  backgroundColor: `#${profileColor}`}}>
                        <span className="usr-name-abv" >{shortHandName}</span>
                        </div>
                        {/* <img className="userImgHeader" src={profilePicUrl ? profilePicUrl : UserOne} /> */}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="useroptionBox">
                        <Dropdown.Item href={`/profile/${id}`} >My Profile</Dropdown.Item>
                        {
                            role != 'ADMIN' ? <Dropdown.Item href="/courses">  My Courses</Dropdown.Item> : ''
                        }
                        {
                            role == 'STUDENT' ? (
                                <>
                                {/* <Dropdown.Item href="/student-certificate">  My Certificates</Dropdown.Item> */}
                                {/* <Dropdown.Item href="/student-test-activity">  My Tests</Dropdown.Item> */}
                                </>
                            ) : ''
                        }
                        
                        <Dropdown.Item onClick={handleShow}> Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Modal show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to logout?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="primary" onClick={handleLogoutClick}> Yes</Button>
                    </Modal.Footer>
                </Modal>

            </div>

                </div>
        {/* </div> */}
    {/* </Navbar.Brand> */}
  {/* </Container> */}
</Navbar>


    )

}

export default Header