import React, { useState, useContext, useEffect } from "react";
// import "./create-lecture.css";
import Container from 'react-bootstrap/Container';
import Header from '../../../common/header/header'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import { CommonService } from '../../../service/common.service';
import { history } from '../../../_helpers/history'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal'
import moment from "moment";

const updateUser = ({ match }) => {


    const [name, setName] = useState('')
    const [dob, setDob] = useState('')
    const [role, setRole] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [id, setId] = useState('');
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const alert = useAlert();
    const paramId = match.params?.id;
    const [dltShow, setdltShow] = useState(false);
    const delethandleClose = () => setdltShow(false);
    const deleteHandleShow = async () => {
        setdltShow(true);
    }

    useEffect(() => {
        setLoading(true);
        if (paramId != null) {
            setId(paramId)
            CommonService.httpGetService(`user/user?id=${paramId}`)
                .then(data => {
                    if (data.length > 0) {
                        let name = data[0].full_name
                        let email = data[0].primary_email
                        let dob = data[0].birth_date
                        let mobile_no = data[0].mobile_no
                        let role = data[0].roles[0] ? data[0].roles[0].name : 'STUDENT'

                        setName(name)
                        setDob(moment(dob).format('YYYY-MM-DD'))
                        setEmail(email)
                        setRole(role)
                        setPhoneNumber(mobile_no)
                        setLoading(false);
                    }
                }, error => {
                    alert.error(error)
                    setLoading(false);
                })
        }
    }, []);

    const handleSubmit = () => {
        setLoading(true);
        const form = document.getElementById("CreateLectureForm");
        // const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
            handleCreateUserBtn();
        }
    };

    const reSendVerification = () => {
        setLoading(true);
        const form = document.getElementById("CreateLectureForm");
        // const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
            try {

                let obj = {
                    "user_id": id,
                    "full_name": name,
                    "primary_email": email,
                    "roles": role,
                    "mobile_no": phoneNumber,
                    "birth_date": dob
                }
                CommonService.httpPostService(`admin-api/user-contract/re-send`, obj)
                    .then(res => {
                        setLoading(false);
                        alert.success(`Verification mail sent.`);
                    }, error => {
                        setLoading(false);
                        alert.error(error)
                    })


            } catch (error) {
                setLoading(false);
                alert.error("Failed to send verification mail.");
                // alert('Something went wrong with login')
            }
        }
    };

    const handleReset = () => {
        setName('')
        setEmail('')
        setPhoneNumber('')
        // setRole(role)
        setValidated(false);
        setdltShow(false);

    };

    function handleCreateUserBtn() {
        try {
            let obj = {
                "id": id,
                "full_name": name,
                "primary_email": email,
                "roles": role,
                "mobile_no": phoneNumber,
                "birth_date": dob
            }
            CommonService.httpPutService(`user/user/${id}`, obj)
                .then(res => {
                    // let lectId = res.data[0].id;
                    // setLectureId(lectId)
                    setLoading(false);
                    alert.success(`User updated successfully.`);
                }, error => {
                    setLoading(false);
                    alert.error(error)
                })


        } catch (error) {
            setLoading(false);
            alert.error("Please fill the required details!");
            // alert('Something went wrong with login')
        }
    }



    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading...'
            className="loaderStyle"
        >
            <Container className="containerBox" fluid>
                <Header></Header>
                <Container fluid>
                    <div className="heading">
                        <div className="lheading">
                            <span className="headTitle">Update User </span>
                        </div>
                        <div className="rheading">
                            <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>
                        </div>
                    </div>

                    <Form className="CreateEntryForm" id="CreateLectureForm" noValidate validated={validated}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridname">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="" value={name} onChange={e => setName(e.target.value)} pattern="[a-zA-Z0-9\s]+" >
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please fill your name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            
                            <Form.Group as={Col} controlId="formGridname">
                                <Form.Label>DOB</Form.Label>
                                <Form.Control type="date" placeholder="" value={dob} onChange={e => setDob(e.target.value)} required>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please fill your Date of birth.
                                </Form.Control.Feedback>
                            </Form.Group>
                            

                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridName">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="text" value={email} onChange={e => setEmail(e.target.value)} pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder="" >
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please fill your email address.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={6} controlId="formGridName">
                                <Form.Label>Phone Number *</Form.Label>
                                <Form.Control type="text" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} pattern="[0-9]{10,10}" minLength="10" maxLength="10" placeholder="" required>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please fill your phone number.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={6} controlId="formGridCode">
                                <Form.Label>Role</Form.Label>
                                <Form.Control as="select" custom value={role} onChange={e => setRole(e.target.value)}  >
                                    <option value="TEACHER">Teacher</option>
                                    <option value="STUDENT">Student</option>
                                </Form.Control>
                            </Form.Group>

                        </Form.Row>

                        <div className="submitBtnRow">
                            <button type="button" onClick={() => handleSubmit()} className="saveBtn"> Submit </button>
                            <button type="button" onClick={() => deleteHandleShow()} className="resetBtn"> Reset </button>
                            <button type="button" onClick={() => reSendVerification()} className="saveBtn"> Update &amp; Re-send Verification </button>

                        </div>
                    </Form>
                    <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to reset this form?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
                            </Button>
                            <Button variant="primary" onClick={() => handleReset()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>

                </Container>
            </Container>
        </LoadingOverlay>
    );
};

export default updateUser;


