import React, { useState, useContext , useEffect } from "react";
import "./student-contract.css";
import Container from 'react-bootstrap/Container';
import Header from '../../common/header/header'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import {CommonService} from '../../service/common.service';
import {history} from '../../_helpers/history'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal'
import StudentContractComponent from '../../components/StudentContractComponent';
import * as moment from 'moment'
var jwt = require('jsonwebtoken');
import queryString from 'query-string';
const StudentContract = ({match}) => {
    const [name, setName] = useState('')
    const [gender, setGender] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [mobileNo, setMobileNo] = useState('')
    const [zip, setZip] = useState('')

    const [countryData, setCountryData] = useState([])
    const [countryId, setCountryId] = useState('')
    const [countryName, setCountryName] = useState('')
    const [stateData, setStateData] = useState([])
    const [stateId, setStateId] = useState('')
    const [stateName, setStateName] = useState('')
    const [cityData, setCityData] = useState([])
    const [cityId, setCityId] = useState('')
    const [cityName, setCityName] = useState('')
    
    // class Data
    const [classJoinDate, setclassJoinDate] = useState('')
    const [courseFee, setCourseFee] = useState('')
    const [teacherName, setTeacherName] = useState('')


    const [id, setId] = useState('');
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const alert = useAlert();
    const  paramId = match.params?.id;

    const queryParams = queryString.parse(location.search)
    const  paymentKey = match.params?.paymentKey;

    const [orderId, setOrderId] = useState('');
    const [classId, setClassId] = useState('');

    const [dltShow, setdltShow] = useState(false);
    const delethandleClose = () => setdltShow(false);
    const deleteHandleShow = async () => {
        setdltShow(true);
    }
    const [shortHandName, setShortHandName] = useState(null);
    const [profileColor, setProfileColor] = useState(null);


    const [showTnc, setShowTnc] = useState(false);
    const handleTncClose = () => setShowTnc(false);
    const handleTncShow = () => setShowTnc(true);

    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('user') ? true : false);
    useEffect(() => {
        setLoading(true);
        getCountry();
        if(paramId != null) {
            if (isLoggedIn) {
                let userData = JSON.parse(localStorage.user)
                setId(userData.id)
                getUserData(userData.id)
                getClassInfo(paramId)
            } 
            if (queryParams.paymentKey) {
                getStates(231)
                parseToken(queryParams.paymentKey)
            }
        }
    }, []);

    const parseToken = (token) => {
        let response = {}
        try {
            jwt.verify(token, process.env.PAYMENT_SECRET, async (err, result) => {
                if (err) {
                    response = {};
                } else {
                    response = result;
                    if (result.billing_info) {
                        console.log(result)
                        setName(result.billing_info.full_name)
                        setEmail(result.billing_info.primary_email)
                        setAddress(result.billing_info.address ? result.billing_info.address : '')
                        setMobileNo(result.billing_info.mobile_no)
                        setZip(result.billing_info.zip)
                        // setCountryId(country_id)
                        // let state = await getStates(231) // Country Code of united states.
                        // let stateName = getLocationName(state, state_id)
                        // setStateName(stateName)
                        // console.log('State Name', stateName);
                        // let city = await getCities(state_id)
                        // let cityName = getLocationName(city, city_id)
                        // console.log('City Name', cityName);
                        // setCityName(cityName)
                        // setStateId(state_id)
                        // setCityId(city_id)
                        setOrderId(result.order_id)
                        setClassId(result.class_id)
                        // setCourseId(result.courseId)
                        // setCourseName(result.course_name)
                        // setClassName(result.class_name)
                    }
                }
            })
            return response
        }
        catch (err) {
            response = {};
            return response
        }
    }

    const getUserData = (id) =>{
        CommonService.httpGetService(`user/user?id=${id}`)
        .then(async data => {
            if (data.length > 0) {
                let name = data[0].full_name
                let gender = data[0].gender
                getShortHandName(name)
                let email = data[0].primary_email
                let address = data[0].address_1
                let mobile_no = data[0].mobile_no
                let zip = data[0].zip
                let country_id = data[0]?.country_id
                let state_id= data[0]?.state_id
                let city_name= data[0].city_name

                setName(name)
                setGender(gender)
                setEmail(email)
                setAddress(address ? address : '')
                setMobileNo(mobile_no ? mobile_no : '')
                setZip(zip ? zip : '')
                setCountryId(country_id ? country_id : 231)
                let state =  await getStates(231) // Country Code of united states.
                let stateName = getLocationName(state,state_id)
                setStateName(stateName)
                console.log('State Name',stateName);
                setCityName(city_name ? city_name : '')
                setStateId(state_id ? state_id : '')
                
                // setStateId(state_id)
                setLoading(false);
                
            }
        },error =>{
            alert.error(error)
            setLoading(false);
        })
    }

    const getLocationName = (locationArr,id) =>{
       let foundLocation = locationArr.filter(option => {
           return option.location_id == id
       })
       if (foundLocation.length > 0) {
           return foundLocation[0].name
       }else{
          return ""
       }
    }

    const getClassInfo = () => {
        let userData = JSON.parse(localStorage.user)
        CommonService.httpGetService(`admin-api/class?id=${paramId}`)
            .then(async data => {
                if (data.length > 0) {
                    setclassJoinDate(data[0].start_date ? moment(data[0].start_date).format('DD/MM/YYYY') : '')
                    getCourseInfo(data[0].course_id)
                    getTeacherInfo(data[0].teacher_id)
                    setLoading(false);
                    
                }
            },error =>{
                alert.error(error)
                setLoading(false);
            })
    }

    const getCourseInfo = (id) => {
        CommonService.httpGetService(`admin-api/courses?id=${id}`)
            .then(async data => {
                if (data.length > 0) {
                    setCourseFee(data[0].fee)
                }
            },error =>{
                alert.error(error)
                setLoading(false);
            })
    }

    const getTeacherInfo = (id) => {
        CommonService.httpGetService(`user/user?id=${id}`)
            .then(async data => {
                if (data.length > 0) {
                    setTeacherName(data[0].full_name)
                }
            },error =>{
                alert.error(error)
                setLoading(false);
            })
    }

    const getShortHandName = (fullName) =>{
        if (fullName != undefined) {
			let nameArr = fullName.split(" ")
			let shortName = ""
			if (nameArr.length == 1) {
				shortName = nameArr[0].substring(0, 2)
			} else if (nameArr.length > 1)
				for (const key in nameArr) {
					if (nameArr.hasOwnProperty(key)) {
						if (Number(key) <= 1) {
							const element = nameArr[key];
							shortName += element.substring(0, 1)
						}
					}
				}
            setShortHandName(shortName)
            setProfileColor(Math.floor(Math.random()*16777215).toString(16))
		}
    }


    const handleSubmit = () => {
        setLoading(true);
        const form = document.getElementById("CreateLectureForm");
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
            handleCreateUserBtn();
        }
       
    };

    const handleReset = () => {
        setName('')
        // setEmail('')
        setAddress('')
        setMobileNo('')
        setZip('')
        setValidated(false);
        setdltShow(false);

    };

    const getCountry = () => {

        try {
            setLoading(true);
            CommonService.httpGetService(`admin-api/location/country`)
            .then(countryArr => {
                console.log(countryArr);
                setCountryData(countryArr)
                setCountryName(countryArr[0].name)
                setLoading(false)

    
            }, error => {
                alert.error(error)
                setLoading(false);
            })
        }
        catch {
            alert.error('Something went wrong')
            
        }
    }


   const getStates = (countryId) => {
    return new Promise(async (resolve, reject) => {
        try {
            setLoading(true);
            if (countryId) {
                CommonService.httpGetService(`admin-api/location/states?country_id=${countryId}`)
            .then(res => {
                console.log(res);
                if (res.length > 0 && res[0] != undefined) {
                    setStateId(res[0].location_id)
                }
                setStateData(res)
                setLoading(false)
                resolve(res)
            }, error => {
                alert.error(error)
                setLoading(false);
                resolve(null)
            })
            } else {
                setLoading(false);
                resolve(null)
            }
        }
        catch {
            alert.error('Something went wrong')
            resolve(null)
            
        }
    })
	}

    const getCities = (stateId) => {
        return new Promise(async (resolve, reject) => {
        try {
            setLoading(true);
            if (stateId) {
                CommonService.httpGetService(`admin-api/location/city?state_id=${stateId}`)
            .then(res => {
                console.log(res);
                if (res.length > 0 && res[0] != undefined) {
                    setCityId(res[0].location_id)
                }
                setCityData(res)
                setLoading(false)
                resolve(res)
            }, error => {
                alert.error(error)
                setLoading(false);
                resolve(null)
            })
            } else {
                setLoading(false);
                resolve(null)
            }
        }
        catch {
            alert.error('Something went wrong')
            resolve(null)
            
        }
    })
    }

    function handleCreateUserBtn () {
        try {
            let stateName = getLocationName(stateData,stateId)
            // let cityName = getLocationName(cityData, cityId)
            let obj = {
                "user_id": id,
                "full_name": name,
                "gender": gender,
                "address_1": address,
                "address_2": "",
                "address_3": "",
                "city_name": cityName,
                "state_name": stateName,
                "country": countryName,
                "zip": zip,
                "mobile_no": mobileNo,
                "primary_email": email,
                "class_id": paramId,
                "order_id" : orderId
            }
           debugger
            CommonService.httpPostService(`admin-api/user-contract`, obj)
            .then(res => {
                 
                console.log('Contract Submit Response : ',res)
                setLoading(false);
                history.push(`/courses`)
                // history.push(`/print-contract/${res.data.id}`)
               
                alert.success(`Agreement Signed`);
				},error =>{
                    setLoading(false);
					alert.error(error)
			})
           

        } catch (error) {
            setLoading(false);
            alert.error("Please fill the required details!");
        }
    }
    return (
        <LoadingOverlay
        active={loading == true ? true : false}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
            {isLoggedIn ? <Header></Header>    : ''}        
            <Container fluid>
                <div className="heading">
                    <div className="lheading">
                        <span className="headTitle">Contract Detail</span>
                    </div>
                    {isLoggedIn ? <div className="rheading">
                    <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>
                    </div> : ''}
                </div>
            <div className="">

                <Form className="CreateEntryForm" id="CreateLectureForm" noValidate validated={validated}>
                <Form.Row>
                <Form.Group as={Col} controlId="formGridname">
                            <Form.Label>Name *</Form.Label>
                            <Form.Control type="text" placeholder="" value={name} onChange={e => setName(e.target.value)}   pattern="[a-zA-Z0-9\s]+" required>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                Please fill your name.
                        </Form.Control.Feedback>
                        </Form.Group>

                    </Form.Row>
                    <Form.Row>
                        {
                            isLoggedIn ? <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Email Address *</Form.Label>
                                <Form.Control type="text" value={email} onChange={e => setEmail(e.target.value)} pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder="" readOnly>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                    Please fill your email address.
                            </Form.Control.Feedback>
                            </Form.Group> : 
                            <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Email Address *</Form.Label>
                            <Form.Control type="text" value={email} onChange={e => setEmail(e.target.value)} pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder="" >
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                Please fill your email address.
                        </Form.Control.Feedback>
                        </Form.Group>
                        }
                        <Form.Group as={Col} controlId="formGridMobile">
                        <Form.Label >Mobile Number *</Form.Label>
                            <Form.Control value={mobileNo} onChange={e => setMobileNo(e.target.value)} pattern="(?<!\d)\d{10,12}(?!\d)" maxLength="12" type="text" required>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                Invalid Phone Number
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridAddress">
                        <Form.Label >Address *</Form.Label>
                            <Form.Control value={address} onChange={e => setAddress(e.target.value)} type="text" as="textarea" rows="1" required >
                                </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridCity">
                        <Form.Label>City *</Form.Label>
                        <Form.Control type="text" placeholder="" value={cityName} onChange={(e) => setCityName(e.target.value)} required>
                                    </Form.Control>
                        </Form.Group>
                        
                    </Form.Row>

                    <Form.Row>
                    <Form.Group as={Col} controlId="formGridPostalCode">
                        <Form.Label >Postal Code *</Form.Label>
                            <Form.Control value={zip} onChange={e => setZip(e.target.value)} type="number" required>
                                </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridCountry">
                        <Form.Label>Country *</Form.Label>
                        <Form.Control as="select" custom value={countryId} 
                        onChange={e => {
                            setCountryId(e.target.value)
                            getStates(e.target.value)
                        }} required>
                                {countryData.map((country, index) => (
                                    <option key={index} value={country.location_id}>{country.name}</option>
                                ))}
                        </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>State *</Form.Label>
                        <Form.Control as="select" custom value={stateId} onChange={e => {
                            setStateId(e.target.value)
                            getCities(e.target.value)
                        }} required >
                            <option  value="">Select</option>
                                {stateData.map((state, index) => (
                                    <option key={index} value={state.location_id}>{state.name}</option>
                                ))}
                        </Form.Control>
                        </Form.Group>
                        
                    </Form.Row>



                    <div className="submitBtnRow">
                        <button type="button" onClick={() => handleSubmit()} className="saveBtn"> Submit </button>
                        <button type="button" onClick={() =>  deleteHandleShow()} className="resetBtn"> Reset </button>

                    </div>
                </Form>
                <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to reset this form?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
                            </Button>
                            <Button variant="primary"  onClick={() => handleReset()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showTnc}
                                onHide={handleTncClose}
                                backdrop="static"
                                size="lg"
                                keyboard={false}>
                                <Modal.Header className="d-flex justify-center">
                                    <Modal.Title >Preview of contract</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="tnc-body">
                                
                                    <StudentContractComponent name={name} nameInitials={shortHandName} email={email} mobileNo={mobileNo} address={address} zip={zip} cityName={cityName} stateName={stateName} countryName={countryName} classJoinDate={classJoinDate} teacherName={teacherName}/>
								</Modal.Body>
                                <Modal.Footer className="tnc-footer">
                                    <button className="loginBtn" onClick={handleTncClose}>
                                        Close
                                    </button>
                                </Modal.Footer>
                            </Modal>
            </div>
            </Container>
        </Container>
       
       </LoadingOverlay>
    );
};

export default StudentContract;
