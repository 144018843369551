import React, { useState , useEffect} from "react";
import "./update-chapter.css";
import Container from 'react-bootstrap/Container';
import Header from '../../../common/header/header'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import {CommonService} from '../../../service/common.service';
import { Link } from "react-router-dom";
import {history} from '../../../_helpers/history'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal'

const updateChapter = ({match}) => {
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const alert = useAlert();
    const [id, setId] = useState('');
    const [courseData, setCourseData] = useState([]);
    const [title, setTitle] = useState('');
    const [course, setCourseId] = useState('')
    const [isPublished, setIsPublished] = useState(true)
    const [courseStateId , setCourseStateId] = useState(null)
    const  paramId = match.params?.id;
    const  courseParamId = match.params?.courseId;

    const [dltShow, setdltShow] = useState(false);
    const onSwitchAction = () => {
        setIsPublished(!isPublished);
    };
    const delethandleClose = () => setdltShow(false);
    const deleteHandleShow = async () => {
        setdltShow(true);
    }

    const getCourses = async (id) => {
        try {
            setLoading(true);
            CommonService.httpGetService(`admin-api/courses?id=${id}`)
            .then(data => {
                setCourseData(data)
                setLoading(false);
            }, error => {
                alert.error(error)
                setLoading(false);
            })
        }
        catch {
            alert.error("Please fill valid details")
        }
    }
    useEffect(() => {
        if(courseParamId != null) {
            setCourseStateId(courseParamId)
            getCourses(courseParamId);
        }
        if(paramId) {
            setId(paramId);
            setLoading(true);
            CommonService.httpGetService(`admin-api/course_chapter?id=${paramId}`)
            .then(data => {
                if(data.length > 0) {
                    let chapterTitle = data[0].title
                    let courseId = data[0].course_id
                    let chapterIsPublished = data[0].is_published
                    setTitle(chapterTitle);
                    setIsPublished(chapterIsPublished);
                    setCourseId(courseId);
                    
                    // if (chapterIsPublished == true){
                    //     setIsSwitchOn(true)
                    // }
                    // else{
                    //     setIsSwitchOn(false)
                    // }
                    setLoading(false);
                }
            },error =>{
                alert.error(error)
                setLoading(false);
			})
        }
    }, []);

    const handleSubmit = (course, title, isPublished) => {
         
        setLoading(true);
        const form = document.getElementById("UpdateChapterForm");
        // const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
             
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
            handleUpdateButtonClick(course, title, isPublished);
        }
       
    };


    const handleReset = () => {
        setTitle('');
        setIsPublished("Y");
        setValidated(false);
        setdltShow(false);

    };

    function handleUpdateButtonClick(course, title, isPublished) {
         
        try {
            let obj = {
                "title": title,
                "is_published": isPublished,
                "course_id": course,
                "last_modified_by": "ADMIN",
                "tenant_id": "1"
            }

            CommonService.httpPutService(`admin-api/course_chapter/${id}`, obj)
            .then(res => {
                setLoading(false);
                alert.success(`Chapter updated successfully.`);
				},error =>{
                    setLoading(false);
					alert.error(error)
			})
           

        } catch (error) {
            setLoading(false);
            alert.error("Please fill the required details!");
        }
    }

   

    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >        
        <Container className="containerBox" fluid>
            <Header></Header>           
            <Container fluid>
                <div className="heading">
                    <div className="lheading">
                        <span className="headTitle">Update Chapter </span>
                        {/* <span className="searchItem"> Code : XYZ </span> */}
                    </div>
                    <div className="rheading">
                    <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>
                    </div>
                </div>

                <Form className="CreateEntryForm" id="UpdateChapterForm" noValidate validated={validated}>
                <Form.Row>
                        <Form.Group as={Col} controlId="formGridCode">
                            <Form.Label>Course</Form.Label>
                            <Form.Control as="select" defaultValue={course}  custom onChange={e => setChapterId(e.target.value)} required>
                                {courseData.map((course, index) => (
                                    <option key={index} value={course.id}>{course.name} ( {course.code} )</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please fill name of the course.
                        </Form.Control.Feedback>
                               </Form.Group>
                        <Form.Group as={Col} controlId="formGridName">
                            <Form.Label>Chapter Title *</Form.Label>
                            <Form.Control type="text" value={title} onChange={e => setTitle(e.target.value)} placeholder="Chapter Title"  pattern="[a-zA-Z0-9\s]+" required/>
                            <Form.Control.Feedback type="invalid">
                                Please fill title of the chapter.
                        </Form.Control.Feedback>
                       </Form.Group>

                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} className="col-6" controlId="formGridDuration">
                            <Form.Label>Published</Form.Label>
                            <Form.Check 
                                    type="switch"
                                    id="custom-switch"
                                    label=""
                                    checked={isPublished}
                                    onChange={e => onSwitchAction(e.target.value)}
                                    
                                />
                         <Form.Control.Feedback type="invalid">
                                Please the status of published.
                        </Form.Control.Feedback>
                            {/* <Form.Control as="select" defaultValue={isPublished} custom onChange={e => setIsPublished(e.target.value)} >
                                <option value="Y" >Yes</option>
                                <option value="N">No</option>
                            </Form.Control>  */}
                        </Form.Group>
                    </Form.Row>
                    <div className="submitBtnRow">
                        <button type="button" onClick={() => {
                             
                            handleSubmit(course, title, isPublished)
                        }} className="saveBtn"> Submit </button>
                        <button type="button" onClick={() =>  deleteHandleShow()} className="resetBtn"> Reset </button>
                        <Link to={`/view-lecture/${course}/${id}`} className=""><button type="button" className="resetBtn">
                            Lectures   
                         </button></Link> 
                    </div>
                </Form>
            
                <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to reset this form?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
                            </Button>
                            <Button variant="primary"  onClick={() => handleReset()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>
            </Container>
        </Container>
        </LoadingOverlay>
    );
};

export default updateChapter;


