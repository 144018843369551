import React, { useState, useContext, useEffect,useReducer } from "react";
import "../start/start-test";
import "./mcq-test.css"
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Header from '../../../common/header/header'
import { CommonService } from '../../../service/common.service';
import { useAlert } from "react-alert";
import LoadingOverlay from 'react-loading-overlay';
import queryString from 'query-string';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl'
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const multipleChoice = ({ match }) => {
	
	const [loading, setLoading] = useState(false);
	const [role, setRole] = useState('')
	
	const alert = useAlert();
	const queryParams = queryString.parse(location.search)
	const [isMaximized,setIsMaximized] = useState(false);
	useEffect(() => {
		setLoading(false);

	}, []);


	return (
		<LoadingOverlay
			active={loading}
			spinner
			text='Loading...'
			className="loaderStyle"
		>
			<div className="containerBox">
				<Header></Header>

			<div className="contentBox">
                <div className="questionSection">
                    <div className="qsHeading mb-4">
                        <span className="quesNum">4 Questions</span>
                        <span className="quesNum">Total Score: 400</span></div>
                <div className="questionList">
                        <span className="quesNum">4 Subjective Questions</span>
                        <ul className="quesUl">
                            <li className="ques_li"><span>1. Unarmed Security types.</span> <button type="button" className="weightage">+100</button></li>
                            <li className="ques_li"><span>2. Explain Armed Security </span><button type="button" className="weightage">+100</button> </li>
                            <li className="ques_li"><span>3. Explain Unarmed Security</span><button type="button" className="weightage">+100</button> </li>
                            <li className="ques_li"><span>4. Describe emergency tactics</span><button type="button" className="weightage">+100</button> </li>
                        </ul>
                        </div>
               </div>
            <div className="presentationSection">
            <div className="qsHeading mb-4">
                        <span className="quesNum">Question 1</span>
                        <span className="quesNum">Total Score: 100  <i className="fas fa-question-circle"></i></span></div>
                        <div className="currentQ">
                            <span className="areaQues">Types of Unarmed Security.</span>
                        <div className="areaAns">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend className="btnRadio" >
                            <InputGroup.Radio aria-label="Radio button for following text input" />
                            </InputGroup.Prepend>
                            <FormControl className="areaOpt" aria-label="Text input with radio button"  value="Option 1" />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend className="btnRadio" >
                            <InputGroup.Radio aria-label="Radio button for following text input" />
                            </InputGroup.Prepend>
                            <FormControl className="areaOpt" aria-label="Text input with radio button"  value="Option 2" />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend className="btnRadio" >
                            <InputGroup.Radio aria-label="Radio button for following text input" />
                            </InputGroup.Prepend>
                            <FormControl className="areaOpt" aria-label="Text input with radio button"  value="Option 3" />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend className="btnRadio" >
                            <InputGroup.Radio aria-label="Radio button for following text input" />
                            </InputGroup.Prepend>
                            <FormControl className="areaOpt" aria-label="Text input with radio button"  value="Option 4" />
                        </InputGroup>
                        {/* <CKEditor
                    className="editorStyle"
                    editor={ ClassicEditor }
                    data="<p>Answer:</p>"
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                /> */}
                        </div>
           
                        </div>
                        <div className="submitBtnRow">
                        <button type="button"  className="btnSkip"> Skip </button>
                        <button type="button" className="btnSubmit"> Submit </button>
                       
                    </div>
            </div>
            	<div className="courseDetails">
						<div className="courseHeading">Course Content</div>
						<div className="lectureList">
							<Accordion >
							
									<Card >
										<Card.Header>
											<Accordion.Toggle as={Button} variant="link" >
												<span className="chapter-name"></span>
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse >
											<Accordion >
												
													<ul style={{ boxShadow: "none" }}>
														<li className="lectureHeader" style={{ backgroundColor: "#fff", borderBottom: "1px solid #ededed" }} >
															<Accordion.Toggle as={Button}  variant="link">
																<span className="lectureName"> </span>
															</Accordion.Toggle>
														</li>
														<Accordion.Collapse  style={{ padding: 0 }}>
															<div style={{ padding: 0 }} >

																	<div className="border-bottom" >
                                                                    </div>

															</div>
														</Accordion.Collapse>
													</ul>
											</Accordion>

										</Accordion.Collapse>
									</Card>

						
							</Accordion>


						</div>

					</div>
				</div>
			</div>
		</LoadingOverlay>
	);
};


export default multipleChoice;
