import React, { useState, useEffect } from "react";
import LoadingOverlay from 'react-loading-overlay';
import "./payment.css";
const ThankyouPage = () => {
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        setTimeout(() => {
			setLoading(false)
		}, 100);
    }, [])
    return (
        <LoadingOverlay
            active={loading}
            spinner
            text={`Loading`}
            className="loaderStyle"
        >

<div className="mt-5 text-center">
	<i className="fa fa-check fa-4x" style={{
		    color: "green"
	}} id="checkmark"></i>
  <h2 className="display-3">Thank you!</h2>
  <p className="lead"><strong>Please check your email now</strong> for further information to complete the process.</p>
</div>
           
        </LoadingOverlay>
    );
};

export default ThankyouPage;
