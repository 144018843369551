import React, { useState, useContext, useEffect, useReducer } from "react";
// import "./pass-result.css";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Firework from '../../../assets/sparkle.png';
import Passtest from '../../../assets/pass-test.png';
import Cross from '../../../assets/cross.png';


const QuizPassResult = ({totalAttempted, totalQuestion , totalCorrect, totalWrong}) => {
	const [percent, setPercentage] = useState(0)
    
    useEffect(() => {
		percentage()
	}, []);

    function percentage() {
         
        let percent= ((totalCorrect/ totalQuestion) * 100).toFixed(2)
        setPercentage(percent)
    }

	return (
            <div className="quiz-result-screen">
                <div className="quiz-u-side">
                <div className="image-col">
                <img className="sparkle upstar" src={Firework} />
                <img className="testbook" src={Passtest} />
                <img className="sparkle downstar" src={Firework} />
                </div>
                <div className="quiz-caption-col">
                Quiz Score : {percent}%
                </div>
                </div>
                <div className="d-side">
                {/* <span className="congrats">Congratulations, you passed!</span> */}
                {/* <span className="congrats_tagline">you did well in the quiz</span> */}
                <div className="divider-row">
                    <div className="l-divider">
                    <span className="test-stat">{totalAttempted}/{totalQuestion}</span>
                    <span className="stat-caption">Attempted Question</span>
                    </div>
                    <div className="m-divider"></div>
                    <div className="r-divider">
                    <span className="course-stat">{totalCorrect}</span>
                    <span className="stat-caption">Correct Answer</span>
                    </div>
                    <div className="m-divider"></div>
                    <div className="r-divider">
                    <span className="course-stat">{totalWrong}</span>
                    <span className="stat-caption">Wrong Answer</span>
                    </div>
                </div>
                </div>
            </div>
	);
};

export default QuizPassResult;
