import React, { useState, useContext, useEffect,useReducer } from "react";
import "./start-test.css";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
// import BigLogo from './../../assets/fls/logo-large-with-slogan.png'
import Header from '../../../common/header/header'
import { CommonService } from '../../../service/common.service';
import { useAlert } from "react-alert";
import LoadingOverlay from 'react-loading-overlay';
import queryString from 'query-string';
// import  MessageComponent  from '../../components/MessageComponent';
// import  OngoinClassStudentsComponent  from '../../components/OngoinClassStudentsComponent';
var Centrifuge = require("centrifuge");
const StartTest = ({ match }) => {
	
	const [state, dispatch] = useReducer(reducer);
	var studentArr = []
	const [loading, setLoading] = useState(false);
	const [role, setRole] = useState('')
	const [defaultViewHeading, setDefaultViewHeading] = useState('')
	const [teacherData, setteacherData] = useState([]);
	const [studentData, setStudentData] = useState([]);
	
	const [chapterData, setchapterData] = useState([]);
	const [selectedAssetPageIndex, setSelectedAssetPageIndex] = useState(null)
	const [lectureData, setLectureData] = useState([]);
	const [selectedAsset, setSelectedAsset] = useState(null)
	const [chapterKey, setChapterKey] = useState(0)
	const [lectureKey, setLectureKey] = useState(0)
	const [url, setUrl] = useState(null)
	const alert = useAlert();
	const queryParams = queryString.parse(location.search)
	const courseId = match.params?.courseId
	const classId = match.params?.classId
	const teacherId = match.params?.teacherId
	const channelName = queryParams?.channel_name;
	const [isMaximized,setIsMaximized] = useState(false);
	useEffect(() => {
		setLoading(false);
		if (localStorage.getItem('user') != null && localStorage.getItem('user') != undefined) {
			var data = JSON.parse(localStorage.getItem('user'))
			setRole(data.role ? data.role : '')
			subscribeCentrifuge(channelName,data.role,data.centri_token)
			// if (data.role == 'TEACHER') {
			// console.log(`Role of user is before subscription: #`,data.role)
			// }
		}
		getTeachers();
		getStudents();
		// getCourseChapter();
		if (courseId) {
			getCourseChapter();
		}

	}, []);

	const subscribeCentrifuge = (channelName,userRole,token) => {
		const centrifuge = new Centrifuge(CommonService.getCentrifugeUrl('ws'));
		centrifuge.setToken(token);
		centrifuge.on('connect', function (ctx) {
			console.log("connected", ctx);
		});

		centrifuge.on('disconnect', function (ctx) {
			console.log("disconnected", ctx);
		});


		var subs = centrifuge.subscribe(channelName, function (ctx) {
			console.log('Role of user is after subscription: ', userRole)
			if (userRole == 'STUDENT') {
				setChapterKey(ctx.data.chapterKey)
			setLectureKey(ctx.data.lectureKey)
			setSelectedAsset(ctx.data.selectedAssetId)
			if (ctx.data.selectedAssetUrl == url) {
				setSelectedAssetPageIndex(ctx.data.selectedAssetPageIndex)
			} else {
				setSelectedAssetPageIndex(1)
				setUrl(ctx.data.selectedAssetUrl)
			}
			}
		}).on("join", function(message) {
			var userData = JSON.parse(message.info.user)
			// setOnlineStatus(userData.Id)
			var messageText = `${userData.Name} joined class`
			// alert.success(messageText)
			console.log(messageText);
			getOnlineUsers(channelName)
		}).on("leave", function(message) {
			getOnlineUsers(channelName)
			
			console.log("Client left channel", message);
		});
		centrifuge.connect();
	}

	const setOnlineStatus = (id) =>{
		for (const key in studentArr) {
			if (studentArr.hasOwnProperty(key)) {
				const student = studentArr[key];
				if (student.id == id) {
					student.login_status = 'online'
				}
			}
		}
		console.log('Updated Student Arr : ',studentArr)
		setStudentData(studentArr)
		// getStudents()
		
		console.log('Student arr in setOnlineStatus : ',studentArr)
	}

	const getOnlineUsers = (channelName) => {
        let obj = {
            "method": "presence",
            "params": {
                "channel": channelName
            }
        }
        CommonService.publishToCentrifuge(obj).then(res => {
			console.log(res)
			for (const key in res.result.presence) {
				if (res.result.presence.hasOwnProperty(key)) {
					const student = res.result.presence[key];
					 var studentInfo = JSON.parse(student.user)
					 setOnlineStatus(studentInfo.Id)
				}
			}
        }, error => {
        })
        
    }

	const postToCentrifuge = (assetDataParam) => {
		if (role == 'TEACHER') {
			let assetUrl = url;
			let assetId = selectedAsset;
			let pageIndex = 1
			if (assetDataParam != null) {
				if (assetDataParam['url']) {
					assetUrl = assetDataParam['url']
				}
				if (assetDataParam['id']) {
					assetId = assetDataParam['id']
				}
				if (assetDataParam['pageIndex']) {
					pageIndex = assetDataParam['pageIndex']
					setSelectedAssetPageIndex(pageIndex)
				}
			}
			let postObjData = {
				"chapterKey": chapterKey ? chapterKey : 0,
				"lectureKey": lectureKey ? lectureKey : 0,
				"selectedAssetId": assetId,
				"selectedAssetUrl": assetUrl,
				"selectedAssetPageIndex": pageIndex
			}

			let postObj = {
				"method": "publish",
				"params": {
					"channel": channelName,
					"data": postObjData
				}
			}
			console.log(JSON.stringify(postObj))
			CommonService.publishToCentrifuge(postObj).then(res => {
				console.log(res)
			}, error => {

			})
		}
	}

	const getTeachers = async () => {
		setLoading(true);
		CommonService.httpGetService(`admin-api/teacher?id=${teacherId}`)
			.then(data => {
				setLoading(false);
				setteacherData(data)

			}, error => {
				setLoading(false);
			})
	}
	const getCourseChapter = async () => {
		setLoading(true);
		CommonService.httpGetService(`admin-api/course_chapter/lecture?course_id=${courseId}`)
			.then(data => {
				if (data.length > 0) {
					setchapterData(data)
					setDefaultViewHeading(data[0].title)
					setLoading(false);
				}
			}, error => {
				alert.error(error)
				setLoading(false);
			})
	}

	const getStudents = () => {
		CommonService.httpGetService(`admin-api/class_student/student/selected?class_id=${classId}`)
			.then(data => {
				setLoading(false);
				dispatch({type: 'studentData', payload: data})
				
				if (studentArr.length > 0) {
					data = studentArr
				}else{
					studentArr = data
				}
				setStudentData(data)

			}, error => {
				setLoading(false);
				alert.error(error)

			})
	}

	const getAllStudents = () => {
		CommonService.httpGetService(`admin-api/class_student/student`)
			.then(data => {
				setLoading(false);
				// dispatch({type: 'studentData', payload: data})
				// studentArr = data
				setStudentData(data)

			}, error => {
				setLoading(false);
				alert.error(error)

			})
	}


	return (
		<LoadingOverlay
			active={loading}
			spinner
			text='Loading...'
			className="loaderStyle"
		>
			<div className="containerBox">
				<Header></Header>

			<div className="contentBox">
                <div className="startScreen">
                <div className="testDetails">
                    <div className="ltdetails">
                        <span className="testName fontwt mb-1">Python-Basic-D19</span>
                        <span className="organizer mb-1">By <span className="fontwt">FLS</span></span>
                        <span className="testDuration mb-1">Duration <span className="fontwt">3 hrs</span></span>
                    </div>
                    <div className="rtdetails">
                    <span className="systemCompatible"><i className="fas fa-check-circle" style={{color: "#A5C75F"}}></i> System is compatible for taking this test</span>
                    <Button className="grnColor" variant="success">Enter Test  <i className="fas fa-arrow-circle-right"></i></Button>
                    </div>
                </div>
                <div className="instructionDetails">
                    <div className="generalIns">
                        <span className="listHeading">General Instructions</span>
                        <ul className="genInsList">
                            <li className="Inst_li">Test Duration: 3 hrs</li>
                            <li className="Inst_li">Make sure you check test ID</li>
                            <li className="Inst_li">Click submit after every answer</li>
                            <li className="Inst_li">Click ? for any help or doubt</li>
                        </ul>

                        <span className="listHeading mt-4">The test will have following types of questions</span>
                          {/* <div className="typeoftest">15 MCQs</div> */}
                          <ul className="genInsList">
                            <li className="Inst_li">15 MCQs</li>
                        </ul>
                    </div>

                    <div className="otherIns">
                        <span className="listHeading">Help and Support</span>
                        <div className="typeoftest">Please contact the test administrator. <br/>
                          <span className="fontwt">Prayas Goel</span> at the given email address : <br/> 
                           <a href="#">prayas@opskube.com</a></div>
                    </div>

                </div>
                </div>
            	<div className={`${role == 'STUDENT' ? `disableBlock courseDetails` : 'courseDetails'} ${isMaximized == true ? 'd-none' : ''}`}>
						<div className="courseHeading">Course Content</div>
						<div className="lectureList">
							<Accordion defaultActiveKey={chapterKey}>
								{chapterData && chapterData.map((chapterDta, index) => (
									<Card key={index} className={`index-${index}`}>
										<Card.Header>
											<Accordion.Toggle as={Button} onClick={() => {
																setDefaultViewHeading(chapterDta.title)
															}} variant="link" eventKey={index}>
												{/* @TODO Fix day */}
												<span className="chapter-name">{index + 1}: {chapterDta.title}</span>
												{/* <span className="lectureDetail">1/8 | 25 min</span> */}
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey={index}>
											{/* <Card.Body> */}
											{/* <span className="lectureName">Lectures</span> */}
											{/* Lecture Accordion */}
											<Accordion defaultActiveKey={lectureKey}>
												{chapterDta.lectures.map((lectureData, lectureIndex) => (
													<ul key={lectureIndex} className={`lectureIndex-${lectureIndex}`} style={{ boxShadow: "none" }}>
														<li className="lectureHeader" style={{ backgroundColor: "#fff", borderBottom: "1px solid #ededed" }} >
															<Accordion.Toggle as={Button} onClick={() => {
																setDefaultViewHeading(lectureData.title)
															}} variant="link" eventKey={lectureIndex}>
																{/* @TODO Fix day */}
																<span className="lectureName"> {lectureData.title}</span>
																{/* <span className="lectureDetail">1/8 | 25 min</span> */}
															</Accordion.Toggle>
														</li>
														<Accordion.Collapse eventKey={lectureIndex} style={{ padding: 0 }}>
															<div style={{ padding: 0 }} >
																{/* <span className="lectureDetail font-weight-bold border-bottom border-danger">Assets</span> */}

																{/* Assets Accordion */}
																{lectureData.assets.map((assetData, assetIndex) => (
																	<div key={assetIndex} className="border-bottom" onClick={() => {

																		setUrl(assetData['url']);
																		setSelectedAsset(assetData['id']);
																		assetData['pageIndex'] = 1
																		postToCentrifuge(assetData)
																		// alert.success(`Clicked ${assetData.title}`)
																	}}>
																		{
																			selectedAsset && selectedAsset == assetData.id ? (
																				<span className="assetdetail selected-asset"> {assetData.title}</span>
																			) : (
																					<span className="assetdetail"> {assetData.title}</span>
																				)
																		}
																		<span className="lectureDetail"></span>
																	</div>
																))
																}

															</div>
														</Accordion.Collapse>
													</ul>
												))
												}
											</Accordion>
											{/* </Card.Body> */}
											{/* )) } */}
										</Accordion.Collapse>
									</Card>

								))
								}
							</Accordion>


						</div>

					</div>
				</div>
			</div>
		</LoadingOverlay>
	);
};

function reducer(state, action) {
    switch (action.type) {
      case 'studentData':
        return { studentData : action.payload};      
      default:
        throw new Error();
    }
}

export default StartTest;
