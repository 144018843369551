import React, {useRef, useState , useEffect} from "react";
import "./update-course.css";
import Container from 'react-bootstrap/Container';
import Header from '../../../common/header/header'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import {CommonService} from '../../../service/common.service';
import { Link } from "react-router-dom";
import {history} from '../../../_helpers/history'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const updateCourse = ({ match }) => { 

    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const formRef = useRef(null);
    const alert = useAlert();
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [code, setCode] = useState('')
    const [description, setDescription] = useState('')
    const [certificateDetail, setCertificateDetail] = useState('<p></p>');
    const [duration_type, setDurationType] = useState('Weeks')
    const [duration, setDuration] = useState('')
    const [max_no_of_students, setMaxNoOfStudents] = useState('')
    const [fee, setFee] = useState('')
    const [passingPercentage, setPassingPercentage] = useState(0)
    const [minTestAttempt, setMinTestAttempt] = useState(0)
    const  paramId = match.params?.id;

    const [dltShow, setdltShow] = useState(false);
    const [shouldBlockNavigation, setshouldBlockNavigation] = useState(false)
    const delethandleClose = () => setdltShow(false);
    const deleteHandleShow = async () => {
        setdltShow(true);
    }

    useEffect(() => {
        

        if(paramId) {
            setshouldBlockNavigation(true)
            setId(paramId);
            setLoading(true);
            CommonService.httpGetService(`admin-api/courses?id=${paramId}`)
            .then(data => {
                 
                if(data.length > 0) {

                    let courseCode = data[0].code
                    let courseName = data[0].name
                    let courseDescription = data[0].description
                    let certificateDescription = data[0].course_certificate_details ? data[0].course_certificate_details :  '<p></p>'
                    let courseFee = data[0].fee
                    let maxNoOfStudents = data[0].max_no_of_students
                    let durationInDays = data[0].duration_type
                    let duration = data[0].duration
                    let passing_percentage = data[0].passing_percentage ? data[0].passing_percentage : 0
                    let min_test_attempt = data[0].min_test_attempt ? data[0].min_test_attempt : 0

                    setCode(courseCode);
                    setName(courseName);
                    setFee(courseFee);
                    setDescription(courseDescription);
                    setCertificateDetail(certificateDescription);
                    setMaxNoOfStudents(maxNoOfStudents);
                    setDurationType(durationInDays);
                    setDuration(duration);
                    setPassingPercentage(passing_percentage)
                    setMinTestAttempt(min_test_attempt)
                    setLoading(false);
                }
            },error =>{
                alert.error(error)
                setLoading(false);
			})
        }
    
        if (shouldBlockNavigation) {
            window.onbeforeunload = () => true
          } else {
            window.onbeforeunload = undefined
          }
    //    handleUpdateButtonClick();
    }, []);

    const handleSubmit = (code, name, fee, description, max_no_of_students, duration_type, duration,certificateDetail) => {
         
        setLoading(true);
        const form = document.getElementById("UpdateCourseForm");
        // const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
             
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
            handleUpdateButtonClick(code, name, fee, description, max_no_of_students, duration_type, duration,certificateDetail);
            
        }
       
    };

    const handleReset = () => {
        // document.getElementById("UpdateCourseForm").reset();
        // setCode('');
        setName('');
        setFee('');
        setDescription('');
        setCertificateDetail('<p></p>');
        setMaxNoOfStudents('');
        setDurationType('Weeks');
        setDuration('');
        setValidated(false);
        setdltShow(false);
        setPassingPercentage(0)
        setMinTestAttempt(0)
      };

    function handleUpdateButtonClick (code, name, fee, description, max_no_of_students, duration_type, duration,certificateDetail) {
        try {
            let obj = {
                "id" : id,
                "code": code,
                "name": name,
                "fee": fee,
                "description": description,
                "course_certificate_details": certificateDetail,
                "max_no_of_students": max_no_of_students,
                "duration_type": duration_type,
                "duration": duration,
                "tenant_id": "TENANT",
                "has_certificate": true,
                "passing_percentage" : passingPercentage,
                "min_test_attempt" : minTestAttempt
            }

            CommonService.httpPutService(`admin-api/courses/${id}`, obj)
            .then(users => {
                setLoading(false);
                alert.success("Cources has been updated successfully");
				},error =>{
                    setLoading(false);
                    alert.error(error)
                    // alert.error("Something went wrong!");
                    
			})

        } catch (error) {
            setLoading(false);
            alert.error("Please fill the required details!");
            // alert('Something went wrong with login')
        }
    }

    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
            <Header></Header>           
            <Container fluid>
                <div className="heading">
                    <div className="lheading">
                        <span className="headTitle">Update Course </span>
                        <span className="searchItem">  </span>
                    </div>
                    <div className="rheading">
                    <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>
                    </div>
                </div>

                <Form ref={formRef} className="CreateEntryForm" id="UpdateCourseForm" noValidate validated={validated}  >
                {/* <Prompt
                    when={shouldBlockNavigation}
                    message='You have unsaved changes, are you sure you want to leave?'
                /> */}
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridCode">
                            <Form.Label>Code *</Form.Label>
                            <Form.Control  value={code} onChange={e => setCode(e.target.value)} placeholder="" pattern="[a-zA-Z0-9\s]+" required/>
                            <Form.Control.Feedback type="invalid">
                                Please fill code of the course.
                        </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridName">
                            <Form.Label>Name *</Form.Label>
                            <Form.Control type="text" value={name}  pattern="[a-zA-Z0-9\s]+" onChange={e => setName(e.target.value)} placeholder="" required/>
                            <Form.Control.Feedback type="invalid">
                                Please fill the name of course.
                        </Form.Control.Feedback>
                        </Form.Group>

                    </Form.Row>
                    <Form.Row>
                    <Form.Group as={Col} controlId="formGridDuration">
                                
                                <Form.Label>Duration *</Form.Label>
                                <Form.Group style={{display: "flex"}} controlId="formGridDuration">
                                <Form.Control className="durtype" as="select" type="text" value={duration_type} custom onChange={e => setDurationType(e.target.value)}  required >
                                <option value="Weeks">Weeks</option>
                                <option value="Days">Days</option>
                                <option value="Hours">Hours</option>
                                <option value="Minutes">Minutes</option>
                                </Form.Control>
                                <Form.Control className="durVal" type="number" min="0" value={duration} onChange={e => setDuration(e.target.value)} placeholder="" required/>

                                <Form.Control.Feedback type="invalid">
                                    Please fill the duration.
                        </Form.Control.Feedback>

                                </Form.Group>
                            
                            </Form.Group>

                        <Form.Group as={Col} controlId="formGridAddress2">
                            <Form.Label> Maximum No. Of Students</Form.Label>
                            <Form.Control type="number"  min="0" value={max_no_of_students} onChange={e => setMaxNoOfStudents(e.target.value)} placeholder=""  />
                            <Form.Control.Feedback type="invalid">
                                Please fill the maximum number of students allowed.
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridCity">
                            <Form.Label>Course Fee *</Form.Label>
                            <Form.Control type="number" min="0.01" step="0.01" value={fee} onChange={e => setFee(e.target.value)} required />
                            <Form.Control.Feedback type="invalid">
                                Please fill the course fee.
                        </Form.Control.Feedback>
                       </Form.Group>

                        <Form.Group as={Col} controlId="formGridZip" className="descriptionRow">
                            <Form.Label >Description *</Form.Label>
                            {/* <textarea className="descriptionTxtBox"></textarea> */}
                            <Form.Control value={description} onChange={e => setDescription(e.target.value)} required/>
                            {/* <Form.Control.Feedback type="invalid">
                                Please fill the description of course.
                        </Form.Control.Feedback> */}
                        </Form.Group>


                    </Form.Row>

                    <Form.Row>
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>Passing Percentage *</Form.Label>
                                <Form.Control type="number" min="0" max="100"  value={passingPercentage} onChange={e => setPassingPercentage(e.target.value)} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please fill the Passing percentage of course.
                        </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridZip" className="descriptionRow">
                                <Form.Label >Minimum Test To be Attempted *</Form.Label>
                                {/* <textarea className="descriptionTxtBox"></textarea> */}
                                <Form.Control type="number" min="0" value={minTestAttempt} onChange={e => setMinTestAttempt(e.target.value)} required />
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                        </Form.Control.Feedback>
                            </Form.Group>


                        </Form.Row>

                    <Form.Row>
                            <Form.Group as={Col} xs={12}>
                                <Form.Label >Ceritficate Description *</Form.Label>
                                <CKEditor id="editor"
                                    editor={ClassicEditor}
                                    data={certificateDetail}
                                    onChange={(event, editor) => {
                                        setCertificateDetail(editor.getData())
                                    }}
                                    config={{         
                                        toolbar: ['heading', '|', 'bold', 'italic','|', 'blockQuote', 'numberedList', 'bulletedList','outdent', 'indent', '|', 'insertTable',
                                          'tableColumn', 'tableRow', 'mergeTableCells', '|', 'undo', 'redo']
                                      }} 
                                // *["undo", "redo", "bold", "italic", "blockQuote", "ckfinder", "imageTextAlternative", "imageUpload", "heading", "imageStyle:full", "imageStyle:side", "link", "numberedList", "bulletedList", "mediaEmbed", "insertTable", "tableColumn", "tableRow", "mergeTableCells"]*

                                />
                            </Form.Group>
                        </Form.Row> 

                    <div className="submitBtnRow">
                        <button type="button" onClick={() => handleSubmit(code, name, fee, description, max_no_of_students, duration_type, duration,certificateDetail)} className="saveBtn"> Submit </button>
                        <button type="button" onClick={() => { deleteHandleShow() }} className="resetBtn"> Reset </button>
                        <Link to={`/view-chapter/${id}`} className="" disabled={paramId == null? true: false}><button type="button" className="resetBtn">Chapters</button></Link>    
                        <Link to={`/courses-resource/create/${id}`} className="" disabled={paramId == null? true: false}><button type="button" className="resetBtn">Add Resources</button></Link>    
                        <Link to={`/courses-resource/view/${id}`} className="" disabled={paramId == null? true: false}><button type="button" className="resetBtn">View Resources</button></Link>    
                    </div>
                </Form>

                <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to reset this form?
                    </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
                            </Button>
                            <Button variant="primary"  onClick={() => handleReset()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>

            </Container>
        </Container>
        </LoadingOverlay>
    );
};

export default updateCourse;


