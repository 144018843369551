import React, { useState, useContext, useEffect, useReducer } from "react";
import './certificate.css';
import BigLogo from '../../assets/fls/cof_mark.png'
import queryString from 'query-string';
import {CommonService} from '../../service/common.service'
import * as moment from 'moment'
const CertificatePrint = () => {
    const queryParams = queryString.parse(location.search)
    const [user_name, setUserName] = useState("");
    const [teacher_name, setTeacherName] = useState("");
    const [description, setDescription] = useState("");
    const [courseName, setCourseName] = useState("");
    const [completionDate, setCompletionDate] = useState("");

    useEffect(() => {
       if (queryParams?.type) {
        if (queryParams.type == 'test') {
            getTestData(queryParams);
        }else if (queryParams.type == 'course') {
            getCourseData(queryParams);
        }
       }
    }, []);

    const getCourseData = (qParams) =>{
        CommonService.httpGetService(`admin-api/class_student?class_id=${qParams.id}&user_id=${qParams.user_id}`)
            .then(res => {
                 
                if (res.length > 0 && res[0])  {
                    setUserName(res[0].user_name)
                    setTeacherName(res[0].teacher_name)
                    setCourseName(res[0].course_name)
                    setCompletionDate(res[0].completion_date)
                    setDescription(res[0].course_certificate_details ? res[0].course_certificate_details : '')
                    
                }
            }, err => {
               
        })
    }

    const getTestData = (qParams) =>{
        CommonService.httpGetService(`admin-api/user-test/result?id=${qParams.id}&user_id=${qParams.user_id}`)
            .then(res => {
                if (res.length > 0 && res[0])  {
                setUserName(res[0].student_name)
                setTeacherName(res[0].teacher_name)
                setCourseName(res[0].course_name)
                setDescription(`for completing florida security school's test ${res[0].test_name} of course ${res[0].course_name}`)
                }
            }, err => {
               
        })
    }


	return (
        <div className="border-blue">

            <div className="certi-content">
                <img className="cof_mark" src={BigLogo} ></img>
                <span className="certi-title">CERTIFICATE OF RECOGNITION</span> <br/>
                <span className="certi-tag">THIS CERTIFICATE IS PROUDLY PRESENTED TO</span> <br/>
                <span className="holder-name">{user_name}</span> <br/>
                <span className="course-name">{courseName}</span> <br/>
                <div className="d-flex justify-content-center text-center">
                <div className={`div2 ${user_name ? 'voucher-container' : ''} `} dangerouslySetInnerHTML={{__html: description }}></div> 
                </div><br/>
             <span className="certi-complete" >Completion Date :  <span className="certi-complete-date" >
                    {completionDate ? moment(completionDate).format('MM/DD/YYYY') : ''}</span> </span> <br/>
            </div>

            <div className="instructor-details mt-5 mb-5">
                <div className="name-desig mr-2">
                <span className="fa certi-tag border-botttom-2">DS3000051</span>
                <span className="fontThirteen certi-tag">Florida Security School</span>
                </div>

                <div className="name-desig ml-2">
                <span className="fa certi-tag border-botttom-2">{teacher_name}</span>
                <span className="fontThirteen certi-tag">Course Instructor</span>
                </div>
            </div>
            
        </div>
        
	);
};

export default CertificatePrint;
