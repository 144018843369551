import React, { useState, useContext, useEffect, useReducer } from "react";
// import "./testComponent.css";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form'
import { CommonService } from '../../../service/common.service';
import { useAlert } from "react-alert";
const ObjectiveComponent = ({ testResultId,testDetails,questionNo, selectedQuestion,questionType,questionIndex, handleSkip , handleLoading}) => {
    const [answer, setAnswer] = useState(null);
    const alert = useAlert();
    const onClickSubmit = () => {
        try {
            setLoading(true);
            if (answer != null) {
                var userData = JSON.parse(localStorage.getItem('user'))
                let reqObj = {
                    "user_test_result_id": testResultId,
                    "course_question_id": selectedQuestion.id,
                    "user_id": userData.id,
                    "answer": answer
                }
                console.log(reqObj)
                CommonService.httpPostService(`admin-api/user-test`,reqObj)
                .then(data => {
                    console.log(data);
                    skipQuestion(selectedQuestion)
                    setAnswer(null)
                    alert.success('Answer has been submitted')
                    setLoading(false);

                }, error => {
                    console.error('Error in submitting answer ObjectiveComponent', error)
                    alert.error('Error in submitting answer')
                    setLoading(false);
                })
                
            }else{
                alert.error('Please choose your answer.')
                setLoading(false);
            }
            
        }
        catch (error) {
            alert.error('Something went wrong..')
            setLoading(false);
        }
    }


    const skipQuestion = (selectedQuestion) =>{
        handleSkip(selectedQuestion,testDetails,questionType,questionIndex,questionNo)
    }
    const setLoading = (status) =>{
        handleLoading(status)
    }




    return (

        <>
            <div className="qsHeading mb-4">
                <span className="quesNum">Question {selectedQuestion.question_no}</span>
                <span className="quesNum">Score: {selectedQuestion?.marks}  <i className="fas fa-question-circle"></i></span></div>
            <div className="currentQ">
                <span className="areaQues"> <span dangerouslySetInnerHTML={{ __html: selectedQuestion.question }}></span></span>
                <div className="areaAns">
                {/* <Form.Group>
                        <Form.Label >Answer</Form.Label>
                </Form.Group> */}
                    {
                        selectedQuestion && selectedQuestion.choices && selectedQuestion.choices.map((choice, index) => (
                        <Form.Group key={index}>
                        <Form.Check
                            name="answer"
                            label={choice}
                            id={`trueRadio_${choice}_${selectedQuestion.id}`}
                            type="radio"
                            onChange={e => setAnswer(choice)}
                            feedbacktooltip={choice}
                        />
                    </Form.Group>
                        ))
                    }
                </div>

                <div className="submitBtnRow">
                    {/* <button type="button" className="btnSkip" onClick={() => skipQuestion(selectedQuestion)}> Skip </button> */}
                    <button type="button" className="btnSubmit" onClick={() => onClickSubmit(selectedQuestion.id)}> Submit </button>
                </div>

            </div>

        </>

    );
};

export default ObjectiveComponent;


