import React, { useEffect, useState } from 'react';
import socketIOClient from "socket.io-client";
import TeacherIcon from '../../../assets/security-officer.png'

import { CommonService } from '../../../service/common.service';

const Student = React.memo(function Student(props) {

	const [isVideo, setIsVideo] = useState(false);
	let peerConnection;
	const config = {
		iceServers: [
			{
				"urls": "stun:stun.l.google.com:19302",
			}
		]
	};
	let remoteVideo = null;

	useEffect(() => {
		const socket = socketIOClient(CommonService.getBaseUrl());
		remoteVideo = document.querySelector('#remoteVideo');
		socket.on("offer", (id, description, room) => {
			console.log('offer : ', id)
			if(id != null && description != null && room === props.roomName) {

				peerConnection = new RTCPeerConnection(config);
				peerConnection
					.setRemoteDescription(description)
					.then(() => peerConnection.createAnswer())
					.then(sdp => peerConnection.setLocalDescription(sdp))
					.then(() => {
						socket.emit("answer", id, peerConnection.localDescription);
					});
				peerConnection.ontrack = event => {
					console.log('event.streams[0] : ', event.streams[0])
					remoteVideo.srcObject = event.streams[0];
					setIsVideo(true)
				};
				peerConnection.onicecandidate = event => {
					if (event.hasOwnProperty('video')) {
						// socket.close();
						console.log('isVideo : ', isVideo)
						setIsVideo(false)
						console.log('isVideo : ', isVideo)
					}
					else if (event.candidate) {
						socket.emit("candidate", id, event.candidate);
					}
				};
			}
		});


		socket.on("candidate", (id, candidate) => {
			console.log('candidate : ', candidate)
			if(candidate != null) {

				if (!candidate.hasOwnProperty('video')) {
					peerConnection
						.addIceCandidate(new RTCIceCandidate(candidate))
						.catch(e => console.error(e));
				}
				else {
					setIsVideo(false)
			}
			}
		});

		socket.on("connect", () => {
			console.log('connect : ')
			socket.emit('create', props.roomName);
			socket.emit("watcher", { room: props.roomName, user: "student" });
		});

		socket.on("broadcaster", () => {
			console.log('broadcaster : ')
			socket.emit("watcher", { room: props.roomName, user: "student" });
		});

		socket.on("disconnectPeer", () => {
			console.log('disconnectPeer : ')

			peerConnection.close();
		});

		window.onunload = window.onbeforeunload = () => {
			socket.close();
		};


	}, [])

	return (
		<>
			{/* <video style={{width:"100%"}} id="remoteVideo" muted autoPlay></video> */}
			<video style={{ width: "100%" }} className={`${isVideo ? '' : 'd-none'}`} id="remoteVideo" muted autoPlay></video>
			<div className={`'usr-avatar' ${isVideo ? 'd-none' : ''}`} style={{ padding: "10px", margin: "0 auto", display: "table" }}>
				<img src={TeacherIcon} />
			</div>
			{
				props.teacherData.length > 0 ? (
					<span style={{ margin: "0 auto", display: "table", fontSize: "20px", fontWeight: "bold" }}>{props.teacherData[0].first_name}</span>
				) : null
			}


		</>
	)
})

export default Student;
