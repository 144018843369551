import React, { useState, useContext, useEffect } from "react";
import "./Login.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import BigLogo from './../../assets/fls/logo-large-with-slogan.png'
import Google from './../../assets/google.png'
import facebook from './../../assets/facebook.png'
import { history } from '../../_helpers/history'
// import { useForm } from "react-hook-form";
import { CommonService } from '../../service/common.service';
import { useAlert } from "react-alert";
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import LoadingOverlay from 'react-loading-overlay';
import queryString from 'query-string';

const Login = () => {
    const [loading, setLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [username, setusername] = useState('');
	const [password, setPassword] = useState('');
	const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const alert = useAlert();
	
	const queryParams = queryString.parse(location.search)
	const [redirectUrl, setRedirectUrl] = useState(queryParams?.redirect_url);
	const [emailVerifyStatus, setEmailVerifyStatus] = useState(queryParams?.emv);

	useEffect(()=>{
		const isLoggedIn = localStorage.getItem('user') ? true : false;
		if(isLoggedIn) {
			var data =  JSON.parse(localStorage.getItem('user'))
			if (redirectUrl && redirectUrl != '/') {
				if (redirectUrl.includes("http") || redirectUrl.includes("www")) {
					location.href = redirectUrl 
				}else{
					if (redirectUrl == 'payment') {
						let url = redirectUrl
						url += `?course_id=${queryParams?.course_id}&payment_redirect_url=${queryParams?.payment_redirect_url}`
						setRedirectUrl(url)
						history.push(url)
					}else{
						history.push(redirectUrl)
					}
				}
			}else{
				if (data.role == 'ADMIN') {
					history.push('/view-course');
				}else{
					history.push('/courses');
				}
			}
		}else if (redirectUrl){
				if (redirectUrl.includes("http") || redirectUrl.includes("www")) {
					setRedirectUrl(redirectUrl)
				}else{
					if (redirectUrl == 'payment') {
						let url = redirectUrl
						url += `?course_id=${queryParams?.course_id}&payment_redirect_url=${queryParams?.payment_redirect_url}`
						setRedirectUrl(url)
					}else{
						setRedirectUrl(redirectUrl)
					}
				}
		}else if(emailVerifyStatus){
			if (emailVerifyStatus == 'success') {
				alert.success('Email Verified.')
			}else{
				alert.error('Failed to verify email')
			}
		}else{
			
			setRedirectUrl('/courses')
		}

	},[])

	const handleSubmit = (username, password) => {
        setLoading(true);
        const form = document.getElementById("loginform");
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
            handleLoginButtonClick(username, password);
            setLoading(false);
          
        }
       
    }

	const onForgotPwdClick = () => {
		forgotpassword(username);
	}

	function forgotpassword(username) {
		setLoading(true)
		try {

			let obj = {
				"username": username,
				"redirect_url": 'http://localhost:8080/updatepassword'
			}
			CommonService.httpPostService('user/forgetpassword', obj)
				.then(response => {
					 
					localStorage.setItem('response', JSON.stringify(response))
					// history.push('/');
					setLoading(false)

					handleClose();
					if(response.status== "success"){
						// history.push('/updatepassword');
						alert.success(response.message)
					}
					
					
				}, error => {
					setLoading(false)
					handleClose()
					alert.error(error)
				})

		} catch (error) {
			setLoading(false)

			alert.error('Something went wrong ...')
		}
	}

	function handleLoginButtonClick(username, password) {
		setLoading(true);
		try {

			let obj = {
				"username": username,
				"password": password
			}
			CommonService.httpPostService('user/login', obj)
				.then(users => {
					setLoading(false);
					localStorage.setItem('user', JSON.stringify(users))
					// history.push('/courses');
					debugger
					if (redirectUrl) {
						if (redirectUrl.includes("http") || redirectUrl.includes("www")) {
							location.href = redirectUrl 
						}else{
							if (redirectUrl == 'payment') {
								let url = redirectUrl
								url += `?course_id=${queryParams?.course_id}&order_id=${queryParams?.order_id}&payment_redirect_url=${queryParams?.payment_redirect_url}`
								setRedirectUrl(url)
								history.push(url)
							}else{
								if (users.role == 'ADMIN') {
									history.push('/view-course');
								}else{
									history.push(redirectUrl)
								}
								
							}
						}
					}else{
						if (users.role == 'ADMIN') {
							history.push('/view-course');
						}else{
							history.push('/courses');
						}
					}
				}, error => {
					setLoading(false);
					alert.error(error)
				})

		} catch (error) {
			alert.error('Something went wrong ...')
		}
	}

	const  handleEnter = (e) => {
		if (e.keyCode === 13) {
			const form = document.getElementById("loginform");
			const index = Array.prototype.indexOf.call(form, e.target);
			form.elements[index + 1].focus();
			if (form.elements[index + 1] && form.elements[index + 1].className == 'loginBtn') {
			  form.elements[index + 1].click()
			}
			e.preventDefault();
		  }
	}

	return (
		<LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
		<Container className="containerColor" fluid>
			<Container>
				<Row className="justify-space-around">
					<Col xs={6} className="col_of_img">
						<img className="coverpic" src={BigLogo} />
					</Col>
					<Col xs={12} md={4} >
						<Col>
								<div className="CompanyName">
									Florida Security School
            				 </div>
								<div className="contentHeading">
									Welcome
             				</div>
								{/* <div className="headingCaption">New here ? <span className="signUp"><Link to='/Signup'> Sign up</Link> </span> </div> */}
								<div className="userdetailSection">
								<Form className="loginformCol" id="loginform" noValidate validated={validated} >
								<Form.Group >
								<Form.Control
										name="userName"
										type="text"
										className="emailInput"
										placeholder="Your Username or Email Address"
										value={username}
										onKeyDown={handleEnter}
										onChange={e => setusername(e.target.value)}
										required/>
									<Form.Control.Feedback type="invalid">
											Please enter your username/email.
									</Form.Control.Feedback>
									</Form.Group>
									<Form.Group >
									<Form.Control
										name="password"
										type="password"
										className="pwdInput"
										placeholder="Your Password"
										value={password}
										onKeyDown={handleEnter}
										onChange={e => setPassword(e.target.value)}
										required
								   />
									<Form.Control.Feedback type="invalid">
											Please enter your password.
									</Form.Control.Feedback>
									</Form.Group>
									<button type="button" className="loginBtn"
									 onClick={() => handleSubmit(username, password)}
									 onKeyDown={(e) => {
										if (e.key === 'Enter') {
											handleSubmit(username, password)
										}else{
											null
										}
									}}  >
										Login
               					    </button>
									</Form>

									<span className="forgotpwd" onClick={handleShow}>Forgot password?</span>
									<Modal show={show}
										onHide={handleClose}
										backdrop="static"
										keyboard={false}
										className="modal_fp">
										<Modal.Header className="closeBtnRow" closeButton>
											
											{/* <Modal.Title>Forgot your Password?</Modal.Title> */}
										</Modal.Header>
										<Modal.Body >
											<span className="forgotPwdHeader">Forgot your Password?</span>
											<span>Please fill out the information below to reset your password. </span>

											<span>
												
											<input 
											name="fpemail"
											type="text" 
											value={username} 
											onChange={e => setusername(e.target.value)} 
											// ref={register({
											// 	required: true,
											// 	pattern: {
											// 	  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
											// 	  message: "invalid email address"
											// 	}
											//   })} 
											className="emailInput w-100" 
											placeholder="Enter your email address"/>
									 {/* {errors.name && errors.name.type === "required" && <span className="">Please enter your email address</span>} */}
									 {/* {errors.name && errors.name.type === "pattern" && <span className="">Please a valid email address</span>} */}
											</span>
											
                    					</Modal.Body>
										<Modal.Footer>
											<Button variant="secondary" onClick={handleClose}>
												Close
                        				</Button>
											<Button variant="primary" onClick={onForgotPwdClick} > Submit</Button>
										</Modal.Footer>
									</Modal>
									{/* <div className="altOption">
										<span className="tagTitle">Or login With</span>
										<div className="twoTag">
											<div className="facebook">
												<button type="button" className="fbBtn">
													<img className="fblogo" src={facebook} />

												</button>
											</div>
											<div className="google">
												<button type="button" className="googleBtn">
													<img className="googlelogo" src={Google} />

												</button>
											</div>
										</div> */}
										{/* <span className="tnc"> By continuing you accept our <span className="forgotpwd" onClick={handleTncShow}> Terms & Conditions</span>  </span> */}
									{/* </div> */}
								</div>
							{/* </form> */}
							
						</Col>
					</Col>
				</Row>
			</Container>
		</Container>
	    </LoadingOverlay>
	);
};

export default Login;
