import React, { useState, useContext, useEffect } from "react";
import './course-certificate.css';
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import Header from '../../common/header/header'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { CommonService } from '../../service/common.service';
import { useAlert } from "react-alert";
import NotFoundIMg from '../../assets/question-mark.png'
import LoadingOverlay from 'react-loading-overlay';
import { history } from '../../_helpers/history'
import * as FileSaver from 'file-saver';
const CourseCertificate = ({ match }) => {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    // const [backUrlType , setSackUrlType]
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [messageShow, setMessageShow] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [message, setMessage] = useState();
    const [classId, setClassId] = useState(null);
    const [filterInputValue, setFilterInputValue] = useState('');
    const paramId = match.params?.id;
    const paramClassName = match.params?.name;
    var Centrifuge = require("centrifuge");
    const [centriMessage, setCentriMessage] = useState();
    const [showSpinner, setShowSpinner] = useState(false);
    let centrifuge = null;
    const [channelName, setChannelName] = useState(Math.random().toString(36).substring(2, 12));
    const messageHandleClose = () => setMessageShow(false);
    const messageHandleShow = async (datal) => {
        setMessageShow(true);
        setMessage(datal.error_description ? datal.error_description : "");
    }

    const subscribeCentrifuge = (token, allStudentData) => {
        centrifuge = new Centrifuge(CommonService.getCentrifugeUrl('ws'));
        centrifuge.setToken(token);
        centrifuge.on('connect', function (ctx) {
            console.log("connected", ctx);
        });

        centrifuge.on('disconnect', function (ctx) {
            console.log("disconnected", ctx);
        });


        var subs = centrifuge.subscribe(channelName, (ctx) => {
            updateStudentData(ctx.data, allStudentData)
        })

        centrifuge.connect();
    }

    const updateStudentData = (centriData, allStudentData) => {
        console.log('Centri data : ',centriData)
        let currentStudent = allStudentData.filter(
            option => {
                if (centriData.student_id == option.id) {
                    return option
                }
            }
        )
        if (centriData.course_certificate_generated == true) {
            for (const key in allStudentData) {
                if (allStudentData.hasOwnProperty(key)) {
                    const stdElm = allStudentData[key];
                    if (stdElm.id == centriData.student_id) {
                        stdElm.updated_url = centriData.course_certificate_url
                        stdElm.course_certificate_url = centriData.course_certificate_url
                        stdElm.course_certificate_generated = centriData.course_certificate_generated,
                        stdElm.show_loader = false

                    }
                }
            }
            setData(allStudentData)
            setCentriMessage(`Certificate Generated for : ${currentStudent[0].full_name}`)
        } else {
            for (const key in allStudentData) {
                if (allStudentData.hasOwnProperty(key)) {
                    const stdElm = allStudentData[key];
                    if (stdElm.id == centriData.student_id) {
                        stdElm.error_description = centriData.error_description
                        stdElm.show_loader = false
                    }
                }
            }
            setData(allStudentData)
            setCentriMessage(`Failed to generate certificate for : ${currentStudent[0].full_name}`)
        }
        setShowSpinner(false)
    }


    const filterData = (value) => {
        let tempArr = Object.assign([], data)
        setFilterInputValue(value)
        if (value.length >= 3) {
            let upperCaseFilter = value.toUpperCase()
            let filterDataArr = tempArr.filter(
                option => {
                    if (
                        (
                            option.full_name != undefined &&
                            option.full_name != null &&
                            (
                                option.full_name.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        ) ||
                        (
                            option.primary_email != undefined &&
                            option.primary_email != null &&
                            (
                                option.primary_email.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        )
                    ) {
                        return option
                    }
                }
            )
            setFilteredData(filterDataArr)
        } else {
            setFilteredData(data)
        }

    }


    const getStudent = (classParamId) => {

        CommonService.httpGetService(`admin-api/class_student/student?class_id=${paramId}&type=added`)
            .then(res => {
                handleStudentData(res,false)
                let userData = JSON.parse(localStorage.getItem('user'))
                subscribeCentrifuge(userData.centri_token, res)
                // if (data.length > 0) {
                //     setChapterId(data[0].id)
                //     setLoading(false);
                // }
            }, error => {
                alert.error(error)
                setLoading(false);
            })
    }

    const handleStudentData = (students,loader=false,id = null) => {
        if (id) {
            for (var student of students) {
                if(id == student.id){
                    student['show_loader'] = loader
                }
            }
        } else {
            for (var student of students) {
                    student['show_loader'] = loader
            }
        }
        setData(students)
        setFilteredData(students)
        if (filterInputValue != '') {
            filterApiData(filterInputValue, students)
        }
    }

    const filterApiData = (value, filterArr) => {
        let tempArr = Object.assign([], filterArr)

        setFilterInputValue(value)
        if (value.length >= 3) {
            let upperCaseFilter = value.toUpperCase()
            let filterDataArr = tempArr.filter(
                option => {
                    if (
                        (
                            option.full_name != undefined &&
                            option.full_name != null &&
                            (
                                option.full_name.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        ) ||
                        (
                            option.primary_email != undefined &&
                            option.primary_email != null &&
                            (
                                option.primary_email.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        )
                    ) {
                        return option
                    }
                }
            )
            setFilteredData(filterDataArr)
        } else {
            setFilteredData(filterArr)
        }

    }


    useEffect(() => {
        // search(paramName)
        // if(paramType != null) {
        //     setSackUrlType(paramType)
        // }
        setLoading(false);
        if (paramId != null) {
            setClassId(paramId)
            getStudent(paramId)

        }


    }, []);

    const generateCerificate = (user_id=null,isRegenerate = false) => {
        let reqObj = {
            "class_id": classId,
            "channel_name": channelName,
            "user_id" : user_id,
            "isRegenerate" : isRegenerate
        }
        if (centrifuge) {
            centrifuge.disconnect()
        }
        handleStudentData(data,true,user_id)
        CommonService.httpPostService(`admin-api/class_student/genrate-all-certificates`, reqObj)
            .then(res => {
                alert.success("Generating Certificates. Please check in few minutes")
                setShowSpinner(true)
            }, error => {
                alert.error(error)
                setLoading(false);
            })
    }

    const downloadCertificate = (fileURL, fileName) => {
        alert.success('Dowloading Certificate. Please wait..')
        CommonService.downloadPdf(fileURL)
            .then(fileBlob => {
                // let fileBlob = res.blob();
                let blob = new Blob([fileBlob], {
                    type: 'application/pdf'
                });
                FileSaver.saveAs(blob, fileName);
            }, error => {

            })
    }


    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading...'
            className="loaderStyle"
        >
            <Container className="containerBox" fluid>
                <Header></Header>

                <Container fluid>
                    <div className="heading">
                        <div className="lheading">
                            <span className="headTitle"> STUDENTS </span>
                            <span className="searchItem"> {paramClassName} </span>
                        </div>
                        <div className="rheading">

                            <button type="button" className="addNew" onClick={() => generateCerificate()}>Generate All Certificate</button>
                            <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>
                        </div>
                    </div>
                    <div className="subheading">
                        {/* <div className="rsubhead">
                            {centriMessage}
                        </div> */}
                        <div className="lsubhead">
                            <input type="text" onChange={e => filterData(e.target.value)} className="tableSearch" placeholder="Search here..." />
                        </div>

                    </div>



                    {

                        filteredData && filteredData?.length > 0 ? (

                            <Table responsive>
                                <thead>
                                    <tr className="tableHead">
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email Address</th>
                                        <th></th>
                                        <th className=""></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        filteredData.map((datal, index) => (

                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                {/* {moment(datal.start_date).format('MMMM Do YYYY hh:mm')} */}
                                                <td>{datal?.full_name} </td>
                                                <td>{datal?.primary_email}</td>
                                                <td className="">
                                                    {datal.class_student_status == 'pending' ? (
                                                        <span className="font-weight-bold text-danger"> Pending </span>
                                                    ) : ''}
                                                </td>
                                                <td className="">
                                                    {
                                                        datal.course_certificate_generated == true && datal.show_loader == false ? (
                                                            <>
                                                                <button type="button" onClick={() => downloadCertificate(datal.course_certificate_url, "certificate.pdf")} className='addStudent mr-2'>Download</button>

                                                                <button type="button" onClick={() => generateCerificate(datal.id,true)} className='removeBtn mr-2'>Re-Generate</button>
                                                            </>

                                                        ) : (
                                                                <>
                                                                    {
                                                                        datal.show_loader == false ? <>
                                                                            <i className="fas fa-info-circle ml-1" onClick={() => {
                                                                                messageHandleShow(datal)
                                                                            }} >
                                                                            </i>
                                                                            <button type="button" className='removeBtn' onClick={() => {
                                                                                generateCerificate(datal.id,false)
                                                                            }}>Generate</button>
                                                                        </> :
                                                                            <i className="fas fa-spinner fa-pulse ml-1" ></i>
                                                                    }
                                                                </>
                                                            )
                                                    }

                                                </td>
                                            </tr>
                                        ))

                                    }



                                </tbody>
                            </Table>
                        ) : (
                                <div className="nofoundRow">
                                    <img className="nofoundimg" src={NotFoundIMg} />
                                    <span className="nofoundimgCaption">Sorry! Students are not available </span>
                                </div>
                            )

                    }
                    <Modal show={messageShow}
                        onHide={messageHandleClose}
                        keyboard={false}>
                        <Modal.Header closeButton>
                            Failed to generate certificate due to :
                        </Modal.Header>
                        <Modal.Body>
                            {message}
                        </Modal.Body>
                    </Modal>

                </Container>
            </Container>
        </LoadingOverlay>



    );
};

export default CourseCertificate;
