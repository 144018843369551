import React, { useState, useContext, useEffect } from "react";
import "./UpdatePassword.css";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import { CommonService } from '../../service/common.service';
import queryString from 'query-string';
import BigLogo from '../../assets/fls/logo-large-with-slogan.png'
import LoadingOverlay from 'react-loading-overlay';
import { history } from '../../_helpers/history'

const UpdatePassword = () => {
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [newPassword, setnewPassword] = useState('');
    const [confirmPassword , setconfirmPassword] = useState('')
    const alert = useAlert();
     
    const queryParams = queryString.parse(location.search)
    const token = queryParams?.token;
    // const onUpdatePasswordClick = (newPassword) => {
    //     resetPassword(newPassword);
    // }

    const handleSubmit = (newPassword) => {
         
        setLoading(true);
        const form = document.getElementById("ResetPasswordForm");
        const pass = document.getElementById("pass").value
        const confPass = document.getElementById("c_pass").value
      
        if (form.checkValidity() === false) {
            setValidated(true);
             
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {

            if(pass != confPass) {
                alert.error('Password & Confirm Password must match!');
            }
            else {
                resetPassword(newPassword);
            }
          
            setLoading(false);
          
        }
       
    };


	function resetPassword( newPassword) {
		try {
			let obj = {
                "token": token,
                "new_password": newPassword
            }
            setLoading(true);
			CommonService.httpPostService('user/updatepassword', obj)
				.then(response => {
					localStorage.setItem('response', JSON.stringify(response))
					if(response.status== "success"){            
                        setLoading(false);
                        console.log(response);
                        alert.success('Password Updated Successfully')
                        history.push('/');
					}
					
					
				}, error => {
                    setLoading(false);
					alert.error(error)
				})

		} catch (error) {
			alert.error('Reset Password Failed')
		}
	}

    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container>
            <Card className="updatePwdCard my-btns shadow-box">
                <Card.Body>
                    <Form id="ResetPasswordForm" noValidate validated={validated} >
                        <div className="text-center">
                            <div className="comp-logo-container">
                            <img  src={BigLogo} />
                            </div>
                            <h1 className="companyTitle">Florida Security School</h1>
                        </div>
                        <div className="p-20">
                        <h2 className="card-title text-center">Reset your password</h2>
                            {/* <Form.Group controlId="formBasicToken">
                                <Form.Label>Token</Form.Label>
                                <Form.Control type="text" value={token} onChange={e => setToken(e.target.value)} placeholder="Enter Token" />

                            </Form.Group> */}

                            <div className="inputPwd">
                                <Form.Group >
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        id="pass"
                                        type="password"
                                        value={newPassword}
                                        onChange={e => setnewPassword(e.target.value)}
                                        placeholder="Enter a new password" 
                                        required/>

                                </Form.Group>

                                <Form.Group >
                                    <Form.Label>Confirm New Password</Form.Label>
                                    <Form.Control
                                        id="c_pass"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={e => setconfirmPassword(e.target.value)}
                                        placeholder="Confirm your new password" 
                                        required/>
                                </Form.Group>
                            </div>

                            <div className="d-flex justify-center">
                            <Button  className="btn active" onClick={() => handleSubmit(newPassword)}>
                                Submit
                            </Button>
                            </div>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
        </LoadingOverlay>
    )

};
export default UpdatePassword;