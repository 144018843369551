import React, { useState, useContext , useEffect } from "react";
import "./print-student-contract.css";
import Container from 'react-bootstrap/Container';
import { useAlert } from "react-alert";
import {CommonService} from '../../service/common.service';
import LoadingOverlay from 'react-loading-overlay';
import StudentContractComponent from '../../components/StudentContractComponent';
import * as moment from 'moment'
const PrintStudentContract = ({match}) => {
    const alert = useAlert();
    const [name, setName] = useState('')
    const [shortHandName, setShortHandName] = useState(null);
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [mobileNo, setMobileNo] = useState('')
    const [zip, setZip] = useState('')

    const [countryName, setCountryName] = useState('')
    const [stateName, setStateName] = useState('')
    const [cityName, setCityName] = useState('')
    
    const [classJoinDate, setClassJoinDate] = useState('')
    const [teacherName, setTeacherName] = useState('')
    const [teacherSignatureUrl, setTeacherSignatureUrl] = useState('')
    const [createdOn, setCreatedOn] = useState('')
    const [fees, setFees] = useState("")
    const [loading, setLoading] = useState(false);
    const  paramId = match.params?.id;
    const getShortHandName = (fullName) =>{
        if (fullName != undefined) {
			let nameArr = fullName.split(" ")
			let shortName = ""
			if (nameArr.length == 1) {
				shortName = nameArr[0].substring(0, 2)
			} else if (nameArr.length > 1)
				for (const key in nameArr) {
					if (nameArr.hasOwnProperty(key)) {
						if (Number(key) <= 1) {
							const element = nameArr[key];
							shortName += element.substring(0, 1)
						}
					}
				}
            setShortHandName(shortName)
		}
    }
    useEffect(() => {
        setLoading(true);
        try {
            if(paramId != null) {
                CommonService.httpGetService(`admin-api/user-contract?id=${paramId}`)
                    .then(async data => {
                        if (data.length > 0) {
                            let name = data[0].full_name
                            getShortHandName(name)
                            setName(name)
                            setEmail(data[0].primary_email)
                            setAddress(data[0].address_1)
                            setCountryName(data[0].country)
                            setStateName(data[0].state_name)
                            setCityName(data[0].city_name)
                            setMobileNo(data[0].mobile_no)
                            setZip(data[0].zip)

                            setTeacherName(data[0].instructor_name)
                            setTeacherSignatureUrl(data[0].signature_url)
                            setCreatedOn(data[0].created_on ? moment(data[0].created_on).format('DD/MM/YYYY') : '')
                            setFees(data[0].payment_amount)
                            setClassJoinDate(data[0].payment_date ? moment(data[0].payment_date).format('DD/MM/YYYY') : '')
                            setLoading(false);
                            
                        }
                    },error =>{
                        alert.error(error)
                        setLoading(false);
                    })
            }
        } catch (error) {
            setLoading(false);
        }
    }, []);
    return (
        <LoadingOverlay
        active={loading == true ? true : false}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" style={{
            marginTop : "5%",
            marginBottom : "5%"
        }}>
        <StudentContractComponent name={name} nameInitials={shortHandName} email={email} mobileNo={mobileNo} address={address} zip={zip} cityName={cityName} stateName={stateName} countryName={countryName} classJoinDate={classJoinDate} teacherName={teacherName} teacherSignature={teacherSignatureUrl} fees={fees} createdOn={createdOn} />
        </Container>
       
       </LoadingOverlay>
    );
};

export default PrintStudentContract;
