import React, { useState, useContext, useEffect } from "react";
import "./profile.css";
import Container from 'react-bootstrap/Container';
import Header from '../../common/header/header'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import { CommonService } from '../../service/common.service';
import { history } from '../../_helpers/history'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal'
import defaultTeacher from '../../assets/defaultteacher.png'
// import defaultTeacher from '../../assets/userone.jpg'
import axios from 'axios';
import Avatar from 'react-avatar-edit'

const Profile = ({ match }) => {
    const [userName, setUserName] = useState('')
    const [phoneNo, setPhoneNo] = useState('')
    const [name, setName] = useState('')
    const [role, setRole] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [mobileNo, setMobileNo] = useState('')
    const [zip, setZip] = useState('')

    const [countryData, setCountryData] = useState([])
    const [countryId, setCountryId] = useState('')
    const [countryName, setCountryName] = useState('')
    const [stateData, setStateData] = useState([])
    const [stateId, setStateId] = useState('')
    const [cityData, setCityData] = useState([])
    const [cityId, setCityId] = useState('')
    const [cityName, setCityName] = useState('')

    const [fileData, setFileData] = useState('')
    const [signatureUrl, setSignatureUrl] = useState(null)
    const [dILicense, setDILicense] = useState(null)


    const [id, setId] = useState('');
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const alert = useAlert();
    const paramId = match.params?.id;
    const [dltShow, setdltShow] = useState(false);
    const delethandleClose = () => setdltShow(false);
    const deleteHandleShow = async () => {
        setdltShow(true);
    }
    // const [crop, setCrop] = useState(null);
    // const [close, setClose] = useState(null);
    const [preview, setPreview] = useState(null);
    const [srcImg, setSrcImg] = useState(null);
    const [shortHandName, setShortHandName] = useState(null);
    const [profileColor, setProfileColor] = useState(null);

    useEffect(() => {
        setLoading(true);
        if (paramId != null) {
            setId(paramId)
            getUserDetail()
        }
        getCountry();
        onClose();
        onCrop();
        setSrcImg();
        onBeforeFileLoad();
    }, []);

    const getUserDetail = () => {
        CommonService.httpGetService(`user/user?id=${paramId}`)
            .then(async data => {
                if (data.length > 0) {
                    let name = data[0].full_name
                    getShortHandName(name)
                    let email = data[0].primary_email
                    let role = data[0].roles[0].name
                    let address = data[0].address_1
                    let mobile_no = data[0].mobile_no
                    let zip = data[0].zip
                    let country_id = data[0]?.country_id
                    let state_id = data[0]?.state_id
                    let city_id = data[0].city_id
                    let city_name = data[0].city_name
                    let profile_picture = data[0].profile_picture
                    let signature_url = data[0].signature_url ? data[0].signature_url : ''
                    let di_license = data[0].di_license ? data[0].di_license : ''
                    setName(name)
                    setUserName(name)
                    setEmail(email)
                    setRole(role)
                    setAddress(address ? address : '')
                    debugger
                    if (typeof mobile_no == 'string') {
                        mobile_no = mobile_no.trim()
                        mobile_no = Number(mobile_no)
                    }
                    setMobileNo(mobile_no ? mobile_no : '')
                    setPhoneNo(mobile_no ? mobile_no.toString() : '')
                    setZip(zip)
                    setCountryId(231)
                    let state = await getStates(231) // Country Code of united states.
                    setStateId(state_id ? state_id : '')
                    setCityName(city_name ? city_name : '')
                    setFileData(profile_picture)
                    setSignatureUrl(signature_url)
                    setDILicense(di_license)

                    // setStateId(state_id)
                    setLoading(false);

                }
            }, error => {
                alert.error(error)
                setLoading(false);
            })
    }
    const getShortHandName = (fullName) => {
        if (fullName != undefined) {
            let nameArr = fullName.split(" ")
            let shortName = ""
            if (nameArr.length == 1) {
                shortName = nameArr[0].substring(0, 2)
            } else if (nameArr.length > 1)
                for (const key in nameArr) {
                    if (nameArr.hasOwnProperty(key)) {
                        if (Number(key) <= 1) {
                            const element = nameArr[key];
                            shortName += element.substring(0, 1)
                        }
                    }
                }
            setShortHandName(shortName)
            setProfileColor(Math.floor(Math.random() * 16777215).toString(16))
        }
    }

    const onClose = () => {
        setPreview({ preview: null })
    }

    const onCrop = (preview) => {
        setPreview({ preview })
    }

    const onBeforeFileLoad = (elem) => {
        if (elem?.target.files[0].size > 71680) {
            alert("File is too big!");
            elem.target.value = "";
        };
    }

    const handleSubmit = (name, email, mobileNo, address, zip, countryId, stateId, cityName, fileData, role, dILicense) => {
        setLoading(true);
        const form = document.getElementById("CreateLectureForm");
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
            handleCreateUserBtn(name, email, mobileNo, address, zip, countryId, stateId, cityName, fileData, role, dILicense);
        }

    };

    const handleReset = () => {
        setName('')
        setEmail('')
        setRole('Student')
        setAddress('')
        setMobileNo('')
        setZip('')
        setValidated(false);
        setdltShow(false);

    };

    const getCountry = () => {

        try {
            setLoading(true);
            CommonService.httpGetService(`admin-api/location/country`)
                .then(countryArr => {
                    setCountryData(countryArr)
                    setLoading(false)


                }, error => {
                    alert.error(error)
                    setLoading(false);
                })
        }
        catch {
            alert.error('Something went wrong')

        }
    }
    const getStates = (countryId) => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                if (countryId && countryId != null) {
                    CommonService.httpGetService(`admin-api/location/states?country_id=${countryId}`)
                        .then(res => {
                            setStateData(res)
                            setLoading(false)
                            resolve(res)
                        }, error => {
                            alert.error(error)
                            setLoading(false);
                            resolve(null)
                        })
                } else {
                    setLoading(false);
                    resolve(null)
                }
            }
            catch {
                alert.error('Something went wrong')
                resolve(null)

            }
        })
    }



    function handleCreateUserBtn(name, email, mobileNo, address, zip, countryId, stateId, cityId, fileData, role, dILicense) {
        try {

            const data = new FormData()
            data.append('id', id)
            data.append('full_name', name)
            data.append('primary_email', email)
            data.append('mobile_no', mobileNo)
            data.append('address_1', address)
            data.append('zip', zip)
            data.append('country_id', countryId)
            data.append('state_id', stateId)
            data.append('city_name', cityName)
            data.append('user_signature', fileData)
            data.append('di_license', dILicense)
            data.append('roles', role)

            axios.put(`${CommonService.getBaseUrl()}/user/user/${id}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => { // then print response status
                setLoading(false);
                alert.success(`User updated successfully. Signature will updated shortly.`);
                getUserDetail()

            }, error => {
                setLoading(false);
                alert.error(error)
            })

        } catch (error) {
            setLoading(false);

            alert.error("Please fill the required details!");
        }
    }

    const selectFile = event => {
        setFileData(event.target.files[0])
    }
    return (
        <LoadingOverlay
            active={loading == true ? true : false}
            spinner
            text='Loading...'
            className="loaderStyle"
        >
            <Container className="containerBox" fluid>
                <Header></Header>
                <Container fluid>
                    <div className="heading">
                        <div className="lheading">
                            <span className="headTitle">My Profile </span>
                        </div>
                        <div className="rheading">
                            <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>
                        </div>
                    </div>
                    <div className="">
                        <div className="profileimg-area">
                            <div className="usr-avatar-box" style={{ backgroundColor: `#${profileColor}` }}>
                                <span className="usr-short-name profile-pic" >{shortHandName}</span>
                            </div>
                            <div className="name-area">
                                <span className="usr-name">{userName} </span>
                                <span className="usr-role"> {role}</span>
                            </div>
                            {
                                phoneNo && phoneNo.length > 0 ?
                                    <>
                                        <span className="spt"></span>
                                        <div className="name-area ">
                                            <span className="usr-name"> <i className="fas fa-phone-alt"></i> </span>
                                            <span className="usr-role"> {phoneNo} </span>
                                        </div>
                                    </> : ''
                            }
                            <span className="spt"></span>
                            <div className="name-area ">
                                <span className="usr-name"> <i className="far fa-envelope"></i> </span>
                                <span className="usr-role"> {email}</span>
                            </div>
                        </div>

                        <Form className="CreateEntryForm" id="CreateLectureForm" noValidate validated={validated}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridname">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="" value={name} onChange={(e) => setName(e.target.value)} pattern="[a-zA-Z0-9\s]+" required>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please fill your name.
                        </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridRole">
                                    <Form.Label>Role</Form.Label>
                                    <Form.Control as="select" custom value={role} disabled >
                                        <option value="ADMIN">Admin</option>
                                        <option value="TEACHER">Teacher</option>
                                        <option value="STUDENT">Student</option>
                                    </Form.Control>

                                </Form.Group>

                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="text" value={email} onChange={(e) => setEmail(e.target.value)} pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder="" readOnly>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please fill your email address.
                        </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridMobile">
                                    <Form.Label >Mobile Number</Form.Label>
                                    <Form.Control value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} pattern="[0-9]{10}" type="number" required>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Invalid Phone Number
                        </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridAddress">
                                    <Form.Label >Address</Form.Label>
                                    <Form.Control value={address} onChange={(e) => setAddress(e.target.value)} type="text" as="textarea" rows="1" >
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Address is required
                                </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control type="text" placeholder="" value={cityName} onChange={(e) => setCityName(e.target.value)} required>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        City is required
                                </Form.Control.Feedback>
                                </Form.Group>


                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridZipCode">
                                    <Form.Label >Zip Code</Form.Label>
                                    <Form.Control value={zip} onChange={(e) => setZip(e.target.value)} type="text" type="number" pattern="[0-9]{6}" required>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Zip Code is required
                                </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCountry">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control as="select" custom value={countryId}
                                        onChange={(e) => {
                                            setCountryId(e.target.value)
                                            getStates(e.target.value)
                                        }} required>
                                        {countryData.map((country, index) => (
                                            <option key={index} value={country.location_id}>{country.name}</option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Country is required
                                </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control as="select" custom value={stateId} onChange={(e) => {
                                        setStateId(e.target.value)
                                    }} required>
                                        <option value="">Select</option>
                                        {stateData.map((state, index) => (
                                            <option key={index} value={state.location_id}>{state.name}</option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        State is required
                                </Form.Control.Feedback>
                                </Form.Group>


                            </Form.Row>
                            {
                                role == 'TEACHER' ? (
                                    <>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formFileUpload">
                                                <Form.Label>Upload Signature *</Form.Label>
                                                <Form.File accept="image/*" onChange={selectFile} id="custom-file" label="Upload Signature" custom />
                                            </Form.Group>
                                            {signatureUrl ?
                                                <Form.Group as={Col} controlId="formFileSignature" className="alert" style={{
                                                    width: "200px",
                                                    maxWidth: "200px"
                                                }}>
                                                    <img className="img-thumbnail" src={signatureUrl}></img>
                                                </Form.Group> : (
                                                    <Form.Group as={Col} controlId="formFileSignature" >
                                                    </Form.Group>
                                                )
                                            }
                                        </Form.Row>

                                        {/* DI LICENSE */}
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridAddress">
                                                <Form.Label >DI License *</Form.Label>
                                                <Form.Control value={dILicense} onChange={(e) => setDILicense(e.target.value)} type="text">
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridZipCode">

                                            </Form.Group>
                                        </Form.Row>
                                    </>
                                ) : ''
                            }


                            <div className="submitBtnRow">
                                <button type="button" onClick={() => handleSubmit(name, email, mobileNo, address, zip, countryId, stateId, cityName, fileData, role, dILicense)} className="saveBtn"> Save </button>
                                <button type="button" onClick={() => getUserDetail()} className="resetBtn"> Reset </button>

                            </div>
                        </Form>
                        <Modal show={dltShow}
                            onHide={delethandleClose}
                            backdrop="static"
                            keyboard={false}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Do you want to reset this form?
                        </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={delethandleClose}>
                                    No
                            </Button>
                                <Button variant="primary" onClick={() => handleReset()}> Yes</Button>
                            </Modal.Footer>
                        </Modal>


                    </div>
                </Container>
            </Container>

        </LoadingOverlay>
    );
};

export default Profile;
